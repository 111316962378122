import { Tabs } from 'antd'
import { useState } from 'react'

import CustomRequestTab from './custom-request-tab/custom-request'
import ItemImageTab from './item-image-tab/item-image-tab'
import ItemsInfoTab from './item-info-tab/item-info'
import ItemSizeTab from './Item-size/item-size-tab'
import ItemTransactionTab from './item-transaction-tab/item-transaction-list'
import ItemReviewsTab from './items-reviews/item-reviews-list'
import ItemStockTab from './items-stock/item-stock-tab'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes/constants'


const ItemsDetailsTabPage = ({ id }: { id: any }) => {

    const [tabs, setTabs] = useState<any>("1")

    const handleTabChange = (activeKey: any) => {
        setTabs(activeKey);
    };

    const items = [
        {
            key: "1",
            label: "Item Info",
            children: <ItemsInfoTab id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "2",
            label: "Item Size",
            children: <ItemSizeTab id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "3",
            label: "Item Stock",
            children: <ItemStockTab id={id} tabs={tabs} />,
            className: 'cltb'

        },

        {
            key: "4",
            label: "Item Images",
            children: <ItemImageTab id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "5",
            label: "Item Transactions",
            children: <ItemTransactionTab id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "6",
            label: "Item Reviews",
            children: <ItemReviewsTab id={id} tabs={tabs} />,
            className: 'cltb'

        },
        // {
        //     key: "7",
        //     label: "Custom Request",
        //     children: <CustomRequestTab id={id} tabs={tabs} />,
        //     className: 'cltb'

        // }
    ]


    return (
        <>
            <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={ROUTES.ITEMS_LIST} >Items</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                        <Link to={ROUTES.ITEMS_LIST} >Items List</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        {`Items Detail/ ${id}`}
                    </li>
                </ul>
            </nav>
            <Tabs
                className="sctabbx"
                activeKey={tabs}
                onChange={handleTabChange}
                items={items} />
        </>
    )
}

export default ItemsDetailsTabPage
