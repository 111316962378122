import { DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Table, TablePaginationConfig } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useUpdateStatus } from 'features/users/use-cases/create-user';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Volunteer } from 'types';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: any;
  itemsCount: number;
  tableParams: UserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<any>>;
};

export const UsersTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
}: UsersTableProps) => {

  const permission = usePermission();

  const navigate = useNavigate();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    // sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
  ) => {
    updateParams({
      ...tableParams,
      CurrentPage: pagination.current,
      RecordsPerPage: pagination.pageSize,
      // order_by: Array.isArray(sorter)
      //   ? undefined
      //   : sorter.order === 'ascend'
      //     ? 'name'
      //     : '-name',
    });
  };


  const { confirm } = Modal;

  const showDeleteConfirm = (record: any) => {
    //console.log(record,"record");
    confirm({
      title: 'Are you sure you want to delete this User?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.MemID);
      },
    });
  };

  const { mutate: mutateUpdateStatus } =
    useUpdateStatus();

  const handleDelete = async (id: any) => {

    mutateUpdateStatus({
      Id: id,
      Flag: "MemberDelete",
    });
  };

  const showAdminDetail = (record: any) => {
    navigate(getRoute(ROUTES.USERS_ADMIN_DETAIL, record.MemID));
  }


  const menuItems = (record: any) => [
    permission.Delete ? {
      key: 'delete',
      label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
        Delete
      </Button>,
    } : null,
  ]

  return (
    <div className='order_rtn'>

      <StyledTableWrapper>
        <StyledTableHeader>
          {permission.Totals && <RecordCountBadge
            text={'Total'}
            count={itemsCount}
          />}
        </StyledTableHeader>
        <Table<Volunteer>
          rowKey='Id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.CurrentPage,
            defaultPageSize: tableParams.RecordsPerPage,
            total: itemsCount,
          }}
          // scroll={{ x: 1440 }}
          locale={{
            emptyText: isUsersListLoading ? <Skeleton active={true} /> : <Empty />
          }}

          loading={isUsersListLoading}
          dataSource={isUsersListLoading ? [] : usersList}
          className='cstm_table'

        >
          <Column<any>
            title={'User Id'}
            dataIndex={'MemUserID'}
            sorter={(a, b) => a.MemFullName.localeCompare(b.MemFullName)}
            render={(value: any, record: any) => {
              return (
                <span className='arow_tab' onClick={() => permission.Edit && showAdminDetail(record)}>
                  {permission.Edit && <RightOutlined />}
                  <h1>{record.MemUserID}</h1>
                </span>

              )
            }}
          />
          <Column<any>
            title={'Name'}
            dataIndex={'MemFullName'}
            sorter={(a, b) => a.MemFullName.localeCompare(b.MemFullName)}
          />
          <Column<any>
            title={'Email'}
            dataIndex={'MemEmailId'}
            sorter={(a, b) => a?.MemEmailId.localeCompare(b?.MemEmailId)}

          />


          <Column<any>
            title={'Contact'}
            dataIndex={'MemMobileNo'}
            sorter={(a, b) => Number(b.MemMobileNo) - Number(a.MemMobileNo)}
          />
          {/* 
          <Column<Volunteer>
            title={t('adminUsers.table.date')}
            dataIndex={'user'}
            render={(_, record) => moment(record.CreatedDate).format("YYYY-MM-DD HH:MM:SS")}

          /> */}

          <Column<any>
            title={'Status'}
            sorter={(a, b) => Number(b.MemStatus) - Number(a.MemStatus)}
            render={(value: any, record: any) => {
              return (
                <h1>{record.MemStatus}</h1>
              )
            }}
          />

          <Column<Volunteer>
            title={"Action"}
            dataIndex={'Status'}
            render={(_, record) => (
              <Dropdown
                menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                trigger={['click']} className='drop_down_cstm'
              >
                <Button shape={"circle"} icon={<MoreOutlined />} />
              </Dropdown>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </div>
  );
};
