import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { StyledWrapper } from './loader.styles';

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper align='center'>
      <Spin size='large'
      // tip={t('common.loading')}
      />
    </StyledWrapper>
  );
};
