import { Button, Col, DatePicker, Form, Input, Row, Select, Image, message } from 'antd';
import dayjs from 'dayjs';
import { useUpdatePromotions } from 'features/marketing/use-cases/update-promotions';
import { useEffect, useRef, useState } from 'react';
import { payFormatDate } from 'utils/payload-date-';
import { AddPromotionFormField, AddPromotionFormValues } from './types';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { handleKeyDownSix } from 'utils/key-press';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import noimg from 'assets/No_Image_Available.jpg';



export const AddPromotionModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-promotion-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddPromotionFormValues>();


    const { isLoading, mutate } = useUpdatePromotions();
    const [imageAr, setImageAr] = useState<File | null>(null);
    const [imageEn, setImageEn] = useState<any>(null);
    const [fileEnName, setFileEnName] = useState<string>("");
    const [fileArName, setFileArName] = useState<string>("");

    const handleClose = () => {
        onClose();
        form.resetFields();
        setImageEn(null);
        setImageAr(null)
        setFileEnName("");
        setFileArName("")
    }


    useEffect(() => {
        if (editData?.NewsImage) {
            const extractedFileName = editData?.NewsImage.split('/').pop();
            setFileEnName(extractedFileName || 'ImageEn.jpg');
        }
        if (editData?.NewsImage_Ar) {
            const extractedFileName = editData?.NewsImage_Ar.split('/').pop();
            setFileArName(extractedFileName || 'ImagAr.jpg');
        }
    }, [editData]);

    const inputRef = useRef<any>();
    const inputArRef = useRef<any>();


    const getInitialValues = {
        [AddPromotionFormField.Flag]: undefined,
        [AddPromotionFormField.Id]: undefined,
        [AddPromotionFormField.Brand]: undefined,
        [AddPromotionFormField.GroupCategory]: undefined,
        [AddPromotionFormField.Category]: undefined,
        [AddPromotionFormField.Name]: undefined,
        [AddPromotionFormField.NameAr]: undefined,
        [AddPromotionFormField.Offer]: undefined,
        [AddPromotionFormField.OfferType]: undefined,
        [AddPromotionFormField.StartDate]: undefined,
        [AddPromotionFormField.EndDate]: undefined,
        [AddPromotionFormField.BannerAR]: undefined,
        [AddPromotionFormField.BannerEn]: undefined,
    };

    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                ...editData,
                [AddPromotionFormField.StartDate]: dayjs(editData?.StartDate),
                [AddPromotionFormField.EndDate]: dayjs(editData?.EndDate),
            });
            setSubCatId(editData?.Group_Fk_Id)
        } else {
            form.resetFields();
        }

    }, [editData, form]);


    const [subCatId, setSubCatId] = useState<number | null>(null);

    const { data: subCategory, isLoading: subCatLoading, refetch } = useGetDropdownLists({ Flag: "GetSubCategoryListByParentId", parentId: subCatId }, { enabled: false });
    const { data: category, isLoading: catLoading } = useGetDropdownLists({ Flag: "GetGroupList" });
    const { data: brandData, isLoading: brandLoading } = useGetDropdownLists({ Flag: "GetBrandList" });
    const { data: offerTypeData, isLoading: offerTypeLoad } = useGetDropdownLists({ Flag: "GetOfferTypeList" });



    useEffect(() => {
        if (subCatId) {
            refetch();
        }
    }, [subCatId])


    const handleUpdate = (formData: AddPromotionFormValues) => {

        const formDataToSend = new FormData();

        formDataToSend.append(AddPromotionFormField.Flag, "Insert");
        if (editData?.Id) {
            formDataToSend.append(AddPromotionFormField.Id, editData.Id);
        }

        if (formData.Brand_Fk_Id) formDataToSend.append(AddPromotionFormField.Brand, formData.Brand_Fk_Id);
        if (formData.Group_Fk_Id) formDataToSend.append(AddPromotionFormField.GroupCategory, formData.Group_Fk_Id);
        if (formData.Category_Fk_Id) formDataToSend.append(AddPromotionFormField.Category, formData.Category_Fk_Id);
        formDataToSend.append(AddPromotionFormField.Name, formData.Name_En);
        formDataToSend.append(AddPromotionFormField.NameAr, formData.Name_Ar);
        formDataToSend.append(AddPromotionFormField.StartDate, payFormatDate(formData.StartDate));
        formDataToSend.append(AddPromotionFormField.EndDate, payFormatDate(formData.EndDate));
        formDataToSend.append(AddPromotionFormField.OfferType, formData.OfferType_Fk_Id);
        formDataToSend.append(AddPromotionFormField.Offer, formData.Offer);

        // Append files (checking if they exist)
        if (imageEn) {
            formDataToSend.append(AddPromotionFormField.BannerEn, imageEn);
        }

        if (imageAr) {
            formDataToSend.append(AddPromotionFormField.BannerAR, imageAr);
        }

        mutate(formDataToSend, { onSuccess: handleClose });
    };



    const onReset = () => {
        form.resetFields();
        onClose();
    };



    const { t } = useTranslation();

    const validateDates = (getFieldValue: (arg0: AddPromotionFormField) => any) => ({
        validator() {
            const startDate = getFieldValue(AddPromotionFormField.StartDate);
            const endDate = getFieldValue(AddPromotionFormField.EndDate);

            if (startDate && endDate && startDate.isAfter(endDate)) {

                return Promise.reject(new Error('End Date must be after or equal to Start Date'));
            }

            return Promise.resolve();
        },
    });

    const getSubCategory = (val: number) => {
        setSubCatId(val)
        form.resetFields([AddPromotionFormField.Category])
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageEn(selectedFile);
                setFileEnName(selectedFile.name);

            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageEn(null);
                setFileEnName("");
            }
        }
    };

    const handleFileArChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageAr(selectedFile);
                setFileArName(selectedFile.name);
            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageAr(null);
                setFileArName("");
            }
        }
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Promotion' : 'Add Promotion'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddPromotionFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >
                            <div className='prmo_form'>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Brand"}
                                            name={AddPromotionFormField.Brand}
                                        >
                                            <Select
                                                showSearch
                                                options={brandData?.map((item: { Text: string; Value: number }) => ({
                                                    label: item.Text,
                                                    value: item.Value,
                                                }))}
                                                filterOption={(input, option) =>
                                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={"Brand"}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Group Category"}
                                            name={AddPromotionFormField.GroupCategory}
                                        >
                                            <Select
                                                showSearch
                                                options={category?.map((item: { Text: string; Value: number }) => ({
                                                    label: item.Text,
                                                    value: item.Value,
                                                }))}
                                                filterOption={(input, option) =>
                                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={"Group Category"}
                                                onChange={(value) => getSubCategory(value)}
                                            />
                                        </Form.Item>

                                    </Col>

                                    <Col span={8}>
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Category"}
                                            name={AddPromotionFormField.Category}
                                        >
                                            <Select
                                                showSearch
                                                options={subCategory?.map((item: { Text: string; Value: number }) => ({
                                                    label: item.Text,
                                                    value: item.Value,
                                                }))}
                                                filterOption={(input, option) =>
                                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={"Category"}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Name"}
                                            name={AddPromotionFormField.Name}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={"Name"}
                                                prefix={<UserOutlined className='input-prefix-icon' />}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Name(Arabic)"}
                                            name={AddPromotionFormField.NameAr}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={" Name(Arabic)"}
                                                prefix={<UserOutlined className='input-prefix-icon' />}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Start Date"}
                                            name={AddPromotionFormField.StartDate}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <DatePicker format="DD-MM-YYYY" placeholder='Start Date'
                                                disabledDate={(current) => current && current.isBefore(dayjs().startOf('day'))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"End Date"}
                                            name={AddPromotionFormField.EndDate}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                                validateDates(form.getFieldValue),
                                            ]}
                                        >
                                            <DatePicker
                                                format="DD-MM-YYYY"
                                                placeholder='End Date'
                                                disabledDate={(current) => {
                                                    const startDate = form.getFieldValue(AddPromotionFormField.StartDate);

                                                    return current && startDate && startDate.isAfter(current);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Offer Type"}
                                            name={AddPromotionFormField.OfferType}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                loading={offerTypeLoad}
                                                showSearch
                                                options={offerTypeData?.map((item: { Text: string; Value: number }) => ({
                                                    label: item.Text,
                                                    value: item.Value,
                                                }))}
                                                filterOption={(input, option) =>
                                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={"Offer Type"}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12} >
                                        <Form.Item<AddPromotionFormValues>
                                            label={"Offer"}
                                            name={AddPromotionFormField.Offer}
                                            rules={[
                                                {
                                                    required: true,

                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={"Offer"}
                                                onKeyDown={handleKeyDownSix}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Form.Item<AddPromotionFormValues>
                                    label={"Banner En"}
                                    name={AddPromotionFormField.BannerEn}
                                >
                                    <Input
                                        prefix={<Button type="primary" size="small" onClick={() => inputRef.current.click()}>Choose File</Button>}
                                        value={fileEnName}
                                        readOnly
                                    />
                                    <input
                                        type='file'
                                        // id='fileInput'
                                        ref={inputRef}
                                        style={{ display: 'none' }}
                                        accept=".jpg,.png,.jpeg"
                                        onChange={handleFileChange}
                                    />
                                    <span>
                                        Recommended size-
                                        Width : 1050 px,
                                        Height : 1050 px
                                    </span>
                                </Form.Item>

                                <>
                                    <div className='edit_img_show'>
                                        {imageEn ? (
                                            <Image
                                                src={URL.createObjectURL(imageEn)}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Preview"
                                            />
                                        ) : (

                                            editData?.Banner_En && (
                                                <Image
                                                    src={editData.Banner_En}
                                                    width={"120px"}
                                                    height={"90px"}
                                                    alt="Brand Logo"
                                                    fallback={noimg}
                                                />
                                            )
                                        )}
                                    </div>
                                </>

                                <Form.Item<AddPromotionFormValues>
                                    label={"Banner (Arabic)"}
                                    name={AddPromotionFormField.BannerAR}
                                >
                                    <Input
                                        prefix={<Button type="primary" size="small" onClick={() => inputArRef.current.click()}>Choose File</Button>}
                                        value={fileArName}
                                        readOnly
                                    />
                                    <input
                                        ref={inputArRef}
                                        type='file'
                                        style={{ display: 'none' }}
                                        accept=".jpg,.png,.jpeg"
                                        onChange={handleFileArChange}
                                    />
                                    <span>
                                        Recommended size-
                                        Width : 1050 px,
                                        Height : 1050 px
                                    </span>
                                </Form.Item>
                                <>
                                    <div className='edit_img_show'>
                                        {imageAr ? (
                                            <Image
                                                src={URL.createObjectURL(imageAr)}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Preview"
                                            />
                                        ) : (

                                            editData?.Banner_Ar && (
                                                <Image
                                                    src={editData.Banner_Ar}
                                                    width={"120px"}
                                                    height={"90px"}
                                                    alt="Brand Logo"
                                                    fallback={noimg}
                                                />
                                            )
                                        )}
                                    </div>
                                </>
                            </div>
                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" loading={isLoading}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

