import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Skeleton, Space, Switch, Tooltip } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateCommonStatus } from 'features/admin/use-cases/update-common-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { CustomSizeFilterFormValuesPaginated } from '../filter-form/custom-size-master-filter/types';
import { EditCustomSizeModal } from '../models/custom-size-master-model/custom-size-model';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: CustomSizeFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const CustomSizeMasterTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const { mutate: mutateUpdateStatus } = useUpdateCommonStatus();

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenModel(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,
    ]

    const handleOpenModel = (record: any) => {
        setOpenEdit(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseModal = () => {
        setOpenEdit(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <EditCustomSizeModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {permission.Totals && <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />}
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.Items}

                >

                    <Column<any>
                        title={'Custom Size'}
                        dataIndex={'CustomSizes'}
                        sorter={(a, b) => a.CustomSizes && a.CustomSizes.localeCompare(b.CustomSizes)}
                    />

                    <Column<any>
                        title={'Custom Size (Arabic)'}
                        dataIndex={'CustomSizes_Ar'}
                        sorter={(a, b) => a.CustomSizes_Ar && a.CustomSizes.localeCompare(b.CustomSizes_Ar)}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        render={(_, record) => (
                            <h1>{record?.IsActive ? "Active" : "InActive"}</h1>
                        )}
                    />

                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                                <Tooltip>

                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(isActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "CustomSizeChangeStatus",
                                                Status: isActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = isActive; // Ensure it's an integer
                                        }}
                                    />
                                </Tooltip>

                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
