import { Badge } from 'antd';
import styled from 'styled-components';
import { colors } from 'styles';
import { usePermission } from 'utils/user-permission-page';

type RecordCountBadgeProps = {
  text: string;
  count?: number;
};

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;

  .badge sup {
    color: ${({ theme }) => theme.antd.colorPrimaryText};
    box-shadow: none;
    font-weight: 500;
  }
`;

export const RecordCountBadge = ({ text, count }: RecordCountBadgeProps) => {
  const permission = usePermission();
  if (!permission.Totals) return null;

  return (
    <StyledWrapper>
      {text}
      <Badge
        className='badge'
        count={count}
        showZero
        color={colors.li3ibgreen1}
        overflowCount={Number.MAX_SAFE_INTEGER}
      />
    </StyledWrapper>
  );
};
