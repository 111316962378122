import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useMarketingCommonStatus } from 'features/marketing/use-cases/update-common-marketing-status';
import { Dispatch, SetStateAction, useState } from 'react';

import noImg from '../../../../assets/No_Image_Available.jpg';
import { BenefitsFilterFormPaginated } from '../filter-form/benefits-list-filter/types';
import { AddBenefitsModal } from '../models/add-benefits-model/add-benefits-model';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    isFetching: boolean;
    benefitsList: any;
    itemsCount: number;
    tableParams: BenefitsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const BenefitsListTable = ({
    benefitsList,
    isLoading,
    itemsCount,
    tableParams,
    isFetching,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,

        });
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useMarketingCommonStatus();



    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleEdit(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null
    ]


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEdit = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }

    return (
        <div className='order_rtn'>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddBenefitsModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isFetching}
                    dataSource={isLoading ? [] : benefitsList}
                >

                    <Column<any>
                        title={'Type'}
                        // dataIndex={'BenefitsType'}
                        sorter={(a, b) => Number(b.BenefitsType) - Number(a.BenefitsType)}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record?.BenefitsType === 1 ? "Customer" : record?.BenefitsType === 2 ? "Designer" : ""} </h1>

                            )
                        }}
                    />

                    <Column<any>
                        title={'Title'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => a.Title_En.localeCompare(b.Title_En)}
                    />

                    <Column<any>
                        title={'Title (Arabic)'}
                        dataIndex={'Title_Ar'}
                        sorter={(a, b) => a.Title_Ar.localeCompare(b.Title_Ar)}
                    />

                    <Column<any>
                        title={'Logo'}
                        render={(value: any, record: any) => {
                            return (
                                <Image src={record?.LogoIcon} width={40} height={40}
                                    onError={(e) => {
                                        e.currentTarget.src = noImg;
                                    }}
                                />

                            )
                        }}
                    />


                    <Column<any>
                        title={'Status'}
                        // dataIndex={'SalesPrice'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        render={(value: any, record: any) => {
                            return (
                                <h1 >
                                    {record?.IsActive ? "Active" : "InActive"}
                                </h1>

                            )
                        }}
                    />


                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        loading={isLoadingUpdate}
                                        onChange={(IsActive) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "BenefitsChangeStatus",
                                                Status: IsActive ? 1 : 0,
                                            });
                                            record.IsActive = IsActive;
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />


                </Table>
            </StyledTableWrapper >
        </div>
    );
};
