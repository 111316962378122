import { Outlet } from 'react-router-dom';
import { UitilesListTable } from '../componens/uitiles-list-table/uitiles-list-table';
import { usePermission } from 'utils/user-permission-page';


export const UitilesView = () => {

    // const { params, updateParams, resetPage } =
    //     usePaginationParams<any>({
    //         CurrentPage: 1,
    //         RecordsPerPage: 10,
    //         // order_by: 'name',
    //         ...getInitialFilterValues,
    //     });

    // const { data, isLoading } = useGetSystemLists((params), {
    //     retry: false,
    // });

    // const handleFiltersChange = (values: SystemFilterFormPaginated) => {
    //     updateParams(values);
    //     resetPage();
    // };
    const permission = usePermission();

    if (!permission.View) return null;

    return (
        <>
            {/* <UitilesListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
                tableParams={params}
            /> */}

            <UitilesListTable
            />
            <Outlet />
        </>
    );
};

