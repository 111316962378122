// import { usePermissionContext } from "features/users/permission-context";

import { useUserContext } from "features/users";
import { useLocation } from "react-router-dom";

// export const hasPagePermission = (currentPage: any, action: string): boolean => {
//     const { menuChildren } = usePermissionContext();
//     const currentPath = currentPage.replace(/\/\d+\/detail$/, "");

//     for (const parentMenu of menuChildren) {
//         if (parentMenu.Menu_Childrens) {
//             const childMenu = parentMenu.Menu_Childrens.find(
//                 (child: { Menu_LinkPath: string }) => `/${child.Menu_LinkPath}` === currentPath
//             );

//             if (childMenu && childMenu.permissionPrivilege) {
//                 return childMenu.permissionPrivilege.some(
//                     (perm: { Privilege: string; IsAccessible: boolean }) =>
//                         perm.Privilege.toLowerCase() === action.toLowerCase() &&
//                         perm.IsAccessible
//                 );
//             }
//         }
//     }

//     return false;
// };


// import { message } from "antd";
// import { usePermissionContext } from "features/users/permission-context";
// import { useMemo } from "react";
// import { useLocation } from "react-router-dom";

// export const usePagePermission = (currentPage: string, action: string) => {
//     const { menuChildren } = usePermissionContext();

//     const currentPath = currentPage.replace(/\/\d+\/detail$/, "");

//     const hasPermission = menuChildren.some((parentMenu) =>
//         parentMenu.Menu_Childrens?.some((child: { Menu_LinkPath: any; permissionPrivilege: any[]; }) => {
//             if (`/${child.Menu_LinkPath}` === currentPath && child.permissionPrivilege) {
//                 return child.permissionPrivilege.some(
//                     (perm) =>
//                         perm.Privilege.toLowerCase() === action.toLowerCase() &&
//                         perm.IsAccessible
//                 );
//             }
//             return false;
//         })
//     );

//     return hasPermission;
// };

// import { message } from "antd";
// import { usePermissionContext } from "features/users/permission-context";
// import { useLocation } from "react-router-dom";

// export const hasPagePermission = (action: string): boolean => {
//     const location = useLocation();
//     const { menuChildren } = usePermissionContext();
//     const currentPath = location.pathname.replace(/\/\d+\/detail$/, "");
//     // const errorMsg = useMemo(() => message.error("No permission to view this page."), [location.pathname])
//     for (const parentMenu of menuChildren) {
//         if (parentMenu.Menu_Childrens) {
//             const childMenu = parentMenu.Menu_Childrens.find(
//                 (child: { Menu_LinkPath: string }) => `/${child.Menu_LinkPath}` === currentPath
//             );

//             if (childMenu && childMenu.permissionPrivilege) {
//                 const hasPermission = childMenu.permissionPrivilege.some(
//                     (perm: { Privilege: string; IsAccessible: boolean }) =>
//                         perm.Privilege.toLowerCase() === action.toLowerCase() &&
//                         perm.IsAccessible
//                 );

//                 if (!hasPermission) {
//                     message.error("No permission to view this page.")
//                 }
//                 // errorMsg

//                 return hasPermission;
//             }
//         }
//     }

//     return false;
// };


// export const hasPagePermission = (action: string): boolean => {
//     const { user } = useUserContext();
//     const location = useLocation();

//     if (checkPermission(user, location.pathname, action)) {
//         return true
//     }

//     return false;
// };

// export const usePermission = (action: string): boolean => {
//     const { user } = useUserContext();
//     const location = useLocation();

//     return checkPermission(user, location.pathname, action);
// };


// export const useViewPermission = (): boolean => usePermission("View");
// export const useAddPermission = (): boolean => usePermission("Add");
// export const useEditPermission = (): boolean => usePermission("Edit");
// export const useDeletePermission = (): boolean => usePermission("Delete");
// export const usePDFPermission = (): boolean => usePermission("Print");
// export const useExcelPermission = (): boolean => usePermission("Export");
// export const useTotalPermission = (): boolean => usePermission("Totals");



// export const hadPermission = (action: string): boolean => {
//     const { user } = useUserContext();
//     const location = useLocation();
//     const normalizedPath = location.pathname.replace(/\/:?[a-zA-Z0-9]+\/detail$/, "");
//     console.log(user, "sd")
//     return user?.resultMenu[normalizedPath]?.[action];
// };

export type PermissionTypes = "View" | "Add" | "Edit" | "Delete" | "Print" | "Export" | "Totals";

export const usePermission = (): Record<PermissionTypes, boolean> => {
    const { user } = useUserContext();
    const location = useLocation();
    const normalizedPath = location.pathname.replace(/\/:?[a-zA-Z0-9]+\/detail$/, "");
    const permissionTypes: PermissionTypes[] = [
        "View",
        "Add",
        "Edit",
        "Delete",
        "Print",
        "Export",
        "Totals",
    ];

    // Dynamically generate permissions object
    const permissions = permissionTypes.reduce((acc, type) => {
        acc[type] = user?.resultMenu[normalizedPath]?.[type];
        return acc;
    }, {} as Record<PermissionTypes, boolean>);

    return permissions;
};









