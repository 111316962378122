import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { SubscribersFilterFormField, SubscribersFilterFormPaginated, SubscribersFilterFormValues } from '../components/filter-form/suscribers-list-filter/types';
import { SubscribersListTable } from '../components/subscribers-list-table/subcribers-list-table';
import { SubscribersListTopBar } from '../components/tob-bar/subscribers-list-topbar';
import { useGetSubscribersLists } from '../use-cases'; import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: SubscribersFilterFormValues = {
  [SubscribersFilterFormField.Email]: undefined,

};


export const SubscribersListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<SubscribersFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetSubscribersLists((params), {
    retry: false,
    enabled: permission.View
  });

  if (!permission.View) return null;

  const handleFiltersChange = (values: SubscribersFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <SubscribersListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <SubscribersListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};

