import { usePaginationParams } from 'hooks';
import { Outlet, useLocation } from 'react-router-dom';

import { CMSTypeTable } from '../components/cms-type-tabel/cms-type-table';
import { CMSTypeFilterFormValues, CMSTypeFilterFormValuesPaginated } from '../components/filter-form/cms-type-filter/types';
import { CMSTypeTopBar } from '../components/top-bar/cms-type-topbar';
import { useGetCMSTypeLists } from '../use-cases/get-cms-type-list';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: CMSTypeFilterFormValues = {
    Name: undefined,
};

export const CMSTypeView = () => {
    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<CMSTypeFilterFormValuesPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, isLoading, isFetching, refetch } = useGetCMSTypeLists((params), {
        retry: false,
        enabled: permission.View
    });

    if (!permission.View) return null;

    const handleFiltersChange = (values: CMSTypeFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <CMSTypeTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <CMSTypeTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}
                isFetching={isFetching}
            />
            <Outlet />
        </>
    );
};






