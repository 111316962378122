import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { CustomerFilterFormValues } from 'features/customers/components/filter-form/customer-filter/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandsFilterFormField, BrandsFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: BrandsFilterFormValues) => void;
    initialValues: BrandsFilterFormValues;
    closeModal?: () => void;
};

export const BrandsListFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<BrandsFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: BrandsFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };

    const statusData = [
        // { label: 'All', value: '' },
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' }
    ];


    return (
        <Form<BrandsFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>

                        <Form.Item<BrandsFilterFormValues>
                            label={'Brand Name'}
                            name={BrandsFilterFormField.Title}
                        >
                            <Input
                                placeholder={"Brand Name"}
                            />
                        </Form.Item>



                        <Form.Item<BrandsFilterFormValues>
                            label={"Status"}
                            name={BrandsFilterFormField.IsActive}
                        >
                            <Select
                                allowClear
                                options={statusData}
                                className='has-prefix-icon'
                                placeholder={"Select Status"}
                            />
                        </Form.Item>
                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
