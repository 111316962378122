import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { CustomSizeMasterTable } from '../components/custom-size-master-tabel/custom-size-master-tabel';
import { CustomSizeFilterFormValues, CustomSizeFilterFormValuesPaginated } from '../components/filter-form/custom-size-master-filter/types';
import { CustomSizeMasterTopBar } from '../components/top-bar/custom-size-master-topbar';
import { useGetCustomSizeMasterLists } from '../use-cases/get-custom-size-master';
import { usePermission } from 'utils/user-permission-page';
// import { userPermissionPage } from 'utils/user-permission-page';



const getInitialFilterValues: CustomSizeFilterFormValues = {
    StatusId: undefined,
    Title: undefined,
};

export const CustomSizeMasterView = () => {

    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<CustomSizeFilterFormValuesPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetCustomSizeMasterLists((params), {
        retry: false,
        enabled: permission.View
    });

    if (!permission.View) return null;


    const handleFiltersChange = (values: CustomSizeFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <CustomSizeMasterTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />
            <CustomSizeMasterTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};





