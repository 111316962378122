import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ContactListTable } from '../components/contact-list-table/contact-list-table';
import { ContactFilterFormField, ContactFilterFormPaginated, ContactFilterFormValues } from '../components/filter-form/contact-list-filter/types';
import { ContactListTopBar } from '../components/tob-bar/contact-list-topbar';
import { useGetContactLists } from '../use-cases'; import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: ContactFilterFormValues = {
  [ContactFilterFormField.Name]: undefined,
  [ContactFilterFormField.Email]: undefined,
  [ContactFilterFormField.MobileNo]: undefined,
  [ContactFilterFormField.ContactType]: undefined,

};


export const ContactListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<ContactFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetContactLists((params), {
    retry: false,
    enabled: permission.View
  });

  if (!permission.View) return null;


  const handleFiltersChange = (values: ContactFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <ContactListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <ContactListTable
        isLoading={isLoading}
        isFetching={isFetching}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

