import { PaginatedRequestParams } from "api";

export enum ItemsTransactionFilterFormField {
    FromDate = 'fromDate',
    ToDate = 'toDate',
    Name = 'Name',
    EmailId = 'EmailId',
    MobileNo = 'MobileNo',
    TransactionId = 'TransactionId',

    PaymentType = 'PaymentType',
    InvoiceType = 'InvoiceType',
    PaymentStatus = 'PaymentStatus',
    Brand = 'BrandName_En',
    IsCustomItems = 'IsCustomItems',
    Status = 'StatusText',
}

export type ItemsTransactionFilterFormValues = {
    [ItemsTransactionFilterFormField.FromDate]: string | undefined;
    [ItemsTransactionFilterFormField.ToDate]: string | undefined;
    [ItemsTransactionFilterFormField.Name]: string | undefined;
    [ItemsTransactionFilterFormField.EmailId]: string | undefined;
    [ItemsTransactionFilterFormField.MobileNo]: string | undefined;
    [ItemsTransactionFilterFormField.TransactionId]: string | undefined;


    [ItemsTransactionFilterFormField.PaymentType]: string | undefined;
    [ItemsTransactionFilterFormField.InvoiceType]: string | undefined;
    [ItemsTransactionFilterFormField.PaymentStatus]: string | undefined;
    [ItemsTransactionFilterFormField.Brand]: string | undefined;
    [ItemsTransactionFilterFormField.IsCustomItems]: boolean | undefined;

    [ItemsTransactionFilterFormField.Status]: string | undefined;
};

export type ItemsTransactionFilterFormPaginated = ItemsTransactionFilterFormValues &
    PaginatedRequestParams;