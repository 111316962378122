import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { FaqListTable } from '../components/faq-list-table/faq-list-table';
import { FaqFilterFormField, FaqFilterFormPaginated, FaqFilterFormValues } from '../components/filter-form/faq-list-filter/types';
import { FaqListTopBar } from '../components/tob-bar/faq-list-topbar';
import { useGetFaqLists } from '../use-cases';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: FaqFilterFormValues = {
  [FaqFilterFormField.Question]: undefined,
};


export const FaqListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<FaqFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetFaqLists((params), {
    keepPreviousData: false,
    enabled: permission.View
  });

  if (!permission.View) return null;

  const handleFiltersChange = (values: FaqFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <FaqListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <FaqListTable
        isLoading={isLoading}
        isFetching={isFetching}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

