import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Skeleton } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';

import { EditDeliveryDaysModal } from '../models/delivery-days-model/delivery-days-model';
import { usePermission } from 'utils/user-permission-page';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: UserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const DeliveryDaysTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenModel(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,
    ]

    const handleOpenModel = (record: any) => {
        setOpenEdit(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseModal = () => {
        setOpenEdit(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <EditDeliveryDaysModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {permission.Totals && <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />}
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} />
                            : !isLoading && !itemList?.items ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.items}
                    className='cstm_table'

                >


                    <Column<any>
                        title={'Row Number'}
                        dataIndex={'Rownumber'}
                        sorter={(a, b) => a.Rownumber && a.Rownumber - b.Rownumber}
                    />

                    <Column<any>
                        title={'Delivery Days'}
                        dataIndex={'DelieveryDays'}
                        sorter={(a, b) => a.DelieveryDays && a.DelieveryDays - b.DelieveryDays}
                    />


                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Dropdown
                                menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                trigger={['click']} className='drop_down_cstm'
                            >
                                <Button shape={"circle"} icon={<MoreOutlined />} />
                            </Dropdown>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
