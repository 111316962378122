import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { OfferTypeFilterFormField, OfferTypeFilterFormPaginated, OfferTypeFilterFormValues } from '../components/filter-form/offer-type-list-filter/types';
import { OfferTypeListTable } from '../components/offer-type-list-table/offer-type-list-table';
import { OfferTypeListTopBar } from '../components/tob-bar/offer-type-list-topbar';
import { useGetOfferTypeLists } from '../use-cases';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: OfferTypeFilterFormValues = {
  [OfferTypeFilterFormField.OfferTypeType]: undefined,
  [OfferTypeFilterFormField.IsActive]: undefined,
};


export const OfferTypeListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<OfferTypeFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetOfferTypeLists((params), {
    retry: false,
    enabled: permission.View
  });

  if (!permission.View) return null;

  const handleFiltersChange = (values: OfferTypeFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <OfferTypeListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <OfferTypeListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};

