import { PlusOutlined } from '@ant-design/icons';
import { Button, Grid } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';

import { AddSystemModal } from '../modal/add-system';
import { SystemListFilterForm } from '../system-list-filter/system-list-filter-form';
import { SystemFilterFormValues } from '../system-list-filter/types';
import { usePermission } from 'utils/user-permission-page';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: SystemFilterFormValues;
    handleFilter: (values: SystemFilterFormValues) => void;
};

export const SystemListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    const permission = usePermission();

    const [showModal, setShowModal] = useState(false);

    const screens = useBreakpoint();

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'visible';
    };

    const handleOpenModal = () => {
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    };

    return (
        <div>
            {permission.Add &&
                <div className='head_btn'>
                    <Button
                        icon={!screens.xs ? <PlusOutlined /> : undefined}
                        type='primary'
                        onClick={handleOpenModal}
                    >
                        Add System Setting
                    </Button>
                </div>}

            <div className={`cstmrightmdl ${showModal && "show"}`}>
                <AddSystemModal onClose={handleCloseModal} editData={""} />
            </div>

            <ContentFilters formId="order-list-filters-form">
                <SystemListFilterForm
                    formId="order-list-filters-form"
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </div>
    );
};
