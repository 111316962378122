import { DeleteOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, message, Modal, Skeleton, Space, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { ItemsApiService } from 'features/items/api/items-api.service';
import { useGetItemSizeListById } from 'features/items/use-cases/get-item-size-list-by-id';
import { useUpdateStatusItemSizeById } from 'features/items/use-cases/update-status-items-size-byid';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';


const ItemSizeTab = ({ id, tabs }: { id: number; tabs: string }) => {

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });
    const { data, isLoading, refetch, isFetching } = useGetItemSizeListById((params));

    const { mutate } = useUpdateStatusItemSizeById();

    useEffect(() => {
        if (tabs === "2") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }

    const menuItems = (record: any) => [
        {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
                Delete
            </Button>,
        },
    ]


    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Items Size?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
        });
    };

    const handleDelete = async (record: any) => {
        mutate({
            SizeType_Fk_Id: record.SizeType_Fk_Id,
            Size_Fk_Id: record.Size_Fk_Id,
            Flag: "DeleteSize",
        });
    };

    const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

    const handleGetBarcode = async (recordData: any) => {
        setLoading((prev) => ({ ...prev, [recordData?.Rownumber]: true }));
        try {
            const response = await ItemsApiService().getBarcodeByItemsId(recordData?.Id);
            const base64String = response.data.barCodes?.[0]?.FileName;
            if (base64String) {
                downloadImage(base64String);
            }
            setLoading((prev) => ({ ...prev, [recordData?.Rownumber]: false }));
        } catch (error: any) {
            message.error(error);
        }
    };;


    const downloadImage = (base64String: string) => {
        const formattedBase64 = base64String.startsWith('data:image/')
            ? base64String
            : `data:image/jpeg;base64,${base64String}`;
        const link = document.createElement('a');
        link.href = formattedBase64;
        link.download = 'barcode.jpg'; // You can modify the filename here
        link.click();
    };


    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Sizes'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Rownumber'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.CurrentPage,
                        defaultPageSize: params.RecordsPerPage,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading || isFetching}
                    dataSource={isLoading ? [] : data?.itemSizeDetails}
                >
                    <Column<any>
                        title={'SKU'}
                        dataIndex={'SKU'}
                        sorter={(a, b) => a?.SKU.localeCompare(b?.SKU)}
                    />

                    <Column<any>
                        title={'Size'}
                        dataIndex={'Size'}
                        sorter={(a, b) => a?.Size.localeCompare(b?.Size)}
                    />

                    <Column<any>
                        title={'Price'}
                        dataIndex={'ItemPrice'}
                        sorter={(a, b) => Number(a.ItemPrice) - Number(b.ItemPrice)}
                    />

                    <Column<any>
                        title={'Reorder Qty'}
                        dataIndex={'MinStockQty'}
                        sorter={(a, b) => Number(a.MinStockQty) - Number(b.MinStockQty)}

                    />

                    <Column<any>
                        title={'Status'}
                        sorter={(a, b) => Number(a.IsActive) - Number(b.IsActive)}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record.IsActive ? "Active" : "Inactive"} </h1>
                            )
                        }}
                    />


                    <Column<any>
                        title="Barcode"
                        dataIndex="Block"
                        render={(_: any, record: any) => {
                            return <Button
                                onClick={() => handleGetBarcode(record)}
                                loading={loading[record.Rownumber]}
                            >
                                Download Barcode
                            </Button>
                        }}
                    />


                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (

                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip title={"Is Active"}>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        // loading={isLoading}
                                        onChange={(IsActive) => {
                                            mutate({
                                                SizeType_Fk_Id: record.SizeType_Fk_Id,
                                                Size_Fk_Id: record.Size_Fk_Id,
                                                Flag: "UpdateStatus",
                                            });
                                            record.IsActive = IsActive;
                                        }}
                                    />
                                </Tooltip>

                            </Space>

                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div >
    )
}

export default ItemSizeTab

