import { Button, Form, Image, Input, message } from 'antd';
import { useUpdateCategory } from 'features/items/use-cases/upload-items-category';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import noImg from '../../../../../assets/No_Image_Available.jpg';
import { AddCategoryFormField, AddCategoryFormValues } from './types';



export const EditCategoryModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'edit-category-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddCategoryFormValues>();

    const { t } = useTranslation();


    const { isLoading, mutate } = useUpdateCategory();
    const [catImage, setCatImage] = useState<File | null>(null);

    const handleClose = () => {
        onClose();
        setCatImage(null);
        form.resetFields();
        setIsPreviewAvailable(true)
    }


    const getInitialValues = {
        [AddCategoryFormField.Id]: undefined,
        [AddCategoryFormField.Flag]: undefined,
        [AddCategoryFormField.GroupName_En]: undefined,
        [AddCategoryFormField.GroupName_Ar]: undefined,
        [AddCategoryFormField.GroupLogo]: undefined,

    };

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

    }, [editData, form]);



    const handleUpdate = (formValues: AddCategoryFormValues) => {
        const formData = new FormData();

        Object.keys(formValues).forEach((key) => {
            if (key !== 'File') {
                formData.append(key, formValues[key as keyof AddCategoryFormValues] as string);
            }
        });

        formData.append('Flag', "insert");

        if (catImage) {
            formData.append('File', catImage);
        }

        if (editData) {
            formData.append('Id', editData.Id);
        }

        mutate(formData, { onSuccess: handleClose });

    };


    const onReset = () => {
        handleClose();
    };

    const [fileName, setFileName] = useState<string>("");

    useEffect(() => {
        if (editData?.GroupLogo) {
            const extractedFileName = editData?.GroupLogo.split('/').pop();
            setFileName(extractedFileName || 'BrandLogo.jpg');
        }
    }, [editData]);


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setCatImage(selectedFile);
                setFileName(selectedFile.name);
            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setCatImage(null);
                setFileName("");
            }
        }
    };


    const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);

    const handleImageError = () => {
        setIsPreviewAvailable(false);

    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Category' : 'Add Category'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddCategoryFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >
                            <Form.Item<AddCategoryFormField>
                                label={"Category Name"}
                                name={AddCategoryFormField.GroupName_En}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Category Name"}
                                />
                            </Form.Item>

                            <Form.Item<AddCategoryFormField>
                                label={"Category Name(Arabic)"}
                                name={AddCategoryFormField.GroupName_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Category Name(Arabic)"}
                                />
                            </Form.Item>


                            <Form.Item<AddCategoryFormField>
                                label={"Category Image"}
                                name={AddCategoryFormField.GroupLogo}
                            >
                                <div>

                                    <Input
                                        prefix={<Button type="primary" size="small" onClick={() => document.getElementById('fileInput')?.click()}>Choose File</Button>}
                                        value={fileName}
                                        readOnly
                                    />
                                    <input
                                        type='file'
                                        id='fileInput'
                                        style={{ display: 'none' }}
                                        accept=".jpg,.png,.jpeg"
                                        onChange={handleFileChange}
                                    />
                                    {/* <Input
                                    placeholder={"Category Image"}
                                    type='file'
                                    accept=".jpg,.png,.jpeg"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            setImage(e.target.files[0]); // Capture the file
                                        }
                                    }}
                                /> */}
                                    <div style={{ marginTop: '10px' }}>
                                        <p>Recommended size</p>
                                        <p>Width : 1050 px</p>
                                        <p>Height : 1050 px</p>
                                    </div>
                                </div>
                            </Form.Item>

                            <div className='edit_img_show'>
                                {catImage ? (
                                    <Image
                                        src={URL.createObjectURL(catImage)}
                                        width={"120px"}
                                        height={"90px"}
                                        alt="Preview"
                                    />
                                ) : (

                                    editData?.GroupLogo && (
                                        <Image
                                            src={editData.GroupLogo}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Brand Logo"
                                            fallback={noImg}
                                            onError={handleImageError}
                                            preview={isPreviewAvailable}
                                        />
                                    )
                                )}
                            </div>

                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button loading={isLoading} type="primary" htmlType='submit' className="btn" disabled={false}>Save</Button>
                            <Button onClick={onReset} danger type="primary" > Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

