import GraphikArabicMedium from 'assets/fonts/graphik-arabic-medium.woff2';
import GraphikArabicRegular from 'assets/fonts/graphik-arabic-regular.woff2';
import GraphikMedium from 'assets/fonts/graphik-medium.woff2';
import GraphikRegular from 'assets/fonts/graphik-regular.woff2';
// import patten_1 from 'assets/patten_01.png';
// import patten_2 from 'assets/patten_02.png';
// import {
//   dropdownMenu
// }
//   from 'components/dropdown/dropdown.styles';
import {
  createGlobalStyle
}
  from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'Graphik', 'GraphikArabic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

/* setting font size to 10px for easier rem calculations */
html {
  font-size: 62.5%;
}

;

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ant-typography strong {
  font-weight: 500;
}

.ant-layout-content {
  color: rgba(0, 0, 0, 0.88);
}




.prflbx {
  img {
    max-width: 125px;
  }
}

@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  src: url(${GraphikRegular}) format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 500;
  src: url(${GraphikMedium}) format('woff2');
}

@font-face {
  font-family: 'GraphikArabic';
  font-weight: 400;
  src: url(${GraphikArabicRegular}) format('woff2');
}


@font-face {
  font-family: 'GraphikArabic';
  font-weight: 500;
  src: url(${GraphikArabicMedium}) format('woff2');
}

.ant-picker-dropdown {
  .ant-picker-today-btn {
    color: $ {
      ({
        theme
      })=>theme.colors.greenLight
  }

  ;

  &:hover {
    color: $ {
      ({
        theme
      })=>theme.colors.greenDark
  }

  ;
}
}
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #00473a;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 13px;
  bottom: 11px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$ {
  dropdownMenu
}

;

.input-prefix-icon {
  color: $ {
    ({
      theme
    })=>theme.antd.colorIcon
}

;
margin-right: 8px;
}

;







/* Start Suffix Design Css 20-03-2024 */

.logo {
  svg {
    g {
      fill: #fff;
    }
  }
}




.cstm_filterbx {
  .cf_frm {
    .cf_frm_row {
      .ant-form-item {
        .ant-row {
          .ant-col {
            label {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.d-none {
  display: none
}


.cstmrightmdl {

  &.child_modal {
    .modal_overlay {
      z-index: 999;
    }

    .popup {
      width: 100%;
      max-width: 550px;

      .modal_body {
        .mb_cstmbx {
          .form-group.is_req {
            input {
              width: auto;
              vertical-align: middle;
              margin-right: 10px;
            }

            label {
              display: inline-block;
              margin: 0;
            }

            input[type="checkbox"] {
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }

  }

  .modal_overlay {
    width: 100vw;
    height: 100vh;
    background: #00000042;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 10;
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 3px 6px #00000054;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    visibility: visible;
    opacity: 1;

    .modal_head {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      line-height: 28px;
      height: 46px;

      h4 {
        color: rgba(0, 0, 0, 0.88);
        font-size: 16px;
        font-weight: 500;
      }

      .close-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 29px;
        color: rgba(0, 0, 0, 0.88);
      }
    }

    .modal_body {
      height: calc(100vh - 50px);
      overflow: auto;

      .mb_cstmbx {
        max-height: calc(100vh - 125px);
        overflow: auto;

        .cstm_form_group {
          margin-bottom: 15px;
          background: #f9f9f9;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #eee;

          label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 7px;
            display: block;
          }

          .form-control {
            font-size: 14px;
          }

          .mltyckbx {
            position: relative;

            select {
              position: relative;
              display: inline-block;
              width: 100%;
              min-width: 0;
              background-color: #ffffff;
              background-image: none;
              border-width: 1px;
              border-style: solid;
              border-color: #d9d9d9;
              border-radius: 6px;
              transition: all 0.2s;
              margin: 0;
              padding: 4px 9px;
              color: rgba(0, 0, 0, 0.88);
              font-size: 14px;
              height: 32px;
            }

            .ant-radio-group {
              display: flex;
              flex-wrap: wrap;

              label {
                border: 1px solid #d9d9d9;
                padding: 3px 7px;
                border-radius: 15px;
                font-size: 13px;
                display: flex;
                align-items: center;
              }
            }

            .ant-checkbox-wrapper {
              display: flex;
              flex-wrap: wrap;

              label {
                border: 1px solid #d9d9d9;
                padding: 3px 7px;
                border-radius: 15px;
                font-size: 13px;
                display: flex;
                align-items: center;
              }
            }

            .ant-checkbox-group {
              display: flex;
              flex-wrap: wrap;

              label {
                border: 1px solid #d9d9d9;
                padding: 3px 7px;
                border-radius: 15px;
                font-size: 13px;
                display: flex;
                align-items: center;
              }
            }

            .close-icon {
              position: absolute;
              right: 0px;
              top: -20px;
            }

          }

        }

        .form-group {
          margin-bottom: 15px;
          position: relative;

          label {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 5px;
            display: block;
          }

          .ant-select {
            width: 100%;

            &:after {
              content: ' ';
              border: solid #878686;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(45deg);
              position: absolute;
              top: 9px;
              right: 15px;
            }

            &.ant-select-show-arrow {
              &:after {
                display: none;
              }
            }
          }

          .form-control {
            font-size: 14px;
          }


          .mlty_bx {
            display: flex;
            margin-bottom: 10px;

            .form-control {
              margin-right: 10px;
            }

            .btn {}
          }

          .optnbx {
            display: flex;
            flex-wrap: wrap;

            .optn_cell {
              border: 1px solid #d9d9d9;
              padding: 3px 7px;
              border-radius: 15px;
              font-size: 13px;

              &+.optn_cell {
                margin-left: 7px;
              }

              &:hover {
                border-color: #e65b4e;
                color: #e65b4e;
              }

              .option-close-icon {}
            }
          }
        }


        .mf_cstm_bx {

          .cld_grp_tbn {
            text-align: right;
            margin-bottom: 5px;

            .btn {
              background: no-repeat;
              font-size: 12px;
              color: #ee3124;
              border: 1px solid #ee3124;
              border-radius: 3px;
              padding: 6px 12px;
              font-weight: 600;
            }
          }

          .group-button {
            .btn {
              background: #000 !important;
              color: #fff !important;
              font-size: 14px !important;
              padding: 6px 22px !important;
              border: none !important;
              border-radius: 4px !important;

              &:hover {
                background: rgba(0, 0, 0, 0.6) !important;
                color: #fff !important;
              }
            }
          }


          .modal_bx {
            .form-group {
              margin-bottom: 15px;

              label {
                font-size: 13px;
                font-weight: 500;
                margin-bottom: 5px;
                display: block;
              }

              .form-control {
                font-size: 14px;
              }

              .mlty_bx {
                display: flex;

              }
            }
          }
        }


      }


      padding: 15px;

      form {
        .ant-form-item {
          .ant-row {
            .ant-col {
              label {
                font-size: 14px;
              }

              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  .ant-input-affix-wrapper {
                    padding: 5px 11px;

                    .ant-input-prefix {}

                    .ant-input {
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .button-group {
        .btn {
          background: #000 !important;
          color: #fff !important;
          font-size: 14px !important;
          padding: 6px 22px !important;
          border: none !important;
          border-radius: 4px !important;

          &:hover {
            background: rgba(0, 0, 0, 0.6) !important;
            color: #fff;
          }
        }
      }
    }

  }
}

.main_side {
  .side-bar-sider {
    background: #181818;

    .ant-layout-sider-children {
      .ant-menu {
        background: #181818;

        .ant-menu-item {
          background: transparent;

          .ant-menu-title-content {
            a {
              color: #fff;
            }
          }
        }

        .ant-menu-submenu {

          // background: #2d2d2d;
          &:hover {
            background: #000;
          }

          border-bottom: 1px solid #292929;
          padding-bottom: 5px;

          // & + .ant-menu-submenu{margin-top:5px;}

          &.ant-menu-submenu-selected {
            background: #000;
          }

          .ant-menu-submenu-title {
            .ant-menu-title-content {
              color: #fff;
            }

            i {
              color: #fff;
            }
          }

          .ant-menu {
            padding-bottom: 10px;

            .ant-menu-item {
              height: auto;
              padding: 12px 0px;

              &:hover {
                background: #434343;
              }

              &.ant-menu-item-selected {
                background: #434343;

                .ant-menu-title-content {
                  font-width: 600;
                }
              }

              .ant-menu-title-content {
                line-height: normal;
                height: auto;

                a {
                  color: #fff;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .ant-layout-sider-trigger {
      background: #333;
    }

  }
}

.main_side::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.main_side::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.main_side::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
.main_side::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main_side a {
  color: #ffffff;
}


.r_side_btn {
  margin: 0;
}


.ant-form-item {
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      input {
        &:hover {
          border-color: #a5a0a0;
        }
      }

      .ant-select {
        &:hover {
          .ant-select-selector {
            border-color: #a5a0a0;
          }
        }
      }

      .ant-input-affix-wrapper {
        &:hover {
          border-color: #a5a0a0;
        }
      }

      .ant-picker {
        &:hover {
          border-color: #a5a0a0;
        }
      }
    }
  }
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.cstmrightmdl {

  &.show {
    .popup {
      transform: translateX(0px);
      transition: 0.4s all ease-in-out;
    }

    &:after {
      content: '';
      background: #00000026;
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      z-index: 100;
    }
  }

  .popup {
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 550px;
    z-index: 101;
    background: #fff;
    transform: translateX(100%);
    transition: 0.4s all ease-in-out;

    .modal_head {
      background: black;
      padding: 5px 15px 6px 15px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: white;
        font-size: 16px;
        font-weight: 500;
      }

      .close-btn {
        border: none;
        background: none;
        cursor: pointer;
        font-size: 29px;
        color: white;
      }
    }

    .modal_body {
      padding: 15px;

      .frmscrll {
        max-height: calc(100vh - 135px);
        overflow: auto;
        height: auto;

        .form-button {
          border-top: 1px solid #e3e3e3;
          padding-top: 15px;
          margin-top: 15px;

          .btn {}
        }

        .des_editr {
          margin-bottom: 30px;

          &+.des_editr {
            margin-bottom: 0px;
          }

          h2 {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 500;
          }
        }
      }
    }
  }
}


.adtct {
  margin-right: 10px;
}



.loader_bx {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  span {
    background: #fff;
    padding: 10px;

    .ant-spin {
      span.anticon {
        padding: 0px;
      }
    }
  }

}


.cstmrightmdl {
  &.child_modal {
    &.show {
      .popup {
        z-index: 105;
      }

      &:after {
        z-index: 104;
      }
    }
  }
}

// item sales design
.invoice_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .ant-form-item {
    margin-bottom: 10px;
    width: 13%;

    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          &.ant-input-outlined[disabled] {
            color: #000;
          }
        }
      }
    }
  }

  .filter_btn {
    width: auto;
  }
}

@media(max-width:1600px) {
  .invoice_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .ant-form-item {
      margin-bottom: 0px;
      width: 15%
    }

    .filter_btn {
      width: auto;
    }
  }
}

@media(max-width:1366px) {
  .invoice_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .ant-form-item {
      margin-bottom: 0px;
      width: 19%
    }

    .filter_btn {
      width: auto;
      
    }
    .onsmsc{
      margin-top: 7px;
    }
  }


}





.event_bx {
  width: 100%;

  .evt_nme {
    display: flex;
    width: 100%;

    .ant-form-item {
      margin-bottom: 10px;
      width: 28%;
      margin-right: 10px;
    }
  }

  a {
    font-size: 12px;
    color: #0952b9;
    margin-top: 6px;
    display: block;
    margin-left: 83px;

    span {
      margin-left: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-form-item {}
}



// .cstm_filterbx{padding:10px;}


.partic_wrapp {
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;


  .ant-form-item {}
}


.parti_dtls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
}

.page_brdcrumb {
  ol {
    li {
      font-size: 16px;

      span {
        a {
          color: rgba(0, 0, 0, 0.88);
          font-weight: 500;
        }
      }
    }
  }
}




.prfle_crd {
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #E6E9ED;

  .ant-card-head{background: #ecedef;border-radius: 4px 4px 0px 0px;min-height: auto;padding: 10px;
    .ant-card-head-wrapper{
      .ant-card-extra{
        button{
          &:first-child{margin-right: 10px;}
        }
      }
    }
  }

  .profile_data {
    display: flex;
    justify-content: left;
        .prf_img_bx {
        width: 140px;
        height: 140px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          position: absolute;
          left: 0px;
          right: 0px;
          top: 0px;
          bottom: 0px;
          object-fit: cover;
        }
      }

      button.ant-btn {
        margin-top: 10px;
        width: 100%;
      }

      
    

    .user_d {
      width: 100%;
      display: Grid;
      grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );

      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;

        span {
          font-weight: 400;
          margin-left: 7px;
        }
      }
    }
  }
  .ant-card-body{padding: 15px;}
}

.role_card {
  .role_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

    label {
      margin-bottom: 10px
    }
  }

  .role_btn {
    display: flex;
    justify-content: end;
  }
}

@media(max-width:1366px) {
  .role_card {
    .role_list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

    }
  }
}




.ant-picker-cell-inner {
  background: #fff !important;
  color: #000 !important;
}




.arow_tab {
  display: flex;
  align-items: center;

  h1 {
    margin-left: 10px;
  }
}



.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}


.cstm_table {
  table {
    tbody {
      tr {
        cursor: pointer;
        
      }
    }
  }
}


.layout_table {
  table {
    table-layout: auto !important;
  }
}





.trms_cndn {
  margin-bottom: 20px;
}


.cstm_r {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.cstm_r input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


.cstm_checkmark {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 15px;
  width: 15px;
  /* background-color: #eee; */
  border-radius: 50%;
  border: 1px solid #000;

}





.cstm_r input:checked~.cstm_checkmark {
  background-color: #000;
}


.cstm_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.cstm_r input:checked~.cstm_checkmark:after {
  display: block;
}


.cstm_r .cstm_checkmark:after {
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;

}


.cstm_check_bx {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.cstm_check_bx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.check_checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid #000;
  border-radius: 2px
}




.cstm_check_bx input:checked~.check_checkmark {
  background-color: #000;
}


.check_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.cstm_check_bx input:checked~.check_checkmark:after {
  display: block;
}


.cstm_check_bx .check_checkmark:after {
  left: 4px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.two_btn {
  display: flex;
}


.sctn_login {
  background: #ffffff;
  height: 100vh;
  position: relative;


  .lgn_logo {
    text-align: center;
    margin-bottom: 35px;

    img {
      width: 100%;
      margin: auto;
      max-width: 400px;
    }
  }

  .sl_bx {
    max-width: 450px;
    margin: auto;
    width: 100%;
    box-shadow: 0px 3px 6px #e6e6e6;

    .slb_rw {
      .slbr_clm {
        .ant-form {
          .ant-form-item {
            .ant-row {
              .ant-col {
                label {
                  font-size: 14px;
                  font-weight: 500;
                  color: #575757;
                }

                .ant-form-item-control-input {
                  .ant-form-item-control-input-content{
                    span{border-radius: 4px;}
                  }
                }
              }
            }
          }

          .ant-btn.btn {
            background: #000;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            padding: 7px 0px;
            height: auto;
            border-radius: 4px;
          }
        }
      }
    }
  }
}


.ant-btn-primary {
  background: #333;
  box-shadow: none;
    border-radius: 4px;

  &:hover {
    background: #000 !important;
  }
}

.newloader {
  .nlbx {
    display: flex;
    align-items: flex-end;

    .call {
      img {
        width: 125px;
      }

      svg {
        width: 30px;
        position: relative;
        top: 11px;
      }

      &+.call {
        margin-left: 7px;
      }
    }
  }
}


.cstltblwthldr {
  .ant-spin-nested-loading {


    .ant-spin {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      position: absolute;
      z-index: 1;

      .ant-spin-dot {
        top: unset;

        .ant-space {
          width: 100%;
          height: 100%;

          .ant-space-item {
            .cstmtblldr {
              .nlbx {
                display: flex;
                align-items: flex-end;

                .call {
                  img {
                    width: 125px;
                  }

                  svg {
                    width: 30px;
                    position: relative;
                    top: 11px;
                  }

                  &+.call {
                    margin-left: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



td.ant-table-cell.invoice_bld:hover {
  font-weight: 500;
}


.truncate-label {
  display: inline-block;
  max-width: 150px;
  /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}



.scrl_tble {
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        overflow: auto;
        min-height: 300px;
      }
    }
  }
}

.early-bird-row {
  background-color: rgb(171 171 171 / 88%);
}

.role_card {
  .role_list_agent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

    
  }
}






.error {
  color: red !important;
}

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 2s steps(4) infinite;
}

// new css

.role_card {
  border: 1px solid #E6E9ED;
  border-radius: 4px;
  position: relative;
  padding:10px;

  .ant-card-head {
    background: #ecedef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: auto;
    min-height: auto;
    padding: 10px;
  }

  .ant-card-body {
    .mnge_btn {
      position: absolute;
      top: 7px;
      right: 10px;
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 500;

      .anticon-plus {
        color: #000;
        vertical-align: middle;
      }

      span {
        margin-inline-start: 4px;
      }

      &:hover {
        background: none;
        color: #000;
        text-decoration: underline;
      }

    }

    
  }

  .role_head {
    display: flex;
    justify-content: space-between;

    .ant-form-item{
      .ant-form-item-label{
        label{font-weight: 500;}
      }
    }

    .role_btn{
      button{
        & + button{margin-left: 10px;}
      }
    }




  }

  .role_table{
    display: table;
    width: 100%;
    margin-top: 0px;
    table-layout: fixed;

    thead{
      background: #222;
      display: table;
      width: 100%;
      th{
        text-align: left;
        width: 100px;
        padding: 5px 10px;
        font-weight: 500;
        color: #fff;

        &:first-child{  width: 142px;}

        .ant-checkbox-wrapper{margin-right: 10px;
         .ant-checkbox-checked{
          .ant-checkbox-inner{background: #fff;
          &::after{border: 2px solid #222;
            width: 5.7142857142857135px;
            height: 9.142857142857142px;
            // border: 2px solid #fff;
            border-top: 0;
            border-inline-start: 0;
          }
          }
         }
        }
      }
    }
    tbody{
      width: 100%;
      height: calc(100vh - 467px);
      overflow: auto;
      display: block;

      td{
        width: 100px;
        padding: 5px 10px;
      &:first-child{
        width: 142px;
    text-align: left;
      }

      }

    }

    tr {
      display: table;
      width: 100%;

      &.parent_tr{background: #22222212;}
  }
    label{
      display: flex;
      align-items: center
    }
    input{width:15px; height: 15px;}
    button{
      border: none;
      background: none;
      padding: 0;
      margin-right: 5px;
      font-weight: 600;
      font-size: 19px;
      vertical-align: middle;
    }
  }
}






::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

  span.pge_nme {
    font-weight: 500;
}
  @media(max-width:1366px){
    .role_card .role_table tbody {
      width: 100%;
      height: calc(100vh - 463px);
      overflow: auto;
      display: block;
  }
  }


  .app-header-user-wrapper{
    .ant-space-item{
      button{
        .app-header-space{
          .ant-space-item{
            span{color: #fff;}
          }
        }
      }
      span{color: #fff;
      svg{fill:#fff;}
      }

      .ant-dropdown{
        ul{
          li{
            span{color: #000;
            svg{fill: #000;}
            }
          }
        }
      }
    }
  
  
  }

  .role_wrap{
    .role_hd{display: flex; justify-content: space-between; align-items: center;
    h3{font-size: 20px; font-weight: 500;}
    button{
      & + button{margin-left: 10px}
    }
    }

    .role_row{
      display: grid;
      flex-wrap: wrap;
      margin-top: 20px;
      grid-template-columns: 25% 75%;

      .role_add{
    // width: 22%;
    background: #fff;
    margin-right: 20px;
    padding: 16px;
    box-shadow: 0px 0px 9px -1px #ebe7e7;
    border-radius: 4px;
    height: calc(100vh - 275px);
    overflow: auto;

    .add_btn{
      border: none;
    padding: 0;
    display: flex;
    justify-content: end;
    text-align: right;
    width: 100%;
    height: auto;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: none;
    color: #000;
    font-weight: 500;
  
    & :hover{
      text-decoration: underline;
    }
    span{
      + span{margin-left: 5px ;}
    }
  }

  .role_name{
    margin-top: 10px;

    .role_bx{
      display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-bottom: 1px solid #f1f1f1;
    padding: 6px;

    &:last-child{
      margin-bottom: 0;
      border-bottom: 0;
     
    }
    button{
      border: none;
    padding: 0;
    box-shadow: none;
    }
    }
  }
      }
      .role_card{
        // width:76.7%;
      background: #fff;
      .role_table{ 
        thead{
          border-top-left-radius: 4px;
    border-top-right-radius: 4px;

        }
        tbody{height: calc(100vh - 313px);
        td{    padding: 8px 10px;}
        }
      }
      }
    }
  }



  .breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

  
    .breadcrumb-item {
      &:not(:last-child)::after {
        content: "/";
        padding: 0 0.5rem;
        color: #6c757d;
      }
  
      a {
        text-decoration: none;
        color: #000000;
        font-weight: 500;
        
        &:hover {
          text-decoration: underline;
          color: #000000;
        }
      }
  
      &.active {
        color: #6c757d;
      }
    }
  }


  .order_dtl{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;

    .dtl_bx{border: 1px solid #e9e9e9;padding: 10px; border-radius: 4px;
    & + .dtl_bx{margin-left: 10px;}
    p{font-weight: 500; color: #000; margin-bottom: 6px;
    span{font-weight: 400; margin-left: 5px}
    }
    }
  }

  .ordership_dtl{
    width: 100%;
    padding-left: 20px;

    .ordr_shp_head{
      display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      font-size: 18px;
      font-weight: 500;
  }
  span{font-weight: 400;
  span{font-weight: 500;}
  }
    }
    .shp_d{display: flex; justify-content: space-between; 
    span{font-weight: 500;
      span{font-weight: 400;}
    }
    }

    .ordr_sts{
      width: auto !important;
      margin-bottom: 20px;
      background: #c30404;
      border: 1px solid #c30404;
      color: #fff;
      border-radius: 4px;
    
    }

    .order_steps{
      .ant-steps{
        .ant-steps-item{
          .ant-steps-item-container{
            .ant-steps-item-tail{margin-inline: 0px 0;
            &::after{width: 100%; margin-inline-start:0; background-color: #009688;}
            }
            .ant-steps-item-icon{margin-inline-start: 0px; width: 17px;
              height: 17px;
            .ant-steps-icon{color: #009688;top: -4px;
            .ant-steps-icon-dot{background: #009688;}
            }
            }
            .ant-steps-item-content{text-align: left}
          }
        }
        .ant-steps-item-process{
          .ant-steps-item-container{
            .ant-steps-item-tail{}
            .ant-steps-item-icon{
              .ant-steps-icon{
                .ant-steps-icon-dot{background: #fff; border: 3px solid #009688;
                }
              }
            }
          }
        }
      }
    }
  }

  .order_dtl_table{
    width: 100%;
    display: table;
    text-align: left;
    thead{background: #222;
      th{padding: 10px 8px; color: #fff; font-weight: 500;}
    }
    td{padding: 10px 8px;}
  }
  
  .layout-content{position: relative;}

  .head_btn {
    position: absolute;
    top: 10px;
    right: 20px;

    button{
      & + button{margin-left: 10px;}
    }
}



.drop_down_cstm {
  border: 0px;


}

.info_filter{
  .ant-form-item{
    .ant-form-item-control-input{
      .ant-form-item-control-input-content{
        .ant-select-disabled{
          .ant-select-selection-item{color: #5c5757}
        }
        .ant-input-disabled{color: #5c5757;
        &::placeholder{color: #5c5757;}
        }

     
      }
    }
  }
}

.custmer_pr{
  .prf_img_bx{
    width: 150px !important;
    height: 150px !important;
    margin-right: 20px
  }
}
.customer_dtls {
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)) !important;
}


.highlight_role{
    background: #e7e7e7;
    border-radius: 4px;
}

.grey_clg {
    background: #dbdbdb;
    padding: 8px;
    text-align: center;
    display: inline;
    border-radius: 4px;
}

.green_clg{
    background: green;
    color: #fff;
     padding: 8px;
    text-align: center;
    display: inline;
    border-radius: 4px;
}
.red_clg{
  background: red;
    color: #fff;
     padding: 8px;
    text-align: center;
    display: inline;
    border-radius: 4px;
}

.breadcrumb {
ol{
li{
color: #000 !important;
font-weight:500;
&:last-child{
 color: #878585 !important;
 font-weight:400;
}
}
}
}


.N_SocialMedia_box{
.social_head{background: #ECF0F1;padding: 11px 30px;display: flex;align-items: center;    margin-bottom: 20px;border-radius: 12px;
.head{    margin-right: 20px;
img{width: 40px;}
}

.social_name{    font-weight: 700;
    margin-right: 20px; width:120px;}
}

}




.N_SocialMedia_box {}

.edit_link {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}

.edit_link a {
    color: #000;
    font-size: 16px;
}

.edit_link a span.anticon {
    margin-right: 7px;
}

.notify_modal .popup {
    max-width: calc(100vw - 201px) !important;
}

.form-button{
button{
& + button{
margin-left:10px;
}
}
}


.tab_actn{display:flex; align-items:center;}

.side-bar-menu{
.ant-menu-submenu{
.ant-menu-submenu-title{
    padding-left: 10px !important;

    .ant-menu-submenu-arrow{
    inset-inline-end: 9px !important; 
    }
}
}
}

.action_col{text-align:right !important; }

.order_track {
    margin-bottom: 40px;
}


  .edit_img_show{
    display: flex;
    margin-top: 10px;
     margin-bottom: 10px;
}

 .edit_img_show_filename{
    display: flex;
     margin-bottom: 10px;
   h1{
    margin-left: 10px;
   }
}


.prmo_form{
  .ant-row{
    .ant-col{
       .ant-form-item{padding-left:10px;
       .ant-form-item-control-input-content{
       .ant-picker{width:100%;}
       }
       }
    }
  }
}




.ttl_amt{
display:flex;
justify-content:space-between;

h4{
    font-weight: 500;
    font-size:15px;
}
.right_s{
  h2{text-align: right;}
  h4{text-align: right;}
}
}


.status_col{text-align:center;}


.item_s {
    display: flex;
    align-items: center;

    span.anticon{
    margin-right: 20px;

    svg{
    width: 20px !important;
    height: 20px;
    }
    }
}

.note_dtls {
    margin-top: 10px;
}

.note_dtls p {
    font-size: 13px;
}

.uplod_btn {
    margin-top: 19px;
}
    .chose_file {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
}

.add_img_mdl .ant-btn-primary:disabled:hover{
background:rgba(0, 0, 0, 0.04) !important;
color:rgba(0, 0, 0, 0.25);
}

.cstm_regis {
    display: flex;
    flex-wrap: wrap;


     .cstm_bx{
      width: 33.3%;
    margin-bottom: 20px;

    h2{
    font-weight: 500;
    }
     }
}


.dash_card{
  
  .ant-card-body{padding: 15px;}
}

.threecol_rw {
  display: flex;
  flex-wrap: wrap;
}

.threecol_rw .threecol {margin-right: 10px;width: 32.5%;}

.threecol_rw .threecol:last-child {
  margin-right: 0;
}

@media(max-width:1366px){
  .threecol_rw .threecol {margin-right: 10px;width: 48%; margin-bottom: 20px}
}
.threecol_rw .threecol:last-child {
  margin-right: 0;
  margin-bottom: 0px
}

.ant-menu .ant-menu-item a.active::before {
  background: #f1f1f163;
}

.cstm_filterbx{
  padding: 10px 10px 0;
}

.order_rtn .sc-ezOQGI {
  margin-top: 10px;
}

.order_rtn .sc-ezOQGI .sc-bYMpWt {
  margin-bottom: 10px;
}

@media(max-width:1366px){
  .breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
}







.footer {
  text-align: center;
   padding: 10px; 
   background-color: #ffffff;
    font-weight: 500;
     color: #5C5C61;
}

.input-number-hide 
.ant-input-number-handler-wrap {
    display: none !important;
}

.inActive_Role {
   cursor:not-allowed;
   .inActive_Role_text{
   opacity:0.6;
   }

}


`;