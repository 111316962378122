import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { PaymentMethodTable } from '../components/payment-method-table/payment-method-table';
import { useGetPaymentMethodLists } from '../use-cases/get-payment-method';
import { usePermission } from 'utils/user-permission-page';


export const PaymentMethodView = () => {

    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });

    const { data, isLoading } = useGetPaymentMethodLists((params), {
        enabled: permission.View
    });

    if (!permission.View) return null;


    return (
        <>


            <PaymentMethodTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};
