import { apiClient, ApiClientConfig } from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { getUpdatedQueryParams } from 'utils/updateQueryParamDownload';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};



export const CustomersApiService = () => {


  const getCustomersLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerlist`,
      params,
    });

    return response.data;
  };


  const getCustomerInfo = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerlist`,
      params,
    });

    return response.data;
  };


  const getCustomersOrder = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerorderlist`,
      params,
    });

    return response.data;
  };

  const getCustomersAddress = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomeraddresslist`,
      params,
    });

    return response.data;
  };

  const getCustomerExcelDownload = async (tableParams: { [key: string]: any }) => {
    const queryParams = getUpdatedQueryParams(tableParams);


    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/cutomer/exporttoexcelcustomerslist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };

  const getCustomerPdfDownload = async (tableParams: { [key: string]: any }) => {

    const queryParams = getUpdatedQueryParams(tableParams);

    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/cutomer/pdfcustomerslist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };


  return {
    getCustomersLists,
    getCustomerPdfDownload,
    getCustomerExcelDownload,
    getCustomerInfo,
    getCustomersAddress,
    getCustomersOrder
  };
};
