import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { BannerListFilterForm } from '../filter-form/banner-list-filter/banner-list-filter-form';
import { BannerFilterFormValues } from '../filter-form/banner-list-filter/types';
import { AddBannerModal } from '../models/add-banner-model/add-banner-model';
import { usePermission } from 'utils/user-permission-page';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: BannerFilterFormValues;
    handleFilter: (values: BannerFilterFormValues) => void;

};

export const BannerListTopBar = ({
    getInitialFilterValues,
    handleFilter,

}: UsersTopBarProps) => {
    const formId = 'banner-list-filters-form';
    const permission = usePermission();

    const screens = useBreakpoint();


    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }



    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddBannerModal onClose={handleCloseModal} editData={null} />
            </div>

            {permission.Add && <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Banner
                </Button>
            </div>}


            <ContentFilters formId={formId}>
                <BannerListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
