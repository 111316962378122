import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid, message } from 'antd';
import { Button, ContentFilters } from 'components';
import { MarketingApiService } from 'features/marketing/api/marketing-api.service';
import { useState } from 'react';
import { PromotionListFilterForm } from '../filter-form/promotion-list-filter/promotion-list-filter-form';
import { PromotionFilterFormValues } from '../filter-form/promotion-list-filter/types';
import { AddPromotionModal } from '../models/add-promotion-model/add-promotion-model';
import { usePermission } from 'utils/user-permission-page';


const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: PromotionFilterFormValues;
    handleFilter: (values: PromotionFilterFormValues) => void;
    Params: any
};

export const PromotionListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    Params
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'promotion-list-filters-form';

    const [excelLoading, setExcelLoading] = useState(false);

    const screens = useBreakpoint();
    const permission = usePermission()

    const handleExport = async () => {
        setExcelLoading(true);
        try {
            const response = await MarketingApiService().getPromotionExcelDownload(Params);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'PromotionList.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExcelLoading(false);
        } catch (error: unknown) {
            const typedError = error as { message: string };
            message.error(typedError.message || 'An unexpected error occurred');
        }
    };



    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className='head_btn'>
                {permission.Export &&
                    <Button
                        icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                        type='primary'
                        onClick={handleExport}
                        loading={excelLoading}
                    >
                        Export to Excel
                    </Button>}

                {permission.Add &&
                    <Button
                        icon={!screens.xs ? <PlusOutlined /> : undefined}
                        type='primary'
                        onClick={handleOpenModel}
                    >
                        Add Promotion
                    </Button>}
            </div>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddPromotionModal onClose={handleCloseModal} editData={null} />
            </div>


            <ContentFilters formId={formId}>
                <PromotionListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
