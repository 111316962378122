import { ContentFilters } from 'components';


import { AllItemsReviewsFilterForm } from '../filter-form/all-items-reviews-list-filter/all-items-reviews-list-filter-form';
import { AllItemsReviewsFilterFormValues } from '../filter-form/all-items-reviews-list-filter/types';

type UsersTopBarProps = {
    getInitialFilterValues: AllItemsReviewsFilterFormValues;
    handleFilter: (values: AllItemsReviewsFilterFormValues) => void;
};

export const AllItemsReviewListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    const formId = 'item-review-list-filters-form';

    return (
        <>
            <ContentFilters formId={formId}>
                <AllItemsReviewsFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
