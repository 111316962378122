import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout as AntLayout } from 'antd';
import IconSiderTrigger from 'assets/icons/logout.svg';
import { Button } from 'components';
import { usePermissions, useUserContext } from 'features/users';
import { useWindowSize } from 'hooks';
import { useSideBar } from 'providers/side-bar-provider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import Setting from '../../assets/Images/cms-management.png';
import Customer from '../../assets/Images/customers.png';
import Home from '../../assets/Images/dashboard.png';
import Brands from '../../assets/Images/design-icon.png';
import Marketing from '../../assets/Images/design-icon.png';
import Promotion from '../../assets/Images/design-icon.png';
import Utilities from '../../assets/Images/design-icon.png';
import Items from '../../assets/Images/items.png';
import Order from '../../assets/Images/order-management.png';
import Admin from '../../assets/Images/user-management.png';
// import { getMenuItems } from './menu-items';
import { SideBarWrapper, StyledMenu } from './side-bar.styles';
import { getActiveKeys } from './utils';


export const SideBar = () => {
  const { collapsed, toggleCollapsed } = useSideBar();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const location = useLocation();
  const permissions = usePermissions();
  // const permissionsNew = useNewPermissions();
  const { Sider } = AntLayout;



  useEffect(() => {

    const closeMobileMenu = () => {
      if (isMobile && !collapsed) {
        toggleCollapsed();
      }
    };

    closeMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);



  const activeKeys = useMemo(() => {
    return getActiveKeys(location.pathname);
  }, [location.pathname]);


  // const [openKeys, setOpenKeys] = useState<string[]>([]);

  // const onOpenChange = (keys: string[]) => {
  //   const latestOpenKey = keys.find((key) => !openKeys.includes(key));
  //   if (!items.some((item) => item?.key === latestOpenKey)) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    const savedOpenKeys = localStorage.getItem('openKeys');

    return savedOpenKeys ? JSON.parse(savedOpenKeys) : activeKeys;
  });

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys.slice(-1)); // Keep only the latest opened submenu
    localStorage.setItem('openKeys', JSON.stringify(keys.slice(-1)));
  };

  const { user } = useUserContext();


  if (!permissions) return null;

  // const loadIconAsync = async (iconName: any) => {
  //   try {
  //     const icon = await import(`../../assets/${iconName}`);
  //     return icon.default;
  //   } catch (err) {
  //     const defaultIcon = await import('../../assets/newlogo.png');
  //     return defaultIcon.default;
  //   }
  // };

  const dataImg = [
    { name: 'Home', src: Home },
    { name: 'Order Management', src: Order },
    { name: 'Brands', src: Brands },
    { name: 'Items', src: Items },
    { name: 'Customer', src: Customer },
    { name: 'Marketing', src: Marketing },
    { name: 'Admin', src: Admin },
    { name: 'Promotion', src: Promotion },
    { name: 'Setting', src: Setting },
    { name: 'Utilities', src: Utilities },
    // Add other icons with their names and image paths
  ];

  // const dataImg = [Home, long]

  const IconImg = (iconName: string) => {
    const foundIcon = dataImg.find((item) => item.name === iconName);

    return foundIcon ? foundIcon.src : Home; // Return the image source or null if not found
  };

  // const loadIconAsync = (iconName: string) => {
  //   try {
  //     return require(`../../assets/${iconName}`).default;
  //   } catch (err) {
  //     return require('../../assets/newlogo.png').default;  // Fallback to a default icon
  //   }
  // };

  const items = user?.getParentMenus.map((item: any, i: number) => {
    return {
      key: i, // Unique key for each item, using index 'i'
      icon: <img
        src={IconImg(item.Menu_LinkName) as any}  // This will be an async function call
        alt={item.Menu_LinkName || 'menu-icon'}
      // src={require(`../../assets/${item.Menu_LinkIcon}`).default || ""}
      />,

      label: (
        <NavLink to={item?.Menu_Childrens[0]?.Menu_LinkPath}> {/* Link path from item or default to dashboard */}
          {item?.Menu_LinkName}
        </NavLink>
      ),
      children:
        item?.Menu_Childrens
          ?.filter((child: any) =>
            child?.permissionPrivilege?.some(
              (perm: any) => perm.Privilege === "View" && perm.IsAccessible
            )
          )
          ?.map((child: any) => ({
            key: child.Menu_LinkPath || ROUTES.HOME, // Using child's path or fallback
            label: (
              <NavLink to={child.Menu_LinkPath || ROUTES.HOME}>
                {child.Menu_LinkName || ''}
              </NavLink>
            ),
          })) || [],
    };
  });


  return (
    <SideBarWrapper className='main_side'>

      <Sider
        id='sideBar'
        data-testid='side-bar'
        breakpoint='lg'
        theme='light'
        collapsible
        collapsed={collapsed}
        collapsedWidth={isMobile ? '0' : '48'}
        width={isMobile ? 260 : 200}
        onCollapse={toggleCollapsed}

        trigger={
          // null
          <Button
            type='text'
            aria-controls='sideBar'
            aria-expanded={!collapsed}
            className='side-bar-trigger-button'
          >
            <ArrowLeftOutlined
              className={collapsed ? 'side-bar-trigger-collapsed' : undefined}
              src={IconSiderTrigger}
              alt={collapsed ? t('sideBar.expand') : t('sideBar.collapse')}
              style={{ width: 23, color: "white", fontWeight: 900, fontSize: 25 }}
            />
          </Button>
        }
        className='side-bar-sider'
      >

        <StyledMenu
          selectedKeys={activeKeys}
          defaultOpenKeys={!collapsed ? activeKeys : undefined}
          mode='inline'
          items={items}
          className='side-bar-menu'
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </Sider>

    </SideBarWrapper>
  );
};
