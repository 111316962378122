import { Empty, Skeleton } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetItemStockListById } from 'features/items/use-cases/items-stocks-list-by-id';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';



const ItemStockTab = ({ id, tabs }: { id: number; tabs: string }) => {

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            Item_Fk_Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });
    const { data, isLoading, isFetching, refetch } = useGetItemStockListById((params));


    useEffect(() => {
        if (tabs === "3") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }


    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Stock'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.CurrentPage,
                        defaultPageSize: params.RecordsPerPage,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading || isFetching}
                    dataSource={isLoading ? [] : data?.itemStockLists}
                >

                    <Column<any>
                        title={' Size Type'}
                        dataIndex={'Size'}
                        sorter={(a, b) => a?.Size.localeCompare(b?.Size)}
                    />


                    <Column<any>
                        title={'Avialbe Qty'}
                        dataIndex={'Qty'}
                        sorter={(a, b) => Number(a.Qty) - Number(b.Qty)}
                    />

                    <Column<any>
                        title={'Transit Qty'}
                        dataIndex={'InTransit_Qty'}
                        sorter={(a, b) => Number(a.InTransit_Qty) - Number(b.InTransit_Qty)}
                    />

                    <Column<any>
                        title={'Sold Qty'}
                        dataIndex={'SoldQty'}
                        sorter={(a, b) => Number(a.SoldQty) - Number(b.SoldQty)}
                    />

                    <Column<any>
                        title={'Total Qty'}
                        dataIndex={'TotalQty'}
                        sorter={(a, b) => Number(a.TotalQty) - Number(b.TotalQty)}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemStockTab

