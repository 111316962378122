import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemsSubCategoryFilterFormField, ItemsSubCategoryFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ItemsSubCategoryFilterFormValues) => void;
    initialValues: ItemsSubCategoryFilterFormValues;
    closeModal?: () => void;
};

export const ItemsSubCategoryFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemsSubCategoryFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();


    const [genderId, setGenderId] = useState<number | null>(null);

    const catParams = {
        Flag: "GetGroupList",
        GenderId: genderId
    }

    const { data: GenderData } = useGetDropdownLists({ Flag: "GetGender" });

    const { data: category } = useGetDropdownLists(catParams, { enabled: Boolean(genderId) });

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: ItemsSubCategoryFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };

    const statusData = [
        { label: 'All', value: '' },
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' }
    ];


    const handleChangeGender = (value: number) => {
        setGenderId(value);
        form.setFieldValue(ItemsSubCategoryFilterFormField.GenderId, value);
        form.resetFields([ItemsSubCategoryFilterFormField.CategoryName]);
    }


    return (
        <Form<ItemsSubCategoryFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>

                        <Form.Item<ItemsSubCategoryFilterFormValues>
                            label={"Gender"}
                            name={ItemsSubCategoryFilterFormField.GenderId}
                        >
                            <Select
                                allowClear
                                showSearch
                                options={GenderData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Gender"}
                                onChange={handleChangeGender}
                            />
                        </Form.Item>

                        <Form.Item<ItemsSubCategoryFilterFormValues>
                            label={"Category"}
                            name={ItemsSubCategoryFilterFormField.CategoryName}
                        >
                            <Select
                                allowClear
                                showSearch
                                options={category?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Category Name"}
                            />
                        </Form.Item>


                        <Form.Item<ItemsSubCategoryFilterFormValues>
                            label={"Sub Category Name"}
                            name={ItemsSubCategoryFilterFormField.Title}
                        >

                            <Input
                                placeholder={"Sub Category Name"}
                            />
                        </Form.Item>

                        <Form.Item<ItemsSubCategoryFilterFormValues>
                            label={"Status"}
                            name={ItemsSubCategoryFilterFormField.Status}
                        >
                            <Select
                                allowClear
                                options={statusData}
                                placeholder={"Status"}
                            />
                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
