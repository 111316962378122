import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsCategoryFilterFormPaginated, ItemsCategoryFilterFormValues } from '../components/filter-form/items-category-filter-form/types';
import { ItemsCategoryTable } from '../components/items-category-table/items-category-table';
import { ItemsCategoryListTopBar } from '../components/top-bar/item-category-list';
import { useGetItemsCategoryLists } from '../use-cases/get-items-category'; import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: ItemsCategoryFilterFormValues = {
    GenderId: undefined,
    title: undefined,
    status: undefined,
};


export const ItemsCategoryListView = () => {
    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsCategoryFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch, isFetching } = useGetItemsCategoryLists((params), {
        enabled: permission.View
    });

    if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsCategoryFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsCategoryListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsCategoryTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

