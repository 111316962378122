import { useSessionContext } from 'features/auth';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { User } from 'types/user';

import { useGetUser } from './use-cases';

export type ContextValue =
  | { user: undefined; status: 'error' }
  | { user: undefined; status: 'loading' }
  | { user: User; status: 'success' }


export const UserContext = createContext<ContextValue | null>(null);

const resultMenu: any = {};

export const checkSdPermission = (
  user: any,
) => {

  user?.getParentMenus.forEach((menu: { Menu_Childrens: any[]; }) => {
    if (menu.Menu_Childrens) {
      menu.Menu_Childrens.forEach(child => {
        const linkPath = '/' + child.Menu_LinkPath;
        resultMenu[linkPath] = {};

        if (child.permissionPrivilege) {
          child.permissionPrivilege.forEach((permission: { Privilege: string | number; IsAccessible: any; }) => {
            resultMenu[linkPath][permission.Privilege] = permission.IsAccessible;
          });
        }
      });
    }
  });
}

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'You tried to use UserContext outside the scope of the UserContextProvider.',
    );
  }

  return context;
};

export function UserContextProvider({ children }: { children: ReactNode }) {
  const { data } = useSessionContext();

  const userQueryResult = useGetUser("1", data?.userId as string, {
    enabled: Boolean(data?.userId),
    retry: false,
    onError: (error) => {
      console.error(error);
    },
  });

  if (userQueryResult) checkSdPermission(userQueryResult?.data);

  const contextValue: any = useMemo(() => {
    const userWithResultMenu = userQueryResult.data
      ? { ...userQueryResult.data, resultMenu }
      : undefined;

    return {
      status: userQueryResult.status,
      user: userWithResultMenu,
    } as ContextValue;
  }, [userQueryResult.status, userQueryResult.data, resultMenu]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
