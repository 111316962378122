import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { PromotionTypeFilterForm } from '../filter-form/promotion-type-filter/promotion-type-filter-form';
import { PromotionTypeFilterFormValues } from '../filter-form/promotion-type-filter/types';
import { AddPromotionTypeModal } from '../models/add-promotion-type-model/add-promotion-type-model';
import { usePermission } from 'utils/user-permission-page';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: PromotionTypeFilterFormValues;
    handleFilter: (values: PromotionTypeFilterFormValues) => void;
};

export const PromotionTypeListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'promotion-type-list-filters-form';
    const permission = usePermission();

    const screens = useBreakpoint();


    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }


    return (
        <>

            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddPromotionTypeModal onClose={handleCloseModal} editData={null} />
            </div>

            {permission.Add && <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Promotion type
                </Button>
            </div>}


            <ContentFilters formId={formId}>
                <PromotionTypeFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
