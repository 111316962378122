import { ContentFilters } from 'components';
import { ItemsCategoryFilterForm } from '../filter-form/items-category-filter-form/items-category-filter-form';
import { ItemsCategoryFilterFormValues } from '../filter-form/items-category-filter-form/types';


type UsersTopBarProps = {
    getInitialFilterValues: ItemsCategoryFilterFormValues;
    handleFilter: (values: ItemsCategoryFilterFormValues) => void;
};

export const ItemsCategoryListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'id-items-category-filters-form';


    return (
        <>

            {/* <ContentTopBar title={'Items Category List'} /> */}
            <ContentFilters formId={formId}>
                <ItemsCategoryFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
