import { PaginatedRequestParams } from "api";

export enum ItemsFilterFormField {
    GenderId = 'GenderId',
    Brand = 'brandId',
    Category = 'groupId',
    SubCategory = 'categoryId',
    ItemName_En = 'itemName',
    ItemCode = 'itemCode',
    IsActive = 'status',
    Qty = 'isQty',
}

export type ItemsFilterFormValues = {
    [ItemsFilterFormField.GenderId]: number | undefined;
    [ItemsFilterFormField.Brand]: number | undefined;
    [ItemsFilterFormField.Category]: string | undefined;
    [ItemsFilterFormField.SubCategory]: string | undefined;
    [ItemsFilterFormField.ItemName_En]: string | undefined;
    [ItemsFilterFormField.ItemCode]: string | undefined;
    [ItemsFilterFormField.IsActive]: string | undefined;
    [ItemsFilterFormField.Qty]: string | undefined;
};

export type ItemsFilterFormPaginated = ItemsFilterFormValues &
    PaginatedRequestParams;