import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
// import { sideBarPermission } from 'components/side-bar/side-bar-permission';
import { CMSTypeView, CMSView, CustomSizeMasterView, DeliveryDaysView, PaymentMethodView } from 'features/admin';
import { LoginView } from 'features/auth';
import { BrandsView } from 'features/brands/routes/brands';
import { CustomerDetailsView, CustomersView } from 'features/customers';
import DashboardView from 'features/dashboard/routes/dashboard';
import WelcomeView from 'features/dashboard/routes/welcome';
import { DeliveryItemView } from 'features/delivery-charges/routes/delivery-item';
import { ItemsCategoryListView, ItemsDetailsView, ItemsListView, ItemsNotifyViews, ItemsPurchasedViews, ItemsReviewListView, ItemsSeasonsViews, ItemsSizeTypeViews, ItemsSizeViews, ItemsStocksListView } from 'features/items/routes';
import { ItemsSubCategoryListView } from 'features/items/routes/items-sub-category-view';
import { ItemsUploadListView } from 'features/items/routes/upload-items-views';
import { BannerListView, BenefitsListView, ContactListView, ContactTypeListView, FaqListView, NewsListView, NotificationsListView, PromotionListView, PromotionTypeListView, SocialMediaListView, SubscribersListView } from 'features/marketing/routes';
import { OfferTypeListView } from 'features/marketing/routes/offer-type-list-views';
import { OrderListDetailsView, OrderListView, OrderReturnDetailsView, OrderReturnView } from 'features/order-mannagemnet/routes';
import { SystemsView } from 'features/system-setting/routes/system-view';
import { UitilesView } from 'features/system-uitiles/routes/uitiles-view';
import {
  AdminDetailView
} from 'features/users';
import { RoleManagementView } from 'features/users/routes/role-management';
import { UserManagementView } from 'features/users/routes/user-management';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
// import { RestrictedSidebarRoute } from './restricted-route';
import { UnauthenticatedRoute } from './unauthenticated-route';
import { RestrictedRoute } from './restricted-route';
import { ItemsGenderListView } from 'features/items/routes/items-gender-view';



export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },



      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <WelcomeView />,
          },

          {
            path: ROUTES.USERS,
            element: <Navigate to={ROUTES.USER_MANAGEMENT} replace />,
          },


          {
            path: ROUTES.USERS_ADMIN_DETAIL,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USERS_ADMIN_DETAIL}
                action="Edit"
              >
                <AdminDetailView />
              </RestrictedRoute>
            )
          },

          {
            path: ROUTES.USER_MANAGEMENT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_MANAGEMENT}
                action="View"
              >
                <MainFiltersProvider>
                  <UserManagementView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },



          {
            path: ROUTES.ROLE_PERMISSION_MANAGEMENT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ROLE_PERMISSION_MANAGEMENT}
                action="View"
              >
                <MainFiltersProvider>
                  <RoleManagementView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_DELIVERY_DAYS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_DELIVERY_DAYS}
                action="View"
              >
                <MainFiltersProvider>
                  <DeliveryDaysView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_CMS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_CMS}
                action="View"
              >
                <MainFiltersProvider>
                  <CMSView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_CMS_TYPE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_CMS_TYPE}
                action="View"
              >
                <MainFiltersProvider>
                  <CMSTypeView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.USER_CUSTOM_SIZE_MASTER,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_CUSTOM_SIZE_MASTER}
                action="View"
              >
                <MainFiltersProvider>
                  <CustomSizeMasterView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_PAYMENT_METHOD,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.USER_PAYMENT_METHOD}
                action="View"
              >
                <MainFiltersProvider>
                  <PaymentMethodView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },



          {
            path: ROUTES.DASHBOARD,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.DASHBOARD}
                action="View"
              >
                <MainFiltersProvider>
                  <DashboardView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ORDER_LIST}
                action="View"
              >
                <OrderListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ORDER_DETAILS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.ORDER_LIST}
                requiredPermission={ROUTES.ORDER_DETAILS}
                action="Edit"
              >
                <OrderListDetailsView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_RETURN,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ORDER_RETURN}
                action="View"
              >
                <OrderReturnView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_RETURN_DETAILS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.ORDER_RETURN}
                requiredPermission={ROUTES.ORDER_RETURN_DETAILS}
                action="Edit"
              >
                <OrderReturnDetailsView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.BRANDS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.BRANDS}
                action="View"
              >
                <BrandsView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_LIST}
                action="View"
              >
                <ItemsListView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_LIST_DETAILS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_LIST_DETAILS}
                action="Edit"
              >
                <ItemsDetailsView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_REVIEWS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_REVIEWS}
                action="View"
              >
                <ItemsReviewListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_STOCK_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_STOCK_LIST}
                action="View"
              >
                <ItemsStocksListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_GENDER,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission={ROUTES.ITEMS_GENDER}
              //   action="View"
              // >
              <ItemsGenderListView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_CATEGORY,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_CATEGORY}
                action="View"
              >
                <ItemsCategoryListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_SUB_CATEGORY,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_SUB_CATEGORY}
                action="View"
              >
                <ItemsSubCategoryListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_SEASONS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_SEASONS}
                action="View"
              >
                <ItemsSeasonsViews />
              </RestrictedRoute>

            ),
          },

          {
            path: ROUTES.ITEMS_SIZE_TYPE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_SIZE_TYPE}
                action="View"
              >
                <ItemsSizeTypeViews />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_SIZE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_SIZE}
                action="View"
              >
                <ItemsSizeViews />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_PURCHASED,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_PURCHASED}
                action="View"
              >
                <ItemsPurchasedViews />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ITEMS_NOTIFY,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_NOTIFY}
                action="View"
              >
                <ItemsNotifyViews />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.ITEMS_UPLOAD_ITEMS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.ITEMS_UPLOAD_ITEMS}
                action="View"
              >
                <ItemsUploadListView />
              </RestrictedRoute>
            ),
          },

          // {
          //   path: ROUTES.ITEMS_REVIEWS,
          //   element: (
          //     // <RestrictedRoute
          //     //   redirectPath={ROUTES.HOME}
          //     //   requiredPermission='fields.view'
          //     // >
          //     <ItemsReviewsView />
          //     // </RestrictedRoute>
          //   ),
          // },

          {
            path: ROUTES.CUSTOMER_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.CUSTOMER_LIST}
                action="View"
              >
                <CustomersView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CUSTOMER_DETAILS_ID,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.CUSTOMER_DETAILS_ID}
                action="Edit"
              >
                <CustomerDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_CHARGE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.DELIVERY_CHARGE}
                action="View"
              >
                <DeliveryItemView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SYSTEM_SETTING,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.SYSTEM_SETTING}
                action="View"
              >
                <SystemsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SYSTEM_UITILES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.SYSTEM_UITILES}
                action="View"
              >
                <UitilesView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.PROMOTION_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission={ROUTES.PROMOTION_LIST}
              //   action="View"
              // >
              <PromotionListView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PROMOTION_TYPE_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission={ROUTES.PROMOTION_TYPE_LIST}
              //   action="View"
              // >
              <PromotionTypeListView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.BANNER_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.BANNER_LIST}
                action="View"
              >
                <BannerListView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.NOTIFICATIONS_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.NOTIFICATIONS_LIST}
                action="View"
              >
                <NotificationsListView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CONTACT_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.CONTACT_LIST}
                action="View"
              >
                <ContactListView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CONTACT_TYPE_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.CONTACT_TYPE_LIST}
                action="View"
              >
                <ContactTypeListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.SUBSCRIBER_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.SUBSCRIBER_LIST}
                action="View"
              >
                <SubscribersListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.NEWS_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.NEWS_LIST}
                action="View"
              >
                <NewsListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.FAQ_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.FAQ_LIST}
                action="View"
              >
                <FaqListView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.SOCIAL_MEDIA_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.SOCIAL_MEDIA_LIST}
                action="View"
              >
                <SocialMediaListView />
              </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.BENEFITS_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.BENEFITS_LIST}
                action="View"
              >
                <BenefitsListView />
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.OFFER_TYPE_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission={ROUTES.OFFER_TYPE_LIST}
                action="View"
              >
                <OfferTypeListView />
              </RestrictedRoute>

            ),
          },

        ],
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
