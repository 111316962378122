import { CheckOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Rate, Skeleton, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, ContentFilters } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetItemReviewsListById } from 'features/items/use-cases/get-reviews-list-by-id';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';

import { ItemsReviewsFilterForm } from './items-reviews-list-filter/items-reviews-list-filter-form';
import { ItemsReviewsFilterFormPaginated, ItemsReviewsFilterFormValues } from './items-reviews-list-filter/types';
import { useUpdateItemreviewstaus } from 'features/items/use-cases/update-reviews-status';
import { usePermission } from 'utils/user-permission-page';



const ItemReviewsTab = ({ id, tabs }: { id: string; tabs: string }) => {

    const permission = usePermission();

    const getInitialFilterValues: ItemsReviewsFilterFormValues = {
        CustomerName: undefined,
        Rating: undefined,
        ReviewStatus: undefined,
    };


    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsReviewsFilterFormPaginated>({
            Item_Fk_Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });
    const { data, isLoading, refetch, isFetching } = useGetItemReviewsListById((params));

    const handleFiltersChange = (values: any) => {
        updateParams(values);
        resetPage();
    };

    useEffect(() => {
        if (tabs === "6") {
            refetch();
        }
    }, [tabs]);

    const formId = "transaction-items-form"

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }

    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: `Are you sure you want to ${record?.ReviewStatus === "Approve" ? "Reject" : "Approve"} this Rating?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
        });
    };

    const { mutate: mutateUpdateStatus } =
        useUpdateItemreviewstaus();

    const handleDelete = async (record: any) => {

        if (record?.ReviewStatus === "Approve") {
            mutateUpdateStatus({
                Id: record?.Id,
                Flag: "Rejected",
                StatusId: 2
            });
        } else {
            mutateUpdateStatus({
                Id: record?.Id,
                Flag: "Approve",
                StatusId: 1
            });
        }


    };

    const menuItems = (record: any) => [
        permission.Delete ? {
            key: 'reject',
            label: <Button type="text" onClick={() => showDeleteConfirm(record)} icon={record?.ReviewStatus === "Approve" ? <CloseOutlined /> : <CheckOutlined />} style={{ color: record?.ReviewStatus === "Approve" ? "red" : "green" }}>{record?.ReviewStatus === "Approve" ? "Reject" : "Approve"}</Button>,
        } : null,


    ]

    return (
        <div>
            <ContentFilters formId={formId}>
                <ItemsReviewsFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Reviews'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.CurrentPage,
                        defaultPageSize: params.RecordsPerPage,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading || isFetching}
                    dataSource={isLoading ? [] : data?.getItemReviews}
                >

                    <Column<any>
                        title={'Customer Name'}
                        dataIndex={'CustomerName'}
                        sorter={(a, b) => {
                            if (!a.CustomerName) return 1;
                            if (!b.CustomerName) return -1;

                            return a.CustomerName.localeCompare(b.CustomerName);
                        }}
                    />


                    <Column<any>
                        title={'Rating'}
                        sorter={(a, b) => {
                            if (!a.Rating) return 1;
                            if (!b.Rating) return -1;

                            return a.Rating - b.Rating;
                        }}
                        render={(_, record: any) => (
                            <Tooltip>
                                <Rate disabled defaultValue={record?.Rating} />
                            </Tooltip>

                        )}
                    />

                    <Column<any>
                        title={'Status'}
                        // dataIndex={'ReviewStatus'}
                        render={(_, record: any) => (
                            <h2 className={`${record?.ReviewStatus === "Approve" ? "green_clg" : record?.ReviewStatus === "Rejected" ? "red_clg" : ""}`} >
                                {record?.ReviewStatus}
                            </h2>
                        )}
                        sorter={(a, b) => {
                            if (!a.ReviewStatus) return 1;
                            if (!b.ReviewStatus) return -1;

                            return a.ReviewStatus.localeCompare(b.ReviewStatus);
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Dropdown
                                menu={{ items: menuItems(record) }}
                                trigger={['click']}
                                className='drop_down_cstm'
                            >
                                <Button shape={"circle"} icon={< MoreOutlined />} />
                            </Dropdown>

                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemReviewsTab

