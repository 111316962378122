import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemsStocksFilterFormField, ItemsStocksFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ItemsStocksFilterFormValues) => void;
    initialValues: ItemsStocksFilterFormValues;
    closeModal?: () => void;
};

export const ItemsStocksFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemsStocksFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const [groupId, setGroupId] = useState("");
    const subCatParams = {
        Flag: "GetSubCategoryListByParentId",
        parentId: groupId,

    }

    const { data: subCategory, isLoading: subCatLoading, refetch: subCatRefetch } = useGetDropdownLists(subCatParams, { enabled: false });
    const { data: category, isLoading: catLoading } = useGetDropdownLists({ Flag: "GetGroupList" });
    const { data: brandData, isLoading: brandLoading } = useGetDropdownLists({ Flag: "GetBrandList" });

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    useEffect(() => {
        if (groupId) {
            subCatRefetch();
        }
    }, [groupId])

    const onFinish = (formValues: ItemsStocksFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };

    const handleCategoryChange = (value: any) => {
        form.setFieldValue(ItemsStocksFilterFormField.Category, value)
        form.resetFields([ItemsStocksFilterFormField.SubCategory])
        setGroupId(value)
    }

    return (
        <Form<ItemsStocksFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>

                        <Form.Item<ItemsStocksFilterFormValues>
                            label={"Brand"}
                            name={ItemsStocksFilterFormField.Brand}
                        >
                            <Select
                                allowClear
                                loading={brandLoading}
                                showSearch
                                options={brandData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Brand"}
                            />
                        </Form.Item>

                        <Form.Item<ItemsStocksFilterFormValues>
                            label={"Group Category"}
                            name={ItemsStocksFilterFormField.Category}
                        >
                            <Select
                                allowClear
                                loading={catLoading}
                                showSearch
                                options={category?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Category"}
                                onChange={handleCategoryChange}
                            />
                        </Form.Item>

                        <Form.Item<ItemsStocksFilterFormValues>
                            label={"Category"}
                            name={ItemsStocksFilterFormField.SubCategory}
                        >
                            <Select
                                allowClear
                                showSearch
                                options={subCategory?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Sub Category"}
                            />
                        </Form.Item>

                        <Form.Item<ItemsStocksFilterFormValues>
                            label={'Item Code'}
                            name={ItemsStocksFilterFormField.ItemCode}
                        >
                            <Input
                                placeholder={"Item Code"}
                            />
                        </Form.Item>


                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
