import './locales/i18n';

import { App as AntAppProvider } from 'antd';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import isToday from 'dayjs/plugin/isToday';
// import localeData from 'dayjs/plugin/localeData';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
// import weekday from 'dayjs/plugin/weekday';
import { SessionContextProvider } from 'features/auth';
import { UserContextProvider } from 'features/users';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { router } from 'routes/router';

import {
  AntDesignConfigProvider,
  ReactQueryProvider,
  SideBarProvider,
  ThemeProvider,
} from './providers';
// import reportWebVitals from './reportWebVitals';

// throws 'clone.weekday is not a function' without additional plugins
// dayjs.extend(weekday);
// dayjs.extend(isToday);
// dayjs.extend(localeData);
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(customParseFormat);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <ReactQueryProvider>
      <AntDesignConfigProvider>
        <ThemeProvider>
          <AntAppProvider>
            <SessionContextProvider>
              <UserContextProvider>
                <SideBarProvider>
                  <RouterProvider router={router} />
                </SideBarProvider>
              </UserContextProvider>
            </SessionContextProvider>
          </AntAppProvider>
        </ThemeProvider>
      </AntDesignConfigProvider>
    </ReactQueryProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
