import { Button, Form, Input, Select, Image, message } from 'antd';
import { useUpdateBanner } from 'features/marketing/use-cases/update-banner';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddIBannerTypeFormField, AddIBannerTypeFormValues } from './types';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';




export const AddBannerModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-banner-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddIBannerTypeFormValues>();

    const { t } = useTranslation();

    const { data: bannerData, isLoading: bannerLoading } = useGetDropdownLists({ Flag: "GetBannerType" });

    const { isLoading, mutate } = useUpdateBanner();
    const [imageAr, setImageAr] = useState<File | null>(null);
    const [imageEn, setImageEn] = useState<any>(null);
    const [fileEnName, setFileEnName] = useState<string>("");
    const [fileArName, setFileArName] = useState<string>("");

    const handleClose = () => {
        onClose();
        form.resetFields();
        setImageEn(null);
        setImageAr(null)
        setFileEnName("");
        setFileArName("")
    }

    useEffect(() => {
        if (editData?.Images_En) {
            const extractedFileName = editData?.Images_En.split('/').pop();
            setFileEnName(extractedFileName || 'ImageEn.jpg');
        }
        if (editData?.Images_Ar) {
            const extractedFileName = editData?.Images_Ar.split('/').pop();
            setFileArName(extractedFileName || 'ImagAr.jpg');
        }
    }, [editData]);

    const inputRef = useRef<any>();
    const inputArRef = useRef<any>();


    const getInitialValues = {
        [AddIBannerTypeFormField.Id]: undefined,
        [AddIBannerTypeFormField.Flag]: undefined,
        [AddIBannerTypeFormField.BannerType]: undefined,
        [AddIBannerTypeFormField.Title]: undefined,
        [AddIBannerTypeFormField.Title_Ar]: undefined,
        [AddIBannerTypeFormField.Image]: undefined,
        [AddIBannerTypeFormField.Image_Ar]: undefined,
        [AddIBannerTypeFormField.Link]: undefined,

    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue(
                editData
                //     {
                //     [AddIBannerTypeFormField.Name]: editData?.Season_En,
                //     [AddIBannerTypeFormField.Name_Ar]: editData?.Season_Ar,
                // }
            );
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formValues: AddIBannerTypeFormValues) => {
        const formData = new FormData();

        // Append other form values
        Object.keys(formValues).forEach((key) => {
            if (key !== 'File_En' && key !== 'File_Ar') {
                formData.append(key, formValues[key as keyof AddIBannerTypeFormValues] as string);
            }
        });

        formData.append('Flag', "insert");

        // Append the file (image)
        if (imageAr) {
            formData.append('File_Ar', imageAr);
        }
        if (imageEn) {
            formData.append('File_En', imageEn);
        }

        if (editData) {
            formData.append('Id', editData.Id);
        }

        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };

    // const bannerData = [{ Text: "Top Banner", Value: 2 }, { Text: "Middle Banner", Value: 3 }];
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageEn(selectedFile);
                setFileEnName(selectedFile.name);

            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageEn(null);
                setFileEnName("");
            }
        }
    };

    const handleFileArChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageAr(selectedFile);
                setFileArName(selectedFile.name);
            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageAr(null);
                setFileArName("");
            }
        }
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? "Edit Banner" : "Add Banner"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddIBannerTypeFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >

                            <Form.Item<AddIBannerTypeFormField>
                                label={"Banner Type"}
                                name={AddIBannerTypeFormField.BannerType}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={bannerLoading}
                                    options={bannerData?.map((item: { Text: string; Value: number }) => ({
                                        label: item.Text,
                                        value: item.Value,
                                    }))}

                                    filterOption={(input, option) =>
                                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                    }

                                    placeholder={"Select Banner Type"}
                                />


                            </Form.Item >

                            <Form.Item<AddIBannerTypeFormField>
                                label={"Title"}
                                name={AddIBannerTypeFormField.Title}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >

                                <Input
                                    placeholder={"Title"}
                                />
                            </Form.Item>

                            <Form.Item<AddIBannerTypeFormField>
                                label={"Title (Arabic)"}
                                name={AddIBannerTypeFormField.Title_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Title (Arabic)"}
                                />
                            </Form.Item>


                            <Form.Item<AddIBannerTypeFormField>
                                label={"Image"}
                                name={AddIBannerTypeFormField.Image}

                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputRef.current.click()}>Choose File</Button>}
                                    value={fileEnName}
                                    readOnly
                                />
                                <input
                                    type='file'
                                    // id='fileInput'
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileChange}
                                />
                                <span>
                                    Recommended size-
                                    Width : 3000 px,
                                    Height : 1050 px
                                </span>
                            </Form.Item>
                            <>
                                <div className='edit_img_show'>
                                    {imageEn ? (
                                        <Image
                                            src={URL.createObjectURL(imageEn)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.Images_En && (
                                            <Image
                                                src={editData.Images_En}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Brand Logo"
                                            />
                                        )
                                    )}
                                </div>
                            </>

                            <Form.Item<AddIBannerTypeFormField>
                                label={"Image (Arabic)"}
                                name={AddIBannerTypeFormField.Image_Ar}

                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputArRef.current.click()}>Choose File</Button>}
                                    value={fileArName}
                                    readOnly
                                />
                                <input
                                    ref={inputArRef}
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileArChange}
                                />
                                <span>
                                    Recommended size-
                                    Width : 1050 px,
                                    Height : 1050 px
                                </span>

                            </Form.Item>
                            <>
                                <div className='edit_img_show'>
                                    {imageAr ? (
                                        <Image
                                            src={URL.createObjectURL(imageAr)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.Images_Ar && (
                                            <Image
                                                src={editData.Images_Ar}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Brand Logo"
                                            />
                                        )
                                    )}
                                </div>
                            </>

                            <Form.Item<AddIBannerTypeFormField>
                                label={"Link Url"}
                                name={AddIBannerTypeFormField.Link}
                                rules={[
                                    {
                                        pattern: /^(https?:\/\/)?((localhost:\d{1,5})|([\w-]+(\.[\w-]+)+))([/\w.-]*)*\/?(\?.*)?(#.*)?$/i,
                                        message: 'Please enter a valid URL',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Link Url"}
                                />
                            </Form.Item>

                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button type="primary"
                                htmlType='submit'
                                className="btn"
                                loading={isLoading}
                                style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

