import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, message, Modal, Popover, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useDeliveryDelete } from 'features/delivery-charges/use-cases/delete-common';
import { useUpdateDeliveyStatus } from 'features/delivery-charges/use-cases/update-common-status';
import { Dispatch, SetStateAction, useState } from 'react';
import { DelliveryCharge } from 'types/admin';

import { DeliveryChargeFilterFormPaginated } from '../delivery-list-filter/types';
import { DeliveryModal } from '../modal/delivery-view';
import { usePermission } from 'utils/user-permission-page';

type DelievryChargeProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: DeliveryChargeFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
    dropdown: any;
};

export const DeliveryListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
    dropdown,
}: DelievryChargeProps) => {

    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });
    };
    const { mutate: mutateUpdateStatus } = useUpdateDeliveyStatus();
    const { mutate: mutateDelete } = useDeliveryDelete();

    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenModal(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,
        permission.Delete ? {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
                Delete
            </Button>,
        } : null,
    ]


    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this Item ?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };

    const handleDelete = async (id: any) => {

        mutateDelete({
            Id: id,
            Flag: "DeliveryChargeDelete",
        });
    };



    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleCloseModal = () => {
        setEditData(null)
        setShowModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setEditData(record)
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    }


    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${showModal && "show"}`}>
                <DeliveryModal onClose={handleCloseModal} editData={editData} dropdown={dropdown} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total '}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<DelliveryCharge>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList}
                >
                    <Column<DelliveryCharge>
                        title={'Governorate'}
                        dataIndex={'GovernorateName'}
                        // render={(text) => text}
                        sorter={(a, b) => a?.GovernorateName.localeCompare(b?.GovernorateName)}
                    />
                    <Column<DelliveryCharge>
                        title={'City'}
                        dataIndex={'CityName'}
                        // render={(text) => text}
                        sorter={(a, b) => a?.CityName.localeCompare(b?.CityName)}

                    />
                    <Column<DelliveryCharge>
                        title={'Charge'}
                        dataIndex={'Charge'}
                        // render={(text) => text}
                        sorter={(a, b) => a.Charge && a.Charge - b.Charge}
                    />
                    <Column<DelliveryCharge>
                        title={'No. of days	'}
                        dataIndex={'NoOfDays'}
                        // render={(text) => text}
                        sorter={(a, b) => a.NoOfDays && a.NoOfDays - b.NoOfDays}
                    />
                    <Column<DelliveryCharge>
                        title={'Status'}
                        // dataIndex={'IsActive'}
                        render={(_, record: any) => (record?.IsActive ? 'Active' : "Inactive")}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                    />
                    <Column<DelliveryCharge>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'IsActive'}
                        render={(_, record: any) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                                <Tooltip>
                                    <Space>
                                        <Switch
                                            defaultChecked={record.IsActive}
                                            checked={record.IsActive}
                                            onChange={(isActive: any) => {
                                                mutateUpdateStatus({
                                                    Id: record.Id,
                                                    Flag: "DeliveryChargeChangeStatus",
                                                    Status: isActive ? 1 : 0, // Use `isChecked` directly
                                                });
                                                record.IsActive = isActive; // Ensure it's an integer
                                            }}
                                        />
                                    </Space>
                                </Tooltip>
                            </Space>
                        )}



                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
