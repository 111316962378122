import { DropdownCommonAPI } from 'components/dropdown-common-api/api/common-dropdown';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { DeliveryChargeFilterFormPaginated, DeliveryChargeFilterFormValues } from '../components/delivery-list-filter/types';
import { DeliveryListTable } from '../components/list-table/delivery-list-table';
import { DeliveryListTopBar } from '../components/top-bar/delivery-topbar';
import { useGetDeliveryLists } from '../use-cases/get-delivery-list';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: DeliveryChargeFilterFormValues = {
    City_Fk_Id: undefined,
    Governorate_Fk_Id: undefined,
    Status: undefined,
    FromDate: undefined,
    ToDate: undefined,
    arabic: undefined,
};

export const DeliveryItemView = () => {
    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<DeliveryChargeFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, isFetching, refetch } = useGetDeliveryLists((params), {
        enabled: permission.View
    });

    const [dropdownValue, SetDropDownValue] = useState([])



    const handleFiltersChange = (values: DeliveryChargeFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch()
    };


    useEffect(() => {
        (async () => {
            try {
                const response = await DropdownCommonAPI().getCommonLists({
                    Flag: 'GetGovernorate' // Pass the flag in the correct format
                });
                SetDropDownValue(response?.dropdownLists)
            } catch (error) {
                console.error("Error fetching governorate data:", error);
            }
        })();
    }, []);

    if (!permission.View) return null;

    return (
        <>
            <DeliveryListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
                dropdown={dropdownValue}
            />

            <DeliveryListTable
                isLoading={isLoading || isFetching}
                itemList={data?.Items}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}
                dropdown={dropdownValue}
            />
            <Outlet />
        </>
    );
};

