import { Card, Col, Row } from 'antd'
import { Loader } from 'components';

const DashboardTop = ({ data, isLoading }: { data: any; isLoading: boolean }) => {



    if (isLoading) return <Loader />

    return (
        <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col span={6} className='main_col'>
                <Card title="Today" className='dash_card'>
                    <div className='ttl_amt'>
                        <div className='left_s'>
                            <h2>Total Orders</h2>
                            <h4>{data?.getDashboardData?.TodayOrderList[0]?.TotalOrders}</h4>
                        </div>
                        <div className='right_s'>
                            <h2>Amount</h2>
                            <h4>{data?.getDashboardData?.TodayOrderList[0]?.TotalAmount}</h4>
                        </div>
                    </div>

                </Card>
            </Col>


            <Col span={6} className='dash_card' >
                <Card title="Weekly">
                    <div className='ttl_amt'>
                        <div className='left_s'>
                            <h2>Total Orders</h2>
                            <h4><b>{data?.getDashboardData?.WeeklyOrderList[0]?.TotalOrders}</b></h4>
                        </div>
                        <div className='right_s'>
                            <h2>Amount</h2>
                            <h4>{data?.getDashboardData?.WeeklyOrderList[0]?.TotalAmount}</h4>
                        </div>
                    </div>

                </Card>
            </Col>



            <Col span={6} className='dash_card'>
                <Card title="Monthly">
                    <div className='ttl_amt'>
                        <div className='left_s'>
                            <h2>Total Orders</h2>
                            <h4>{data?.getDashboardData?.MonthlyOrderList[0]?.TotalOrders}</h4>
                        </div>
                        <div className='right_s'>
                            <h2>Amount</h2>
                            <h4>{data?.getDashboardData?.MonthlyOrderList[0]?.TotalAmount}</h4>
                        </div>
                    </div>

                </Card>
            </Col>



            <Col span={6} className='dash_card'>
                <Card title="Yearly">
                    <div className='ttl_amt'>
                        <div className='left_s'>
                            <h2>Total Orders</h2>
                            <h4>{data?.getDashboardData?.YearlyOrderList[0]?.TotalOrders}</h4>
                        </div>
                        <div className='right_s'>
                            <h2>Amount</h2>
                            <h4>{data?.getDashboardData?.YearlyOrderList[0]?.TotalAmount}</h4>
                        </div>
                    </div>

                </Card>
            </Col>

        </Row>
    )
}

export default DashboardTop
