export enum AddNotificationFormField {
  Id = "Id",
  Flag = "Flag",
  AppType = "AppType",
  DeviceType = "DeviceTypeId",
  NotificationType = "NotificationOfferTypeId",
  Title = 'Title_En',
  Title_Ar = 'Title_Ar',
  DescriptionEn = 'Description_En',
  DescriptionAr = 'Description_Ar',
  StatusId = "StatusId"
}

export type AddNotificationFormValues = {
  [AddNotificationFormField.Id]: number;
  [AddNotificationFormField.Flag]: string;
  [AddNotificationFormField.AppType]: number;
  [AddNotificationFormField.NotificationType]: number;
  [AddNotificationFormField.DeviceType]: number;
  [AddNotificationFormField.Title]: string;
  [AddNotificationFormField.Title_Ar]: string;
  [AddNotificationFormField.DescriptionEn]: string;
  [AddNotificationFormField.DescriptionAr]: string;
  [AddNotificationFormField.StatusId]: number;
};

