
import { Empty, Image, Skeleton, Switch, Tooltip } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateCommonStatus } from 'features/admin/use-cases/update-common-status';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';

import noImg from '../../../../assets/No_Image_Available.jpg';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: UserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const PaymentMethodTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { mutate: mutateUpdateStatus } = useUpdateCommonStatus();

    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {permission.Totals &&
                        <RecordCountBadge
                            text={'Total'}
                            count={itemsCount}
                        />}
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} />
                            : !isLoading && !itemList?.Items ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.items}

                >


                    <Column<any>
                        title={'Name'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => a.Title_En && a.Title_En.localeCompare(b.Title_En)}
                    />
                    <Column<any>
                        title={'Name (Arabic)'}
                        dataIndex={'Title_Ar'}
                        sorter={(a, b) => a.Title_Ar && a.Title_Ar.localeCompare(b.Title_Ar)}
                    />
                    <Column<any>
                        title={'Logo'}
                        dataIndex={'Logo'}
                        render={(_, record) => (
                            <Image src={record.Logo}
                                width={40}
                                height={40}
                                fallback={noImg}
                                alt="Logo"
                            />
                        )}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        render={(_, record) => (
                            <h1>{record?.IsActive === 1 ? "Active" : "InActive"}</h1>
                        )}
                    />


                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Tooltip>

                                <Switch
                                    defaultChecked={record.IsActive}
                                    checked={record.IsActive}
                                    onChange={(isActive: any) => {
                                        mutateUpdateStatus({
                                            Id: record.Id,
                                            Flag: "PaymentMethodChangeStatus",
                                            Status: isActive ? 1 : 0, // Use `isChecked` directly
                                        });
                                        record.IsActive = isActive; // Ensure it's an integer
                                    }}
                                />
                            </Tooltip>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
