import { ContentFilters } from 'components';
import { ItemsStocksFilterForm } from '../filter-form/items-stocks-filter-form/items-stocks-filter-form';
import { ItemsStocksFilterFormValues } from '../filter-form/items-stocks-filter-form/types';


type UsersTopBarProps = {
    getInitialFilterValues: ItemsStocksFilterFormValues;
    handleFilter: (values: ItemsStocksFilterFormValues) => void;
};

export const ItemsStocksListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    const formId = 'item-stocks-list-filters-form';

    return (
        <>


            <ContentFilters formId={formId}>
                <ItemsStocksFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
