import { PlusOutlined } from '@ant-design/icons';
import { Button, Grid } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';

import { DeliveryItemFilterForm } from '../delivery-list-filter/delivery-filter-form';
import { DeliveryChargeFilterFormValues } from '../delivery-list-filter/types';
import { DeliveryModal } from '../modal/delivery-view';
import { usePermission } from 'utils/user-permission-page';

const { useBreakpoint } = Grid;

export type dropdownLists = {
    value: number,
    Text: string
}

type DeliveryTopBarProps = {
    getInitialFilterValues: DeliveryChargeFilterFormValues;
    handleFilter: (values: DeliveryChargeFilterFormValues) => void;
    dropdown: dropdownLists[];
};

export const DeliveryListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    dropdown
}: DeliveryTopBarProps) => {
    const permission = usePermission();

    const screens = useBreakpoint();

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            {permission.Add &&
                <div className='head_btn'>
                    <Button
                        icon={!screens.xs ? <PlusOutlined /> : undefined}
                        type='primary'
                        onClick={handleOpenModal}
                    >
                        Add Delivery Charge
                    </Button>
                </div>}

            <div className={`cstmrightmdl ${showModal && "show"}`}>
                <DeliveryModal onClose={handleCloseModal} editData={""} dropdown={dropdown} />
            </div>


            <ContentFilters >
                <DeliveryItemFilterForm
                    formId="delivery-list-filters-form"
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                    dropdown={dropdown}
                />
            </ContentFilters>
        </>
    );
};
