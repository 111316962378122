import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Input, message, Skeleton, } from 'antd';
import { Button } from 'components';
import { createNewMenuPermissionDTO } from 'features/users/api/user-api.service';
import { useCreateNewMenuPermission } from 'features/users/use-cases/create-new-menu-permission';
import { useCreateRole } from 'features/users/use-cases/create-role';
import { useGetAllRoleManagement } from 'features/users/use-cases/get-allrole-mangemnet';
import { useGetNewMenuTreePermission } from 'features/users/use-cases/get-new-menu-permission';
import { useResetRoleMenuPermission } from 'features/users/use-cases/reset-role-permission';
import { useUserContext } from 'features/users/user-context';
import React, { useEffect, useState } from 'react';

import { AddRoleModal } from '../modals/edit-role/add-role';


export const RolePermissionManagement = () => {
    useState<any>();
    const [showRoleModal, setShowRoleModal] = useState(false);

    const { user } = useUserContext();

    const roleparams = {
        PeopleId: user?.UserDetails?.MemId
    }

    const { data: allRoles, isLoading: roleRolling, refetch: roleRefetch } = useGetAllRoleManagement(roleparams as any);

    const { mutate: mutateNewMenuPermission, isLoading: updateMenuLoading } = useCreateNewMenuPermission();

    const [selectedRole, setSelectedRole] = useState(0);

    const [roleEditData, setRoleEditData] = useState<any>(null);


    const { mutate: deleteMutate } = useCreateRole();

    const menuParams = {
        RoleId: selectedRole,
    }

    const { data: menuTree, refetch, isLoading } = useGetNewMenuTreePermission(menuParams as any, { enabled: true });


    const { mutate: resetMutate, isLoading: resetLoading } = useResetRoleMenuPermission();



    useEffect(() => {
        if (selectedRole) {
            refetch();
        }
    }, [selectedRole])



    const [updatedPermissions, setUpdatedPermissions] = useState<any>([]);
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    useEffect(() => {
        setUpdatedPermissions(menuTree?.PermissionsTypes)
    }, [menuTree])

    // Function to handle checkbox change
    const handleCheckboxChange = (pageId: any, privilageId: any, isParent = false) => {
        setUpdatedPermissions((prev: any[]) => {
            // Toggle the parent permission
            let updated = prev.map(permission =>
                permission.PageId === pageId && permission.PrivilageId === privilageId
                    ? { ...permission, IsSelected: !permission.IsSelected }
                    : permission
            );

            // If the row is a parent, update all its child permissions
            if (isParent) {
                const isSelected = !prev.find(
                    permission => permission.PageId === pageId && permission.PrivilageId === privilageId
                )?.IsSelected;

                const childMenus = getChildMenus(pageId);
                childMenus.forEach((child: { PageId: any; }) => {
                    updated = updated.map(permission =>
                        permission.PageId === child.PageId && permission.PrivilageId === privilageId
                            ? { ...permission, IsSelected: isSelected }
                            : permission
                    );
                });
            }

            return updated;
        });
    };


    // Handle "select all" checkboxes in the header
    const handleSelectAll = (privilageId: any, isSelected: boolean) => {
        setUpdatedPermissions((prev: any[]) =>
            prev.map(permission =>
                permission.PrivilageId === privilageId
                    ? { ...permission, IsSelected: isSelected }
                    : permission
            )
        );
    };

    // Toggle show/hide for parent rows
    const toggleExpand = (parentId: number) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(parentId)
                ? prevExpandedRows.filter((id) => id !== parentId)
                : [...prevExpandedRows, parentId]
        );
    };

    // Helper to check if all checkboxes in the column are selected
    const isAllSelected = (privilageId: any) => {
        return updatedPermissions?.filter((permission: { PrivilageId: any; }) => permission.PrivilageId === privilageId)
            .every((permission: { IsSelected: any; }) => permission.IsSelected);
    };

    // Filter parent menus
    const parentMenus = menuTree?.MenuPagesList?.filter((page: { IsParent: number; }) => page.IsParent === 1);

    // Filter child menus for a specific parent
    const getChildMenus = (parentId: any) => {
        return menuTree?.MenuPagesList?.filter((page: { ParentId: any; IsParent: number; }) => page.ParentId === parentId && page.IsParent === 2);
    };


    // Function to update user menu tree
    const updateUserMenuTree = (privileges: string) => {
        if (selectedRole === 0) {
            return message.error("Please Select Role")
        }
        const payload: createNewMenuPermissionDTO = {
            Flag: 'SetRolePrivileges',
            Role_FK_Id: selectedRole,
            PrivilagesString: privileges,
        };

        mutateNewMenuPermission(payload, { onSuccess: () => refetch() });
    };



    const generatePrivilegesString = (permissions: any[]) => {
        return permissions
            .filter((item) => item.IsSelected) // Filter selected items
            .map((item) => `${item.PageId}-${item.PrivilageId}`) // Format PageId-PrivilageId
            .join(','); // Join into comma-separated string
    };

    // Handle form submission
    const handleSubmit = () => {
        const privileges = generatePrivilegesString(updatedPermissions);
        updateUserMenuTree(privileges);
    };


    const handleReset = () => {
        if (selectedRole === 0) {
            return message.error("Please Select Role")
        }

        const payloadReset = {
            RoleId: selectedRole,
            Flag: "DeletePagesOfRole"
        }

        resetMutate(payloadReset, { onSuccess: () => refetch() })

    }

    useEffect(() => setRoles(allRoles?.getRoles), [allRoles])


    const [searchTerm, setSearchTerm] = useState("");

    const [roles, setRoles] = useState([]);

    const handleSearch = (e: any) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        // Filter roles based on the search term
        const filteredRoles = allRoles?.getRoles.filter((role: any) =>
            role.RoleName.toLowerCase().includes(value)
        );
        setRoles(filteredRoles);
    };



    const handleCloseRoleModal = () => {
        setShowRoleModal(false);
        setRoleEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEditRole = (e: any, role: any) => {
        e.stopPropagation();
        setShowRoleModal(true);
        setRoleEditData(role)
        document.body.style.overflow = 'hidden';
    }

    const handleDelete = (e: any, role: any) => {
        e.stopPropagation();

        const payload = {
            Id: role.Id,
            Flag: "delete"
        }

        deleteMutate(payload as any, { onSuccess: () => roleRefetch });
    };

    const handleSelect = (role: any) => {
        setSelectedRole(role.Id); // Store clicked role ID
    }

    const isAllPagesSelected = () => {
        return updatedPermissions?.every((permission: { IsSelected: any; }) => permission.IsSelected);
    };

    const handleSelectAllPages = (isSelected: boolean) => {
        setUpdatedPermissions((prev: any[]) =>
            prev.map(permission => ({ ...permission, IsSelected: isSelected }))
        );
    };

    // if (isLoading) return <Loader />;


    return (
        <>
            <div className={`cstmrightmdl ${showRoleModal && "show"}`}>
                <AddRoleModal onClose={handleCloseRoleModal} roleEditData={roleEditData} roleRefetch={roleRefetch} />
            </div>


            <div className='role_wrap'>
                <div className='role_hd'>
                    <h3>Role Mangement</h3>
                    <div className='role_btn'>
                        {isLoading ?
                            < Skeleton.Button active />
                            :
                            <Button type="primary" onClick={handleSubmit} loading={updateMenuLoading}>
                                Save
                            </Button>}
                        {isLoading ?
                            < Skeleton.Button active />
                            :
                            <Button type="primary" onClick={handleReset} loading={resetLoading}>
                                Reset Permission
                            </Button>}
                    </div>
                </div>


                <div className='role_row'>

                    <div className='role_add'>
                        <Button type="" onClick={(e: any) => handleEditRole(e, null)} className="add_btn">
                            <PlusOutlined />
                            Add Role
                        </Button>
                        <div className='srch_bar'>
                            {roleRolling ?
                                <Skeleton.Input active={true} />
                                :
                                <Input
                                    placeholder="Search roles"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    style={{ width: "100%" }}
                                />}
                        </div>

                        {/* Display filtered roles */}
                        <div className='role_name'>
                            {roleRolling ?
                                <Skeleton active={true} paragraph={{
                                    rows: 10,
                                }} />
                                :
                                <>
                                    {roles?.map((item: any) => (
                                        <div className={`role_bx ${selectedRole === item?.Id && 'highlight_role'} ${!item.IsActive ? "inActive_Role" : "pointer"}`}
                                            key={item?.Id}

                                            onClick={() => item.IsActive && handleSelect(item)}
                                        >

                                            <span className={` ${!item.IsActive && "inActive_Role_text"}`}>{item.RoleName}</span>

                                            <div>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    style={{ marginRight: "8px" }}
                                                    onClick={(e: any) => handleEditRole(e, item)}
                                                />
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    danger
                                                    onClick={(e: any) => handleDelete(e, item)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>}

                        </div>
                    </div>


                    <div className='role_card'>
                        {isLoading ?
                            <Skeleton active={true} paragraph={{
                                rows: 15,
                            }} />
                            :
                            <>

                                {
                                    menuTree?.ColumnNames.length > 0 &&
                                    <div>
                                        <table className='role_table'>
                                            <thead>
                                                <tr>
                                                    <th>

                                                        <label>
                                                            <Checkbox

                                                                checked={isAllPagesSelected()}
                                                                onChange={(e) => handleSelectAllPages(e.target.checked)} />

                                                            Pages
                                                        </label>
                                                    </th>
                                                    {menuTree?.ColumnNames?.map((col: any) => (
                                                        <th key={col.PrivilageId}>
                                                            <label>

                                                                <Checkbox
                                                                    checked={isAllSelected(col.PrivilageId)}
                                                                    onChange={(e) => handleSelectAll(col.PrivilageId, e.target.checked)}
                                                                />
                                                                {col.ColumnName}
                                                            </label>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {parentMenus?.map((parentMenu: any) => (
                                                    <React.Fragment key={parentMenu.PageId}>
                                                        <tr className='parent_tr'>
                                                            {/* Parent menu with expandable row */}
                                                            <td>
                                                                <button onClick={() => toggleExpand(parentMenu.PageId)}>
                                                                    {expandedRows.includes(parentMenu.PageId) ? '-' : '+'}
                                                                </button>
                                                                <span className='pge_name'>{parentMenu.MenuName}</span>
                                                            </td>
                                                            {menuTree?.ColumnNames.map((col: any) => {
                                                                const permission = updatedPermissions?.find(
                                                                    (perm: any) => perm.PageId === parentMenu.PageId && perm.PrivilageId === col.PrivilageId
                                                                );

                                                                return (
                                                                    <td key={col.PrivilageId}>
                                                                        <Checkbox
                                                                            checked={permission?.IsSelected || false}
                                                                            onChange={() => handleCheckboxChange(parentMenu.PageId, col.PrivilageId, true)}
                                                                        />
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                        {/* Display child menus if parent is expanded */}
                                                        {expandedRows.includes(parentMenu.PageId) &&
                                                            getChildMenus(parentMenu.PageId).map((childMenu: any) => (
                                                                <tr key={childMenu.PageId} style={{ marginTop: "5px" }}>
                                                                    <td style={{ paddingLeft: '20px' }}>{childMenu.MenuName}</td>
                                                                    {menuTree?.ColumnNames.map((col: any) => {
                                                                        const permission = updatedPermissions.find(
                                                                            (perm: any) => perm.PageId === childMenu.PageId && perm.PrivilageId === col.PrivilageId
                                                                        );

                                                                        return (
                                                                            <td key={col.PrivilageId}>
                                                                                <Checkbox
                                                                                    checked={permission?.IsSelected || false}
                                                                                    onChange={() => handleCheckboxChange(childMenu.PageId, col.PrivilageId)}
                                                                                />
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>}
                            </>}
                    </div>

                </div>
                {/* } */}

                {/* 
                </> */}
            </div>
        </>
    );
};

