import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, message, Modal, Table, Tooltip } from 'antd';
import { useGetItemsSizeGuide } from 'features/items/use-cases/get-item-size-guide';
import { useUpdateCategory } from 'features/items/use-cases/upload-items-category';
import { useUpdateSubCategory } from 'features/items/use-cases/upload-items-sub-category';
import { useEffect } from 'react';

export const SizeGuideModal = ({ onClose, editData, modalType }: { onClose: () => void, editData: any; modalType: string }) => {



    const params = {
        SizeGuide_Fk_Id: editData?.SizeGuide_Fk_Id,
        CurrentPage: 1,
        RecordsPerPage: 10,
    }



    const { data, isLoading: getLoading, refetch, isFetching } = useGetItemsSizeGuide((params), {
        enabled: false,
    })

    const { mutate: updateCategorySize, isLoading: cateLoading } = useUpdateCategory();

    const { mutate: updateSubCategorySize, isLoading: subLoading } = useUpdateSubCategory();

    const handleStatusChange = (record: any) => {
        const formData = new FormData();
        formData.append("Id", editData?.Id);
        formData.append("Flag", "SaveSizeGuide");
        formData.append("SizeGuide_Fk_Id", record.SizeGuid_FK_Id);
        if (modalType === "category") {
            updateCategorySize(formData, { onSuccess: onClose })
        } else {
            updateSubCategorySize(formData, { onSuccess: onClose })
        }

    };

    useEffect(() => {
        refetch();
    }, [editData?.SizeGuide_Fk_Id])

    const handleClose = () => {
        onClose();
    };

    const { confirm } = Modal;

    const handleChangeConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure want to Save Size Guide?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleStatusChange(record);
            },
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'IsAllChecked',
            key: 'SizeGuid_FK_Id',
            render: (IsAllChecked: boolean, record: any) => (
                <Tooltip title="Toggle status">
                    <Checkbox
                        key={record?.SizeGuid_FK_Id}
                        checked={IsAllChecked}
                        onChange={(e) => handleChangeConfirm(record)}
                    />
                </Tooltip>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'Title_En',
            key: 'Title_En',
        },

    ];

    const dataSource = data?.Items?.map((item: any) => ({
        ...item,
        key: item.SizeGuid_FK_Id, // Use a unique property for each item as key
    }));

    if (!editData?.SizeGuide_Fk_Id) {
        onClose();
        message.error("SizeGuide_Fk_Id not found. Please check.");

        return null
    }

    return (
        <>
            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Size Guide</h4>
                </div>
                <div className='modal_body'>
                    <Table dataSource={dataSource} columns={columns} pagination={false} loading={isFetching} />
                    <div style={{ marginTop: 16 }}>
                        <strong>Total : {data?.Items?.length}</strong>
                    </div>
                </div>

            </div>
        </>
    );
};
