import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsSizeFilterFormPaginated, ItemsSizeFilterFormValues } from '../components/filter-form/items-size-filter/types';
import { ItemsSizeListTable } from '../components/items-size-list-tabel/items-size-list-tabel';
import { ItemsSizeListTopBar } from '../components/top-bar/items-size-topbar';
import { useGetItemsSizeLists } from '../use-cases/get-items-size-list'; import { usePermission } from 'utils/user-permission-page';
;


const getInitialFilterValues: ItemsSizeFilterFormValues = {
    SizeTypeId: undefined,
    Size: undefined,
    StatusId: undefined,
};

export const ItemsSizeViews = () => {
    const permission = usePermission();
    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsSizeFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, isLoading, isFetching, refetch } = useGetItemsSizeLists((params), {
        retry: false,
        enabled: permission.View
    });

    if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsSizeFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsSizeListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsSizeListTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};



