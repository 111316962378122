import { ContentFilters } from 'components';
import { ItemsCategoryFilterFormValues } from '../filter-form/items-category-filter-form/types';
import { ItemsGenderFilterForm } from '../filter-form/items-gender-filter-form/items-gender-filter-form';


type UsersTopBarProps = {
    getInitialFilterValues: ItemsCategoryFilterFormValues;
    handleFilter: (values: ItemsCategoryFilterFormValues) => void;
};

export const ItemsGenderListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'items-gender-filters-form';


    return (
        <>

            {/* <ContentTopBar title={'Items Category List'} /> */}
            <ContentFilters formId={formId}>
                <ItemsGenderFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
