import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsPurchasedFilterFormField, ItemsPurchasedFilterFormPaginated, ItemsPurchasedFilterFormValues } from '../components/filter-form/items-purchased-filter/types';
import { ItemsPurchasedTable } from '../components/items-purchased-tabel/items-purchased-tabel';
import { ItemsPurchasedListTopBar } from '../components/top-bar/items-purchased-topbar';
import { useGetItemsPurchasedLists } from '../use-cases/get-item-purchased-list'; import { usePermission } from 'utils/user-permission-page';
;



const getInitialFilterValues: ItemsPurchasedFilterFormValues = {
    [ItemsPurchasedFilterFormField.Customer_Name]: undefined,
    [ItemsPurchasedFilterFormField.Price]: undefined,
    [ItemsPurchasedFilterFormField.ItemName]: undefined,
    [ItemsPurchasedFilterFormField.Qty]: undefined,
};


export const ItemsPurchasedViews = () => {
    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsPurchasedFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, isFetching, isLoading, refetch } = useGetItemsPurchasedLists((params), {
        enabled: permission.View
    });

    if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsPurchasedFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsPurchasedListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsPurchasedTable
                isLoading={isFetching || isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};



