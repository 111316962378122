import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateCommonStatus } from 'features/admin/use-cases/update-common-status';
import { Dispatch, SetStateAction, useState } from 'react';

import noImg from '../../../../assets/No_Image_Available.jpg';
import { AddBrandsModal } from '../add-brands-model/add-brands-model';
import { BrandsFilterFormPaginated } from '../brands-list-filter/types';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    isFetching: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: BrandsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};
export const BrandsListTable = ({
    itemList,
    isLoading,
    isFetching,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });
    };
    ;
    const { mutate: mutateUpdateStatus } = useUpdateCommonStatus();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEdit = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }

    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleEdit(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null
    ]

    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddBrandsModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading || isFetching}
                    dataSource={itemList?.brandList}
                >

                    <Column<any>
                        title={'Logo'}
                        render={(_, record: any) => (
                            <Image src={record.BrandLogo} alt="Brand Logo" width={50} height={50}
                                onError={(e) => {
                                    e.currentTarget.src = noImg;
                                }}
                            />
                        )}
                    />

                    <Column<any>
                        title={'Brand Name Eng'}
                        dataIndex={'BrandName_En'}
                        sorter={(a, b) => a.BrandName_En && a.BrandName_En.localeCompare(b.BrandName_En)}
                    />

                    <Column<any>
                        title={'Brand Name Arabic'}
                        dataIndex={'BrandName_Ar'}
                        sorter={(a, b) => a.BrandName_Ar && a.BrandName_Ar.localeCompare(b.BrandName_Ar)}
                    />
                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        render={(_, record: any) => (record?.IsActive ? 'Active' : "InActive")}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                    />
                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                                <Tooltip>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(isActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "BrandChangeStatus",
                                                Status: isActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = isActive; // Ensure it's an integer
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
