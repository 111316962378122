import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { BenefitsListTable } from '../components/benefits-list-table/benefits-list-table';
import { BenefitsFilterFormField, BenefitsFilterFormPaginated, BenefitsFilterFormValues } from '../components/filter-form/benefits-list-filter/types';
import { BenefitsListTopBar } from '../components/tob-bar/benefits-list-topbar';
import { useGetBenefitLists } from '../use-cases'; import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: BenefitsFilterFormValues = {
  [BenefitsFilterFormField.BenefitsType]: undefined,
  [BenefitsFilterFormField.Name]: undefined,
  [BenefitsFilterFormField.IsActive]: undefined,
};


export const BenefitsListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<BenefitsFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetBenefitLists((params), {
    retry: false,
    enabled: permission.View
  });

  if (!permission.View) return null;


  const handleFiltersChange = (values: BenefitsFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <BenefitsListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <BenefitsListTable
        isLoading={isLoading}
        isFetching={isFetching}
        benefitsList={data?.Items}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

