import { Button, Card, Input } from 'antd';
import { ContentFilters } from 'components';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsStocksFilterFormField, ItemsStocksFilterFormPaginated, ItemsStocksFilterFormValues } from '../components/filter-form/items-stocks-filter-form/types';
import { ItemsStocksTable } from '../components/items-stocks-table/items-stocks-table';
import { ItemsStocksListTopBar } from '../components/top-bar/items-stocks-list-topbar';
import { useGetItemsStocksLists } from '../use-cases/get-items-stocks';
import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: ItemsStocksFilterFormValues = {
    [ItemsStocksFilterFormField.Brand]: undefined,
    [ItemsStocksFilterFormField.Category]: undefined,
    [ItemsStocksFilterFormField.SubCategory]: undefined,
    [ItemsStocksFilterFormField.ItemCode]: undefined,
};


export const ItemsUploadListView = () => {
    const permission = usePermission();
    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsStocksFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    // const { data, isLoading, refetch, isFetching } = useGetItemsStocksLists((params), {
    //     retry: false,
    // enabled: permission.View
    // });

    if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsStocksFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        // refetch();
    };

    return (
        <>

            <ContentFilters formId={"hhggj"}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Input
                            type={"file"}
                        />
                    </div>
                    <div>
                        <Button type="primary">
                            Extract Records From Excel Sheet
                        </Button>
                    </div>
                    <div>
                        <Button type="primary" danger>
                            Clear
                        </Button>
                    </div>
                </div>
            </ContentFilters>

            <Card>
                <div style={{ display: "flex" }}>
                    <h1 style={{ color: "red" }}>upload of Items done not  generate that all items will be uploaded. Items will be undergo validation checking end only valid items will be uploaded</h1>
                    <Button type={"primary"}>
                        Upload Excel Sheet
                    </Button>
                </div>
            </Card>

            {/* <ItemsStocksTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            /> */}
            <Outlet />
        </>
    );
};

