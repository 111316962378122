import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsSubCategoryFilterFormPaginated, ItemsSubCategoryFilterFormValues } from '../components/filter-form/items-sub-category-filter-form/types';
import { ItemsSubCategoryTable } from '../components/items-sub-category-tabel/items-sub-category-table';
import { ItemsSubCategoryListTopBar } from '../components/top-bar/item-sub-category-list';
import { useGetItemsSubCategoryLists } from '../use-cases/get-items-sub-category'; import { usePermission } from 'utils/user-permission-page';
;

const getInitialFilterValues: ItemsSubCategoryFilterFormValues = {
    GenderId: undefined,
    title: undefined,
    statusId: undefined,
    GroupId: undefined,
};


export const ItemsSubCategoryListView = () => {
    const permission = usePermission();
    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsSubCategoryFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, isFetching, refetch } = useGetItemsSubCategoryLists((params), {
        retry: false,
        enabled: permission.View
    });

    if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsSubCategoryFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsSubCategoryListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsSubCategoryTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

