import { CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Empty, message, Modal, Rate, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateStatus } from 'features/users/use-cases/create-user';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

import { ItemsStocksFilterFormPaginated } from '../filter-form/items-stocks-filter-form/types';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsStocksFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsStocksTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const navigate = useNavigate();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this any?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };

    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useUpdateStatus();

    const handleDelete = async (id: any) => {

        try {
            // Simulate the loading state
            mutateUpdateStatus({
                Id: id,
                flag: "any_DELETE",
                status: true
            });

        } catch (error) {
            // Handle any errors here
            message.error("")
        }
    };


    const navigateDetails = (record: any) => {
        navigate(getRoute(ROUTES.ITEMS_LIST_DETAILS, record.Id));
    }

    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='ItemCode'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.getItemStocks}

                >

                    <Column<any>
                        title={'Item Code'}
                        dataIndex={'ItemCode'}
                        sorter={(a, b) => {
                            if (!a.Rating) return 1; // Sort null or undefined values to the end
                            if (!b.Rating) return -1;

                            return a.Rating - b.Rating;
                        }}

                    />

                    <Column<any>
                        title={'Item Name'}
                        dataIndex={'ItemName_En'}
                        sorter={(a, b) => {
                            if (!a.ItemName_En) return 1; // Sort null or undefined values to the end
                            if (!b.ItemName_En) return -1;

                            return a.ItemName_En.localeCompare(b.ItemName_En);
                        }}

                    />

                    <Column<any>
                        title={'Brand'}
                        dataIndex={'BrandName_En'}
                        sorter={(a, b) => {
                            if (!a.BrandName_En) return 1; // Sort null or undefined values to the end
                            if (!b.BrandName_En) return -1;

                            return a.BrandName_En.localeCompare(b.BrandName_En);
                        }}
                    />
                    <Column<any>
                        title={'Size'}
                        dataIndex={'Size'}
                        sorter={(a, b) => {
                            if (!a.Size) return 1; // Sort null or undefined values to the end
                            if (!b.Size) return -1;

                            return a.Size.localeCompare(b.Size);
                        }}
                    />
                    <Column<any>
                        title={'Price'}
                        dataIndex={'ItemPrice'}
                        sorter={(a, b) => {
                            if (!a.ItemPrice) return 1; // Sort null or undefined values to the end
                            if (!b.ItemPrice) return -1;

                            return a.ItemPrice - b.ItemPrice;
                        }}
                    />
                    <Column<any>
                        title={'Av Qty'}
                        dataIndex={'Qty'}
                        sorter={(a, b) => {
                            if (!a.Qty) return 1; // Sort null or undefined values to the end
                            if (!b.Qty) return -1;

                            return a.Qty - b.Qty;
                        }}
                    />
                    <Column<any>
                        title={'Transit Qty'}
                        dataIndex={'InTransit_Qty'}
                        sorter={(a, b) => {
                            if (!a.InTransit_Qty) return 1; // Sort null or undefined values to the end
                            if (!b.InTransit_Qty) return -1;

                            return a.InTransit_Qty - b.InTransit_Qty;
                        }}
                    />
                    <Column<any>
                        title={'Sold Qty'}
                        dataIndex={'SoldQty'}
                        sorter={(a, b) => {
                            if (!a.SoldQty) return 1; // Sort null or undefined values to the end
                            if (!b.SoldQty) return -1;

                            return a.SoldQty - b.SoldQty;
                        }}
                    />

                    <Column<any>
                        title={'Total Qty'}
                        dataIndex={'Qty'}
                        sorter={(a, b) => {
                            if (!a.Qty) return 1; // Sort null or undefined values to the end
                            if (!b.Qty) return -1;

                            return a.Qty - b.Qty;
                        }}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
