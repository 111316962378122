import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { BenefitsListFilterForm } from '../filter-form/benefits-list-filter/benefits-list-filter-form';
import { BenefitsFilterFormValues } from '../filter-form/benefits-list-filter/types';
import { AddBenefitsModal } from '../models/add-benefits-model/add-benefits-model';
import { usePermission } from 'utils/user-permission-page';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: BenefitsFilterFormValues;
    handleFilter: (values: BenefitsFilterFormValues) => void;

};

export const BenefitsListTopBar = ({
    getInitialFilterValues,
    handleFilter,

}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'benefits-list-filters-form';
    const permission = usePermission();

    const screens = useBreakpoint();

    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>

            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddBenefitsModal onClose={handleCloseModal} editData={null} />
            </div>

            {permission.Add && <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Benefits
                </Button>
            </div>}


            <ContentFilters formId={formId}>
                <BenefitsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
