import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { NewsFilterFormField, NewsFilterFormPaginated, NewsFilterFormValues } from '../components/filter-form/news-list-filter/types';
import { NewsListTable } from '../components/news-list-table/news-list-table';
import { NewsListTopBar } from '../components/tob-bar/news-list-topbar';
import { useGetNewsLists } from '../use-cases';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: NewsFilterFormValues = {
  [NewsFilterFormField.Title]: undefined,
  [NewsFilterFormField.IsActive]: undefined,
};


export const NewsListView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<NewsFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetNewsLists((params), {
    retry: false,
    enabled: permission.View
  });

  if (!permission.View) return null;

  const handleFiltersChange = (values: NewsFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <NewsListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <NewsListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};

