import { apiClient, ApiClientConfig } from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { commonDeleteDTO, updateCommonStatusDTO } from 'features/admin';
import { getUpdatedQueryParams } from 'utils/updateQueryParamDownload';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};

export type NotifyStatusDTO = {
  Id: number;
  Flag: string;
};


export type UpdateItemsInfoDTO = {
  Flag?: string;
  Id?: string;
  ItemName_En?: string;
  ItemName_Ar?: string;
  ItemDescription_En?: string;
  ItemDescription_Ar?: string;
  OtherInformation_En?: string;
  OtherInformation_Ar?: string;
  Group_Fk_Id?: string;
  Category_Fk_Id?: string;
  Brand_Fk_Id?: string;
  Item_Code?: string;
  ItemPrice?: number;
  OfferType_Fk_Id?: string;
  Offer?: string;
  OfferPrice?: number;
  ItemCellableQty?: number;
  IsActive?: boolean;
  // IsDiscount: string;
};


export const ItemsApiService = () => {


  const getItemsLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemlist`,
      params,
    });

    return response.data;
  };


  const getPdfDownload = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/orderlist/pdforderlist`,
      params,
    });

    return response.data;
  };


  const getExcelDownload = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/orderlist/exporttoexcelorderlist`,
      params,
    });

    return response.data;
  };

  const getDropdownLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dropdown/getdropdownlist`,
      params,
    });

    return response.data?.dropdownLists;
  };

  // items info children's tabs

  const getItemInfoById = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitembyid?Id=${id}`,
    });

    return response.data;
  };

  const updateItemsInfo = async (
    payload: UpdateItemsInfoDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupadateitem`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };




  const getItemSizeListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemsizedetailbyid`,
      params,
    });

    return response.data;
  };


  const getItemStockListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemstocklist`,
      params,
    });

    return response.data;
  };


  const getItemImagesListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemimages`,
      params,
    });

    return response.data;
  };

  const getItemTransactionListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemtransactionlist`,
      params,
    });

    return response.data;
  };



  const getItemReviewsListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemreviewlist`,
      params,
    });

    return response.data;
  };

  const getItemCustomRequestListById = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemcustomrequest`,
      params,
    });

    return response.data;
  };

  // items childrens menu

  const getItemsReviewsLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemreviewlist`,
      params,
    });

    return response.data;
  };

  const getItemsCategoryLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getcategorylist`,
      params,
    });

    return response.data;
  };


  const getItemsStocksLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemstocklist_frommenu`,
      params,
    });

    return response.data;
  };

  const uploadItemImg = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/itemmaster/saveitemimages`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const getItemsSubCategoryLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getsubcategorylist`,
      params,
    });

    return response.data;
  };


  const getItemsSeasonLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemseasonlist`,
      params,
    });

    return response.data;
  };

  const getItemsSizeTypeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemsizetypelist`,
      params,
    });

    return response.data;
  };

  const addItemsSeasons = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupdateitemseason`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };



  const getItemsSizeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getitemsizelist`,
      params,
    });

    return response.data;
  };

  const updateItemsSize = async (
    payload: UpdateItemsInfoDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupdateitemsize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getItemsPurchasedLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getpurchaseditemlist`,
      params,
    });

    return response.data;
  };

  const getItemsNotifyLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getnotifylist`,
      params,
    });

    return response.data;
  };



  const updateNotifyStatus = async (
    payload: NotifyStatusDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/updatenotify`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getCustomSizeByItemsId = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getcustomsizerecords`,
      params,
    });

    return response.data;
  };

  const updateCommonStatus = async (
    payload: updateCommonStatusDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commonupdatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const commonDelete = async (
    payload: commonDeleteDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commondelete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const addCustomSizeByItemsId = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `itemmaster/UpdateCustomSizeRecords`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateItemsSizeGuide = async (
    payload: UpdateItemsInfoDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupdateitemsize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getItemsSizeGuide = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/itemmaster/getsizeguidedetails`,
      params,
    });

    return response.data;
  };


  const updateCategory = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/itemmaster/saveandupdatecategory`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const updateSubCategory = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/itemmaster/saveandupdatesubcategory`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const updateItemsSizeType = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupdatesizetype`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateItemreviewstaus = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveitemreviewstaus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getBarcodeByItemsId = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `itemmaster/generatebarcodeforall?Id=${id}&Flag=Single`,
    });

    return response;
  };


  const getItemsExcelDownload = async (tableParams: { [key: string]: any }) => {
    const queryParams = getUpdatedQueryParams(tableParams);


    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/itemmaster/exporttoexcelitemlist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };

  const getItemsPdfDownload = async (tableParams: { [key: string]: any }) => {

    const queryParams = getUpdatedQueryParams(tableParams);

    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/itemmaster/pdfitemlist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };


  const updateStatusItemSizeById = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `itemmaster/DeleteAndUpdateItemDetailsSize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getItemsLists,
    getPdfDownload,
    getExcelDownload,
    getDropdownLists,
    getItemInfoById,
    updateItemsInfo,
    getItemSizeListById,
    getItemStockListById,
    getItemImagesListById,
    getItemTransactionListById,
    getItemCustomRequestListById,
    getItemReviewsListById,
    getItemsReviewsLists,
    getItemsStocksLists,
    getItemsCategoryLists,
    uploadItemImg,
    getItemsSubCategoryLists,
    getItemsSeasonLists,
    getItemsSizeTypeLists,
    addItemsSeasons,
    getItemsSizeLists,
    getItemsPurchasedLists,
    getItemsNotifyLists,
    updateItemsSize,
    updateNotifyStatus,
    getCustomSizeByItemsId,
    addCustomSizeByItemsId,

    updateCommonStatus,
    commonDelete,

    getItemsSizeGuide,
    updateItemsSizeGuide,
    updateCategory,
    updateSubCategory,
    updateItemsSizeType,
    updateItemreviewstaus,
    getBarcodeByItemsId,

    updateStatusItemSizeById,

    getItemsExcelDownload,
    getItemsPdfDownload
  };
};
