import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useItemsCommonStatus } from 'features/items/use-cases/update-common-items-status';
import { Dispatch, SetStateAction, useState } from 'react';

import noImg from '../../../../assets/No_Image_Available.jpg';
import { ItemsCategoryFilterFormPaginated } from '../filter-form/items-category-filter-form/types';
import { EditCategoryModal } from '../model/edit-item-category/edit-item-category-model';
import { SizeGuideModal } from '../model/size-guide-modal';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsCategoryFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsCategoryTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const { mutate: mutateUpdateStatus } =
        useItemsCommonStatus();

    // const { mutate: mutateDelete, isLoading: isLoadingDelete } =
    //     useMarketingDelete();


    // const handleDelete = async (id: number) => {
    //     mutateDelete({
    //         Id: id,
    //         Flag: "FAQDelete ",
    //     });
    // };


    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenEditModel(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,
        // {
        //     key: 'size',
        //     label: <Button onClick={() => handleSizeGuide(record)} type="text" icon={<DeleteOutlined />}>
        //         Link size Guide
        //     </Button>,
        // },
    ]

    const handleOpenEditModel = (record: any) => {
        setEditData(record);
        setOpenEdit(true);
        document.body.style.overflow = 'hidden';
    }

    const handleSizeGuide = async (record: any) => {
        setEditData(record);
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseModal = () => {
        setEditData(null);
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleCloseEditModal = () => {
        setOpenEdit(false);
        setEditData(null);
        document.body.style.overflow = 'visible';
    }


    return (
        <div className='order_rtn'>
            {isOpen && <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <SizeGuideModal onClose={handleCloseModal} editData={editData} modalType={"category"} />
            </div>
            }
            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <EditCategoryModal onClose={handleCloseEditModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table
                    rowKey='RowId'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}

                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList?.getCategoryLists}

                >
                    <Column<any>
                        title={'Gender'}
                        dataIndex={'Gender'}
                        sorter={(a, b) => {
                            if (!a.Gender) return 1;
                            if (!b.Gender) return -1;

                            return a.Gender.localeCompare(b.Gender);
                        }}
                    />
                    <Column<any>
                        title={'Title'}
                        dataIndex={'GroupName_En'}
                        sorter={(a, b) => {
                            if (!a.GroupName_En) return 1;
                            if (!b.GroupName_En) return -1;

                            return a.GroupName_En.localeCompare(b.GroupName_En);
                        }}

                    />

                    <Column<any>
                        title={'Title(Arabic)'}
                        dataIndex={'GroupName_Ar'}
                        sorter={(a, b) => {
                            if (!a.GroupName_Ar) return 1; // Sort null or undefined values to the end
                            if (!b.GroupName_Ar) return -1;

                            return a.GroupName_Ar.localeCompare(b.GroupName_Ar);
                        }}
                    />



                    <Column<any>
                        title={'Group Category Image'}
                        render={(_, record) => (
                            <Image
                                src={record?.GroupLogo}
                                width={40}
                                height={40}
                                fallback={noImg}
                            />
                        )}
                    />

                    <Column<any>
                        title={'Size Guide'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => {
                            if (!a.Title_En) return 1; // Sort null or undefined values to the end
                            if (!b.SizeGuide) return -1;

                            return a.Title_En.localeCompare(b.Title_En);
                        }}
                        ellipsis
                    />

                    {/* <Column<any>
                        title={'Order'}
                        dataIndex={'SortOrder'}
                        sorter={(a, b) => {
                            if (!a.SortOrder) return 1; // Sort null or undefined values to the end
                            if (!b.SortOrder) return -1;

                            return a.SortOrder - b.SortOrder;
                        }}
                    /> */}


                    <Column<any>
                        title={'Status'}
                        sorter={(a, b) => {
                            if (!a.IsActive) return 1; // Sort null or undefined values to the end
                            if (!b.IsActive) return -1;

                            return a.IsActive - b.IsActive;
                        }}
                        render={(_, record) => (
                            <h1>{record.IsActive ? "Active" : "InActive"}</h1>

                        )}
                    />

                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']}
                                    className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip title={"Is Active"}>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(IsActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.RowId,
                                                Flag: "CategoryChangeStatus",
                                                Status: IsActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = IsActive; // Ensure it's an integer
                                        }}
                                    />

                                </Tooltip>

                            </Space>
                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
