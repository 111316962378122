import { RightOutlined } from '@ant-design/icons';
import { Empty, Skeleton, Table, TablePaginationConfig } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: UserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const OrderReturnListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const permission = usePermission();

    const navigate = useNavigate();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    // const { confirm } = Modal;

    // const showDeleteConfirm = (record: any) => {
    //     //console.log(record,"record");
    //     confirm({
    //         title: 'Are you sure you want to delete this any?',
    //         icon: <ExclamationCircleOutlined />,
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk() {
    //             handleDelete(record.Id);
    //         },
    //     });
    // };

    // const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
    //     useUpdateStatus();

    // const handleDelete = async (id: any) => {

    //     try {
    //         // Simulate the loading state
    //         mutateUpdateStatus({
    //             Id: id,
    //             flag: "any_DELETE",
    //             status: true
    //         });

    //     } catch (error) {
    //         // Handle any errors here
    //         message.error("")
    //     }
    // };






    const navigateDetails = (record: any) => {
        navigate(getRoute(ROUTES.ORDER_RETURN_DETAILS, record.Id));
    }

    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {permission.Totals &&
                        <RecordCountBadge
                            text={'Total'}
                            count={itemsCount}
                        />}
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.getOrderLists}
                // className='cstm_table'

                >

                    <Column<any>
                        title={'Customer Name'}
                        className='pointer'
                        sorter={(a, b) => {
                            if (!a.Name) return 1; // Sort null or undefined values to the end
                            if (!b.Name) return -1;

                            return a.Name.localeCompare(b.Name);
                        }}
                        render={(value: any, record: any) => {
                            return (
                                <span className='arow_tab' onClick={() => navigateDetails(record)}>
                                    <RightOutlined />
                                    <h1>{record.Name}</h1>
                                </span>

                            )
                        }}
                    />
                    <Column<any>
                        title={'TansactionId'}
                        dataIndex={'TransactionId'}
                        sorter={(a, b) => {
                            if (!a.TransactionId) return 1; // Sort null or undefined values to the end
                            if (!b.TransactionId) return -1;

                            return a.TransactionId - b.TransactionId;
                        }}
                    />

                    <Column<any>
                        title={'Selling Price'}
                        dataIndex={'TotalAllSellingPrice'}
                        sorter={(a, b) => {
                            if (!a.TotalAllSellingPrice) return 1; // Sort null or undefined values to the end
                            if (!b.TotalAllSellingPrice) return -1;

                            return a.TotalAllSellingPrice - b.TotalAllSellingPrice;
                        }}
                    />


                    <Column<any>
                        title={'Delivery'}
                        dataIndex={'DeliveryCharge'}
                        sorter={(a, b) => {
                            if (!a.DeliveryCharge) return 1; // Sort null or undefined values to the end
                            if (!b.DeliveryCharge) return -1;

                            return a.DeliveryCharge - b.DeliveryCharge;
                        }}
                    />

                    <Column<any>
                        title={'Amount'}
                        dataIndex={'AmountPaid'}
                        sorter={(a, b) => {
                            if (!a.AmountPaid) return 1; // Sort null or undefined values to the end
                            if (!b.AmountPaid) return -1;

                            return a.AmountPaid - b.AmountPaid;
                        }}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'StatusText'}
                    />

                    <Column<any>
                        title={'Payment Type'}
                        dataIndex={'Title_En'}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
