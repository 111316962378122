import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { NotificationListFilterForm } from '../filter-form/notification-list-filter/notification-list-filter-form';
import { NotificationFilterFormValues } from '../filter-form/notification-list-filter/types';
import { AddNotificationModal } from '../models/add-notification-model/add-notification-model';
import { usePermission } from 'utils/user-permission-page';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: NotificationFilterFormValues;
    handleFilter: (values: NotificationFilterFormValues) => void;
};

export const NotificationListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'notification-list-filters-form';
    const permission = usePermission();

    const screens = useBreakpoint();

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>


            <div className={`cstmrightmdl ${showModal && "show"}`}>
                <AddNotificationModal onClose={handleCloseModal} editData={""} />
            </div>


            {permission.Add && <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModal}
                >
                    Add Notification
                </Button>

            </div>}


            <ContentFilters formId={formId}>
                <NotificationListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
