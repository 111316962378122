import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, message, Modal, Rate, Skeleton, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateItemreviewstaus } from 'features/items/use-cases/update-reviews-status';
import { Dispatch, SetStateAction } from 'react';

import { AllItemsReviewsFilterFormPaginated } from '../filter-form/all-items-reviews-list-filter/types';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: AllItemsReviewsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsReviewTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: `Are you sure you want to ${record?.ReviewStatus === "Approve" ? "Reject" : "Approve"} this Rating?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
        });
    };

    const { mutate: mutateUpdateStatus } =
        useUpdateItemreviewstaus();

    const handleDelete = async (record: any) => {

        if (record?.ReviewStatus === "Approve") {
            mutateUpdateStatus({
                Id: record?.Id,
                Flag: "Rejected",
                StatusId: 2
            });
        } else {
            mutateUpdateStatus({
                Id: record?.Id,
                Flag: "Approve",
                StatusId: 1
            });
        }


    };



    const menuItems = (record: any) => [
        permission.Delete ? {
            key: 'reject',
            label: <Button type="text" onClick={() => showDeleteConfirm(record)} icon={record?.ReviewStatus === "Approve" ? <CloseOutlined /> : <CheckOutlined />} style={{ color: record?.ReviewStatus === "Approve" ? "red" : "green" }}>{record?.ReviewStatus === "Approve" ? "Reject" : "Approve"}</Button>,
        } : null,


    ]

    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.getItemReviews}

                >
                    <Column<any>
                        title={'Customer'}
                        dataIndex={'CustomerName'}
                        sorter={(a, b) => {
                            if (!a.CustomerName) return 1; // Sort null or undefined values to the end
                            if (!b.CustomerName) return -1;

                            return a.CustomerName.localeCompare(b.CustomerName);
                        }}

                    />

                    <Column<any>
                        title={'Item Name'}
                        dataIndex={'ItemName_En'}
                        sorter={(a, b) => {
                            if (!a.ItemName_En) return 1; // Sort null or undefined values to the end
                            if (!b.ItemName_En) return -1;

                            return a.ItemName_En.localeCompare(b.ItemName_En);
                        }}
                    />

                    <Column<any>
                        title={'Rating'}
                        // dataIndex={'Rating'}
                        sorter={(a, b) => {
                            if (!a.Rating) return 1; // Sort null or undefined values to the end
                            if (!b.Rating) return -1;

                            return a.Rating - b.Rating;
                        }}
                        render={(_, record: any) => (
                            <Tooltip>
                                <Rate disabled defaultValue={record?.Rating} />
                            </Tooltip>

                        )}
                    />

                    <Column<any>
                        title={'Description'}
                        dataIndex={'ReviewDescription'}
                        sorter={(a, b) => {
                            if (!a.ReviewDescription) return 1; // Sort null or undefined values to the end
                            if (!b.ReviewDescription) return -1;

                            return a.ReviewDescription.localeCompare(b.ReviewDescription);
                        }}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'ReviewStatus'}
                        render={(_, record: any) => (
                            <h2 className={`${record?.ReviewStatus === "Approve" ? "green_clg" : record?.ReviewStatus === "Rejected" ? "red_clg" : ""}`} >
                                {record?.ReviewStatus}
                            </h2>
                        )}
                        sorter={(a, b) => {
                            if (!a.ReviewStatus) return 1; // Sort null or undefined values to the end
                            if (!b.ReviewStatus) return -1;

                            return a.ReviewStatus.localeCompare(b.ReviewStatus);
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Dropdown
                                menu={{ items: menuItems(record) }}
                                trigger={['click']}
                                className='drop_down_cstm'
                            >
                                <Button shape={"circle"} icon={< MoreOutlined />} />
                            </Dropdown>

                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
