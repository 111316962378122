import { Outlet, useParams } from 'react-router-dom'

import ItemsDetailsTabPage from '../components/items-details-tab/items-details-tab-page';
import { usePermission } from 'utils/user-permission-page';


export const ItemsDetailsView = () => {

  const { id } = useParams();
  const permission = usePermission();

  if (!permission.View) return null;

  if (!id) return null;

  return (
    <div>
      <ItemsDetailsTabPage id={id} />
      <Outlet />
    </div>
  )
}
