import { Button, Form } from 'antd';
import { useUpdateNotificationType } from 'features/marketing/use-cases/update-notification-type';
import { useEffect, useState } from 'react';

import { AddNotificationForm } from './add-notification-form';
import { AddNotificationFormField, AddNotificationFormValues } from './types';




export const AddNotificationModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-notification-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddNotificationFormValues>();

    const { isLoading, mutate } = useUpdateNotificationType();


    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddNotificationFormField.Id]: undefined,
        [AddNotificationFormField.Flag]: undefined,
        [AddNotificationFormField.AppType]: undefined,
        [AddNotificationFormField.DeviceType]: undefined,
        [AddNotificationFormField.NotificationType]: undefined,
        [AddNotificationFormField.Title]: undefined,
        [AddNotificationFormField.Title_Ar]: undefined,
        [AddNotificationFormField.DescriptionEn]: undefined,
        [AddNotificationFormField.DescriptionAr]: undefined,
    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue(
                {
                    ...editData,
                    // [AddNotificationFormField.DescriptionEn]: editData?.Description_En,
                    // [AddNotificationFormField.DescriptionAr]: editData?.Description_Ar,
                }
            );
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formValues: AddNotificationFormValues) => {
        formValues.Flag = "insert";
        formValues.Id = editData?.Id || 0;
        formValues.StatusId = 1;

        mutate(formValues, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? "Edit Notification" : "Add Notification"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddNotificationFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <AddNotificationForm />

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

