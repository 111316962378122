import { FileExcelOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid, message } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';

import { ItemsListFilterForm } from '../filter-form/items-list-filter/items-list-filter-form';
import { ItemsFilterFormValues } from '../filter-form/items-list-filter/types';
import { ItemsApiService } from 'features/items/api/items-api.service';
import { usePermission } from 'utils/user-permission-page';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsFilterFormValues;
    handleFilter: (values: ItemsFilterFormValues) => void;
    tableParams: any
};

export const ItemsListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {
    const permission = usePermission();

    const formId = 'item-list-filters-form';
    const [excelLoading, setExcelLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const screens = useBreakpoint();

    const handleExport = async () => {
        setExcelLoading(true);
        try {
            const response = await ItemsApiService().getItemsExcelDownload(tableParams);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ItemsList.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExcelLoading(false);
        } catch (error: unknown) {
            const typedError = error as { message: string };
            message.error(typedError.message || 'An unexpected error occurred');
        }
    };


    const handlePdf = async () => {
        setPdfLoading(true);
        try {
            const response = await ItemsApiService().getItemsPdfDownload(tableParams);

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'ItemsList.pdf'); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Cleanup the link element and blob URL
            document.body.removeChild(link);
            URL.revokeObjectURL(pdfUrl);
            setPdfLoading(false);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };



    return (
        <>
            <div className='head_btn'>
                {permission.Export && <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                    onClick={handleExport}
                    loading={excelLoading}
                >
                    Export to Excel
                </Button>}

                {permission.Print && <Button
                    icon={!screens.xs ? <FilePdfOutlined /> : undefined}
                    type='primary'
                    onClick={handlePdf}
                    loading={pdfLoading}
                >
                    Print PDF
                </Button>}
            </div>


            <ContentFilters formId={formId}>
                <ItemsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
