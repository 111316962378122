import { ContentFilters } from 'components';
import { BrandsListFilterForm } from '../brands-list-filter/brands-list-filter-form';
import { BrandsFilterFormValues } from '../brands-list-filter/types';


type UsersTopBarProps = {
    getInitialFilterValues: BrandsFilterFormValues;
    handleFilter: (values: BrandsFilterFormValues) => void;
    tableParams: any
};

export const BrandsListTopBar = ({
    getInitialFilterValues,
    handleFilter
}: UsersTopBarProps) => {

    const formId = 'brand-list-filters-form';

    return (
        <>
            <ContentFilters formId={formId}>
                <BrandsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
