type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];

export const ROUTES = {

  DASHBOARD: '/HomeRoot/Dashboard',

  USERS: '/users',
  USER_MANAGEMENT: '/AdminRoot/UsersList',
  USERS_ADMIN_DETAIL: `/AdminRoot/UsersList/:id/detail`,
  ROLE_PERMISSION_MANAGEMENT: '/AdminRoot/RoleManagement',
  USER_DELIVERY_DAYS: '/AdminRoot/DelieveryDays',
  DELIVERY_CHARGE: "/AdminRoot/DeliveryCharge",
  USER_PAYMENT_METHOD: '/AdminRoot/PaymentMethod',
  USER_CMS: '/AdminRoot/CMS',
  USER_CMS_TYPE: '/AdminRoot/CMSType',
  USER_CUSTOM_SIZE_MASTER: '/AdminRoot/CustomSizeList',

  ORDER_LIST: '/OrderRoot/OrderList',
  ORDER_DETAILS: '/OrderRoot/OrderList/:id/detail',
  ORDER_RETURN: '/OrderRoot/OrderReturnList',
  ORDER_RETURN_DETAILS: '/OrderRoot/OrderReturnList/:id/detail',


  ITEMS_LIST: '/ItemsRoot/ItemsList',
  ITEMS_LIST_DETAILS: '/ItemsRoot/ItemsList/:id/detail',

  ITEMS_REVIEWS: '/ItemsRoot/ItemReview',
  ITEMS_STOCK_LIST: '/ItemsRoot/ItemsStockList',
  ITEMS_CATEGORY: '/ItemsRoot/CategoryGroups',

  ITEMS_GENDER: '/ItemsRoot/Gender',

  ITEMS_SUB_CATEGORY: '/ItemsRoot/Category',
  ITEMS_SEASONS: '/ItemsRoot/SeasonList',
  ITEMS_SIZE_TYPE: '/ItemsRoot/SizeType',

  ITEMS_PURCHASED: '/ItemsRoot/PurchasedItem',
  ITEMS_NOTIFY: '/ItemsRoot/Notify',
  ITEMS_SIZE: '/ItemsRoot/Size',
  ITEMS_UPLOAD_ITEMS: '/ItemsRoot/UploadItems',


  CUSTOMER_LIST: '/CustomerRoot/CustomersList',
  CUSTOMER_DETAILS_ID: '/CustomerRoot/CustomersList/:id/detail',

  PROMOTION_LIST: '/MarketingRoot/PromotionList',
  PROMOTION_TYPE_LIST: '/MarketingRoot/PromotionType',
  NOTIFICATIONS_LIST: '/MarketingRoot/Notifications',
  BANNER_LIST: '/MarketingRoot/Banners',
  CONTACT_LIST: '/MarketingRoot/ContactList',
  CONTACT_TYPE_LIST: '/MarketingRoot/ContactType',
  SUBSCRIBER_LIST: '/MarketingRoot/Subscribers',
  NEWS_LIST: '/MarketingRoot/News',
  FAQ_LIST: '/MarketingRoot/FAQ',
  BENEFITS_LIST: '/MarketingRoot/Benefits',
  SOCIAL_MEDIA_LIST: '/MarketingRoot/SocialLink',
  OFFER_TYPE_LIST: '/MarketingRoot/OfferType',

  BRANDS: '/DesignerRoot/VendorList',
  BRANDS_DETAILS: '/DesignerRoot/VendorList/:id/detail',
  SYSTEM_SETTING: "/SettingRoot/SystemSetting",
  SYSTEM_UITILES: '/UtilityRoot/SyncWebsitedata',




  HOME: '/',
  LOGIN: '/login',
  NOT_FOUND: '*',

} as const;
