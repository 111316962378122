import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { CustomersTable } from '../components/customer-table/customer-table';
import { CustomerFilterFormValues, CustomerFilterFormValuesPaginated } from '../components/filter-form/customer-filter/types';
import { CustomerTopBar } from '../components/top-bar/customers-topbar';
import { useGetCustomersLists } from '../use-cases';
import { usePermission } from 'utils/user-permission-page';

const getInitialFilterValues: CustomerFilterFormValues = {
  Name: undefined,
  EmailId: undefined,
  mobile: undefined,
  FromDate: undefined,
  ToDate: undefined,
  StatusId: undefined,
};

export const CustomersView = () => {
  const permission = usePermission();

  const { params, updateParams, resetPage } =
    usePaginationParams<CustomerFilterFormValuesPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, isFetching } = useGetCustomersLists((params), {
    retry: false,
    enabled: permission.View,
  });

  if (!permission.View) return null;


  const handleFiltersChange = (values: CustomerFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <CustomerTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        tableParams={params}
      />

      <CustomersTable
        isLoading={isLoading || isFetching}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};
