import { AdminDetailTopBar } from 'features/users/components/top-bar/admin-detail-top-bar';
import { useParams } from 'react-router-dom';
import { usePermission } from 'utils/user-permission-page';


export const AdminDetailView = () => {
  const { id } = useParams();
  const permission = usePermission();

  if (!permission.Edit) return null;

  if (!id) return null;

  return (
    <>

      <AdminDetailTopBar
        id={id}
      />

    </>
  );
};
