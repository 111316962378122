import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';
import { useGetItemsCategoryLists } from '../use-cases/get-items-category'; import { usePermission } from 'utils/user-permission-page';
import { ItemsGenderListTopBar } from '../components/top-bar/item-gender-list.-topbar';
import { ItemsGenderTable } from '../components/items-gender-table/items-gender-table';
import { ItemsGenderFilterFormPaginated, ItemsGenderFilterFormValues } from '../components/filter-form/items-gender-filter-form/types';
;

const getInitialFilterValues: ItemsGenderFilterFormValues = {
    GenderId: undefined,
    title: undefined,
    status: undefined,
};


export const ItemsGenderListView = () => {
    const permission = usePermission();

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsGenderFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch, isFetching } = useGetItemsCategoryLists((params), {
        // enabled: permission.View
    });

    // if (!permission.View) return null;

    const handleFiltersChange = (values: ItemsGenderFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsGenderListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsGenderTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

