import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
// import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useMarketingDelete } from 'features/marketing/use-cases/delete-common-marketing';
import { useMarketingCommonStatus } from 'features/marketing/use-cases/update-common-marketing-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { OfferTypeFilterFormPaginated } from '../filter-form/offer-type-list-filter/types';
import { AddOfferTypeModal } from '../models/add-offer-type-model/add-offer-type-model';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: OfferTypeFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
    isFetching: boolean
};

export const OfferTypeListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
    isFetching
}: UsersTableProps) => {
    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { confirm } = Modal;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this Item ?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };



    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useMarketingCommonStatus();

    const { mutate: mutateDelete } =
        useMarketingDelete();


    const handleDelete = async (id: number) => {
        mutateDelete({
            Id: id,
            Flag: "OfferTypeDelete",
        });
    };



    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleEdit(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,

        permission.Delete ? {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
                Remove
            </Button>,
        } : null,
    ]


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEdit = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    return (
        <div className='order_rtn'>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddOfferTypeModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}

                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isFetching}
                    dataSource={isLoading ? [] : itemList?.Items}
                >

                    <Column<any>
                        title={'Offer Type'}
                        dataIndex={'OfferType_En'}
                        sorter={(a, b) => a.OfferType_En.localeCompare(b.OfferType_En)}
                    />

                    <Column<any>
                        title={'Offer Type (Arabic)'}
                        dataIndex={'OfferType_Ar'}
                        sorter={(a, b) => a.OfferType_Ar.localeCompare(b.OfferType_Ar)}
                    />


                    <Column<any>

                        title={'Status'}
                        // dataIndex={'SalesPrice'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        render={(value: any, record: any) => {
                            return (
                                <h1 >
                                    {record?.IsActive ? "Active" : "InActive"}
                                </h1>

                            )
                        }}
                    />


                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        loading={isLoadingUpdate}
                                        onChange={(IsActive) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "OfferTypeChangeStatus",
                                                Status: IsActive ? 1 : 0,
                                            });
                                            record.IsActive = IsActive;
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    />


                </Table>
            </StyledTableWrapper >
        </div>
    );
};
