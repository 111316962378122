// import { Link, useLocation } from 'react-router-dom';

import { Breadcrumb } from "antd";
import { useUserContext } from "features/users";
import { Link, useLocation, useNavigate } from "react-router-dom";

// const Breadcum = () => {
//   const location = useLocation();
//   const { user } = useUserContext();
//   const menuItems = user?.getParentMenus
//   // Split the path into an array to generate breadcrumbs
//   const pathnames = location.pathname.split('/').filter(x => x);

//   return (
//     <nav aria-label="breadcrumb">
//       <ul className="breadcrumb">
//         <li className="breadcrumb-item">
//           <Link to="/">Home</Link>
//         </li>

//         {pathnames.map((value, index) => {
//           const to = `/${pathnames.slice(0, index + 1).join('/')}`;
//           const isLast = index === pathnames.length - 1;

//           return isLast ? (
//             <li key={to} className="breadcrumb-item active" aria-current="page">
//               {value}
//             </li>
//           ) : (
//             <li key={to} className="breadcrumb-item">
//               <Link to={to}>{value}</Link>
//             </li>
//           );
//         })}
//       </ul>
//     </nav>
//   );
// };

// export default Breadcum;

const findMenuPath = (menuData: any[], currentPath: string) => {
  const breadcrumbPath: { name: any; path: any; }[] = [];

  for (const menu of menuData) {
    if (`/${menu.Menu_LinkPath}` === currentPath) {
      // Add the parent to the breadcrumb if it matches
      breadcrumbPath.push({ name: menu.Menu_LinkName, path: menu.Menu_LinkPath });

      return breadcrumbPath;
    } else if (menu.Menu_Childrens?.length) {
      // Check for a match in the children
      const child = menu.Menu_Childrens.find((child: any) => `/${child.Menu_LinkPath}` === currentPath);
      if (child) {
        // Add the parent and child to the breadcrumb
        breadcrumbPath.push({ name: menu.Menu_LinkName, path: menu.Menu_LinkPath });
        breadcrumbPath.push({ name: child.Menu_LinkName, path: child.Menu_LinkPath });

        return breadcrumbPath;
      }
    }
  }

  return breadcrumbPath;
};

// export const Breadcum = () => {
//   const { user } = useUserContext();
//   const location = useLocation();
//   const navigate = useNavigate();

//   const breadcrumbPath = findMenuPath(user?.getParentMenus, location.pathname);

//   // Function to handle navigation to parent and redirect to the first child
//   const handleParentClick = (menuPath: string) => {
//     // Find the parent menu and get the first child
//     const parentMenu = user?.getParentMenus.find((menu: any) => `/${menu.Menu_LinkPath}` === menuPath);
//     if (parentMenu && parentMenu.Menu_Childrens.length) {
//       // Navigate to the first child if it exists
//       navigate(`/${parentMenu.Menu_Childrens[0].Menu_LinkPath}`);
//     } else {
//       // Otherwise, navigate to the parent path itself
//       navigate(menuPath);
//     }
//   };

//   return (
//     <Breadcrumb className="breadcrumb">
//       {breadcrumbPath.map((crumb, index) => {
//         const isLast = index === breadcrumbPath.length - 1;

//         return (
//           <Breadcrumb.Item key={index}>
//             {isLast ? (
//               // If it's the last breadcrumb, show as text (not clickable)
//               <span>{crumb.name}</span>
//             ) : (
//               // Parent breadcrumb: clickable
//               <span onClick={() => handleParentClick(`/${crumb.path}`)} style={{ cursor: 'pointer' }}>
//                 {crumb.name}
//               </span>
//             )}
//           </Breadcrumb.Item>
//         );
//       })}
//     </Breadcrumb>
//   );
// };




export const Breadcum = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const breadcrumbPath = findMenuPath(user?.getParentMenus, location.pathname);

  // Function to handle navigation to parent and redirect to the first child
  const handleParentClick = (menuPath: string) => {
    // Find the parent menu and get the first child
    const parentMenu = user?.getParentMenus.find((menu: any) => `/${menu.Menu_LinkPath}` === menuPath);
    if (parentMenu && parentMenu.Menu_Childrens.length) {
      // Navigate to the first child if it exists
      navigate(`/${parentMenu.Menu_Childrens[0].Menu_LinkPath}`);
    } else {
      // Otherwise, navigate to the parent path itself
      navigate(menuPath);
    }
  };

  const breadcrumbItems = breadcrumbPath.map((crumb, index) => {

    const isLast = index === breadcrumbPath.length - 1;

    return {
      title: isLast ? (
        <span>{crumb.name}</span> // Last breadcrumb as plain text
      ) : (
        <span
          onClick={() => handleParentClick(`/${crumb.path}`)}
          style={{ cursor: 'pointer' }}
        >
          {crumb.name}
        </span>
      ),
      key: index,
    };
  });



  return (
    <Breadcrumb className="breadcrumb" items={breadcrumbItems} />
  );
};