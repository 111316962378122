import { ContentFilters } from 'components';
import { CMSTypeFilterForm } from '../filter-form/cms-type-filter/cms-type-filter-form';
import { CMSTypeFilterFormValues } from '../filter-form/cms-type-filter/types';

type UsersTopBarProps = {
    getInitialFilterValues: CMSTypeFilterFormValues;
    handleFilter: (values: CMSTypeFilterFormValues) => void;
};

export const CMSTypeTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'cms-type-filters-form';


    return (
        <>

            <ContentFilters formId={formId}>
                <CMSTypeFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
