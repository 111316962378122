import { PaginatedRequestParams } from "api";

export enum ItemsCategoryFilterFormField {
    GenderId = 'GenderId',
    Title = 'title',
    Status = 'status',
}

export type ItemsCategoryFilterFormValues = {
    [ItemsCategoryFilterFormField.GenderId]: string | undefined;
    [ItemsCategoryFilterFormField.Title]: string | undefined;
    [ItemsCategoryFilterFormField.Status]: string | undefined;

};

export type ItemsCategoryFilterFormPaginated = ItemsCategoryFilterFormValues &
    PaginatedRequestParams;