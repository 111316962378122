import { useEffect, useState, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { App } from "antd";
import { useUserContext } from "features/users";
import { ROUTES } from "./constants";


// type RestrictedRouteProps = {
//   children: ReactElement;
//   requiredPermission: string;
//   redirectPath: any;
//   action: string;
// };

// const RestrictedRoute = ({
//   children,
//   requiredPermission,
//   redirectPath,
//   action,
// }: RestrictedRouteProps) => {
//   const { user } = useUserContext();
//   const { message } = App.useApp();
//   const [isAllowed, setIsAllowed] = useState<boolean | null>(null);

//   const [fallbackRoute, setFallbackRoute] = useState<string | null>(null);

//   useEffect(() => {
//     const resolvePermission = async () => {
//       if (user) {
//         // Super admin check
//         if (user.UserDetails.MemId === 1) {
//           setIsAllowed(true);
//           return;
//         }

//         // Check permission for the current route
//         const hasPermission = checkPermission(user, requiredPermission, action);
//         if (hasPermission) {
//           setIsAllowed(true);
//         } else {
//           message.error("No permission to view this page.");

//           // Resolve the fallback route
//           const fallback = await getFallbackRoute(user, redirectPath, "View");
//           setFallbackRoute(fallback);
//           setIsAllowed(false);
//         }
//       }
//     };

//     resolvePermission();
//   }, [user, requiredPermission, action, redirectPath]);

//   if (isAllowed === null) {
//     // Render a loader while permissions are being resolved
//     return <div>Loading...</div>;
//   }

//   if (!isAllowed && fallbackRoute) {
//     return <Navigate to={fallbackRoute} replace />;
//   }

//   return children;
// };

// const getFallbackRoute = async (
//   user: any,
//   redirectPath: string,
//   action: string
// ): Promise<string> => {
//   // Simulate an async permission check
//   const hasPermission = await checkPermissionAsync(user, redirectPath, action);
//   if (hasPermission) {
//     return redirectPath;
//   }

//   // Default to home if no valid fallback exists
//   return ROUTES.HOME;
// };

// const checkPermissionAsync = async (
//   user: any,
//   path: string,
//   action: string
// ): Promise<boolean> => {
//   // Simulate an async check (e.g., API call or async operation)
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(checkPermission(user, path, action));
//     }, 1000); // Simulate delay
//   });
// };

// export default RestrictedRoute;

// export const checkPermission = (
//   user: any, // Replace `any` with the appropriate user type
//   requiredPath: string,
//   action: string
// ): boolean => {
//   const menuChildren = user?.getParentMenus || [];
//   const normalizedPath = requiredPath.replace(/\/:?[a-zA-Z0-9]+\/detail$/, "");
//   // console.log(normalizedPath, "normalizedPath")
//   return menuChildren.some((parentMenu: any) => {
//     return (
//       parentMenu.Menu_Childrens?.some((child: any) => {
//         const matchesPath = `/${child.Menu_LinkPath}` === normalizedPath;
//         const hasPermission = child.permissionPrivilege?.some(
//           (perm: { Privilege: string; IsAccessible: boolean }) =>
//             perm.Privilege.toLowerCase() === action.toLowerCase() &&
//             perm.IsAccessible
//         );

//         return matchesPath && hasPermission;
//       }) || false
//     );
//   });
// };


// const result: any = {};

// export const checkSdPermission = (
//   user: any,
// ) => {

//   user?.getParentMenus.forEach((menu: { Menu_Childrens: any[]; }) => {
//     if (menu.Menu_Childrens) {
//       menu.Menu_Childrens.forEach(child => {
//         const linkPath = child.Menu_LinkPath;
//         result[linkPath] = {};

//         if (child.permissionPrivilege) {
//           child.permissionPrivilege.forEach((permission: { Privilege: string | number; IsAccessible: any; }) => {
//             result[linkPath][permission.Privilege] = permission.IsAccessible;
//           });
//         }
//       });
//     }
//   });
// }


type RestrictedRouteProps = {
  children: ReactElement;
  requiredPermission: string;
  redirectPath: any;
  action: any;
};
export const RestrictedRoute = ({
  children,
  redirectPath,
  requiredPermission,
  action
}: RestrictedRouteProps) => {

  const { user } = useUserContext();

  if (!user) return null;

  const isAllowed = checkRoutePermission(user, requiredPermission, action);

  if (isAllowed === true) {
    return children;
  }
  // message.error("No permission to view this page.");
  return <Navigate to={redirectPath} replace />;
};

const checkRoutePermission = (user: any, requiredPermission: string, action: string): boolean => {
  const normalizedPath = requiredPermission.replace(/\/:?[a-zA-Z0-9]+\/detail$/, "");

  return user?.resultMenu[normalizedPath]?.[action];

};

