import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemsTransactionFilterFormField, ItemsTransactionFilterFormValues } from './types';
import { payFormatDate } from 'utils/payload-date-';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ItemsTransactionFilterFormValues) => void;
    initialValues: ItemsTransactionFilterFormValues;
    closeModal?: () => void;
};

export const ItemsTransactionFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemsTransactionFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: ItemsTransactionFilterFormValues) => {
        const formattedValues = {
            ...formValues,
            [ItemsTransactionFilterFormField.FromDate]: formValues?.fromDate && payFormatDate(formValues?.fromDate),
            [ItemsTransactionFilterFormField.ToDate]: formValues?.toDate && payFormatDate(formValues?.toDate),
        };
        handleFilter(formattedValues);
        closeModal?.();
    };

    const statusData = [
        { label: 'All', value: 'all' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
    ];

    const PaymentStatusList = [
        { Text: "CAPTURED", Value: "CAPTURED" },
        { Text: "NOT CAPTURED", Value: "NOT CAPTURED" },
        { Text: "INCOMPLETE", Value: "INCOMPLETE" },
        { Text: "VOIDED", Value: "VOIDED" },
        { Text: "CANCELED", Value: "CANCELED" },
        { Text: "DENIED BY RISK", Value: "DENIED BY RISK" },
        { Text: "HOST TIMEOUT", Value: "HOST TIMEOUT" },
        { Text: "PENDING", Value: "PENDING" },

    ];


    return (
        <Form<ItemsTransactionFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>


                        <Form.Item<ItemsTransactionFilterFormValues>
                            label={'From Date'}
                            name={ItemsTransactionFilterFormField.FromDate}
                        >
                            <DatePicker />

                        </Form.Item>


                        <Form.Item<ItemsTransactionFilterFormValues>
                            label={'To Date'}
                            name={ItemsTransactionFilterFormField.ToDate}
                        >
                            <DatePicker />

                        </Form.Item>


                        <Form.Item<ItemsTransactionFilterFormValues>
                            label={'Name'}
                            name={ItemsTransactionFilterFormField.Name}
                        >
                            <Input
                                placeholder={"Customer Name"}
                            />
                        </Form.Item>


                        <Form.Item<ItemsTransactionFilterFormValues>
                            label={"Payment Status"}
                            name={ItemsTransactionFilterFormField.PaymentStatus}
                        >
                            <Select
                                showSearch
                                options={PaymentStatusList?.map((item: { Text: string; Value: string }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Payment Status"}
                            />
                        </Form.Item>


                        <Form.Item<ItemsTransactionFilterFormValues>
                            label={"Status"}
                            name={ItemsTransactionFilterFormField.Status}
                        >
                            <Select
                                options={statusData}
                                placeholder={'Status'}
                            />
                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
