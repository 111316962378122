import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, Modal, Skeleton, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useItemsCommonStatus } from 'features/items/use-cases/update-common-items-status';
import { Dispatch, SetStateAction, useState } from 'react';

import noImg from "../../../../assets/No_Image_Available.jpg";
import { ItemsSubCategoryFilterFormPaginated } from '../filter-form/items-sub-category-filter-form/types';
import { EditSubCategoryModal } from '../model/edit-item-sub-category/edit-item-sub-category-model';
import { SizeGuideModal } from '../model/size-guide-modal';
import { usePermission } from 'utils/user-permission-page';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsSubCategoryFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsSubCategoryTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);


    const { mutate: mutateUpdateStatus } =
        useItemsCommonStatus();

    const handleSizeGuide = async (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    };


    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenEditModel(record)} icon={<EditOutlined />} >Edit</Button>,
        } : null,
        // {
        //     key: 'size',
        //     label: <Button onClick={() => handleSizeGuide(record)} type="text" icon={<DeleteOutlined />}>
        //         Link size Guide
        //     </Button>,
        // },
    ]

    const handleOpenEditModel = (record: any) => {
        setOpenEdit(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseModal = () => {
        setEditData(null);
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }


    const handleCloseEditModal = () => {
        setOpenEdit(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }


    return (
        <div className='order_rtn'>
            {isOpen && <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <SizeGuideModal onClose={handleCloseModal} editData={editData} modalType={"subCategory"} />
            </div>}

            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <EditSubCategoryModal onClose={handleCloseEditModal} editData={editData} />
            </div>


            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList?.getSubCategoryLists}

                >

                    <Column<any>
                        title={'Gender'}
                        dataIndex={'Gender'}
                        sorter={(a, b) => {
                            if (!a.Gender) return 1; // Sort null or undefined values to the end
                            if (!b.Gender) return -1;

                            return a.Gender.localeCompare(b.Gender);
                        }}
                    />

                    <Column<any>
                        title={'Group Category'}
                        dataIndex={'GroupName_En'}
                        sorter={(a, b) => {
                            if (!a.GroupName) return 1; // Sort null or undefined values to the end
                            if (!b.GroupName) return -1;

                            return a.GroupName.localeCompare(b.GroupName);
                        }}

                    />

                    <Column<any>
                        title={'Category'}
                        dataIndex={'CategoryName_En'}
                        sorter={(a, b) => {
                            if (!a.CategoryName) return 1; // Sort null or undefined values to the end
                            if (!b.CategoryName) return -1;

                            return a.CategoryName.localeCompare(b.CategoryName);
                        }}
                    />

                    <Column<any>
                        title={'Category (Arabic)'}
                        dataIndex={'CategoryName_Ar'}
                        sorter={(a, b) => {
                            if (!a.CategoryName) return 1; // Sort null or undefined values to the end
                            if (!b.CategoryName) return -1;

                            return a.CategoryName.localeCompare(b.CategoryName);
                        }}
                    />

                    <Column<any>
                        title={'Category Image'}
                        // dataIndex={'Rating'}
                        render={(_, record: any) => (
                            <>
                                <Image src={record?.CategoryImage}
                                    width={40}
                                    height={40}
                                    fallback={noImg}
                                />
                            </>

                        )}
                    />
                    <Column<any>
                        title={'Size Guide'}
                        dataIndex={'SizeGuide'}
                        sorter={(a, b) => {
                            if (!a.SizeGuide) return 1; // Sort null or undefined values to the end
                            if (!b.SizeGuide) return -1;

                            return a.SizeGuide.localeCompare(b.SizeGuide);
                        }}
                    />




                    <Column<any>
                        title={'Status'}
                        render={(_, record) => (
                            <h1>{record.IsActive ? "Active" : "InActive"}</h1>

                        )}

                        sorter={(a, b) => {
                            if (!a.IsActive) return 1; // Sort null or undefined values to the end
                            if (!b.IsActive) return -1;

                            return a.IsActive - b.IsActive;
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']}
                                    className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip title={"Is Active"}>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(IsActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "SubCategoryChangeStatus",
                                                Status: IsActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            // record.IsActive = IsActive; // Ensure it's an integer
                                        }}
                                    />
                                </Tooltip>

                            </>
                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
