import { EditOutlined, ExclamationCircleOutlined, MoreOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateItemsInfo } from 'features/items/use-cases/update-item-info';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

import { ItemsFilterFormPaginated } from '../filter-form/items-list-filter/types';
import { AddCustomSizeModal } from '../model/add-custome-size-model/add-custome-size-model';
import { usePermission } from 'utils/user-permission-page';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<ItemsFilterFormPaginated>>;
    isFetching: boolean
};

export const ItemsListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
    isFetching
}: UsersTableProps) => {
    const permission = usePermission();

    const navigate = useNavigate();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { confirm } = Modal;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const { mutate } = useUpdateItemsInfo();

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this Items?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });

    };

    const handleDelete = async (id: any) => {
        mutate({
            Id: id,
            Flag: "DeleteItemMaster",
        });
    };

    const navigateDetails = (record: any) => {
        navigate(getRoute(ROUTES.ITEMS_LIST_DETAILS, record.Id));
    }



    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleEdit(record)} icon={<EditOutlined />} >Edit Custom Size</Button>,
        } : null,
        // {
        //     key: 'delete',
        //     label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
        //         Remove
        //     </Button>,
        // },
    ]


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEdit = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    return (
        <div className='order_rtn'>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddCustomSizeModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Rownumber'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList?.getItemMasterLists}
                    className='cstm_table'

                >
                    <Column<any>
                        title={'Brand'}
                        sorter={(a, b) => a.BrandName_En.localeCompare(b.BrandName_En)}
                        render={(value: any, record: any) => {
                            return (
                                <span className='arow_tab' onClick={() => permission.Edit && navigateDetails(record)}>
                                    {permission.Edit && <RightOutlined />}
                                    <h1>{record.BrandName_En}</h1>
                                </span>

                            )
                        }}
                    />

                    <Column<any>
                        title={'Gender'}
                        dataIndex={'Gender_En'}
                        sorter={(a, b) => {
                            if (!a.Gender_En) return 1; // Sort null or undefined values to the end
                            if (!b.Gender_En) return -1;

                            return a.Gender_En.localeCompare(b.Gender_En);
                        }}
                    />
                    <Column<any>
                        title={'Group Category'}
                        dataIndex={'GroupName_En'}
                        sorter={(a, b) => a.GroupName_En.localeCompare(b.GroupName_En)}
                    />

                    <Column<any>
                        title={'Category'}
                        dataIndex={'CategoryName_En'}

                        sorter={(a, b) => {
                            if (!a.CategoryName_En) return 1; // Sort null or undefined values to the end
                            if (!b.CategoryName_En) return -1;

                            return a.CategoryName_En.localeCompare(b.CategoryName_En);
                        }}
                    />

                    <Column<any>
                        title={'Item Name'}
                        dataIndex={'ItemName_En'}
                        sorter={(a, b) => a.ItemName_En.localeCompare(b.ItemName_En)}
                    />

                    <Column<any>
                        title={'Code'}
                        dataIndex={'ItemCode'}

                        sorter={(a, b) => {
                            if (a.ItemCode == null) return 1; // Sort null or undefined values to the end
                            if (b.ItemCode == null) return -1;

                            return a.ItemCode - b.ItemCode;
                        }}
                    />

                    <Column<any>
                        title={'Price'}
                        dataIndex={'SalesPrice'}
                        sorter={(a, b) => Number(a.SalesPrice) - Number(b.SalesPrice)}
                    />

                    <Column<any>
                        title={'Status'}
                        // dataIndex={'AmountPaid'}
                        render={(_, record: any) => record.IsActive ? " Active" : "InActive"}
                        sorter={(a, b) => Number(a.IsActive) - Number(b.IsActive)}
                    />

                    <Column<any>
                        title={'Custom'}
                        render={(_, record: any) => record.IsCustom ? " Yes" : "No"}
                        sorter={(a, b) => Number(a.IsCustom) - Number(b.IsCustom)}
                    />


                    <Column<any>
                        title={'Locked'}
                        render={(_, record: any) => record.IsLockedItem ? " Yes" : "No"}
                        sorter={(a, b) => Number(a.IsLockedItem) - Number(b.IsLockedItem)}
                    />

                    <Column<any>
                        title={'Quantiity'}
                        dataIndex={"Qty"}
                        // sorter={(a, b) => Number(a.Qty) - Number(b.Qty)}
                        sorter={(a, b) => {
                            if (a.Qty == null) return 1; // Sort null or undefined values to the end
                            if (b.Qty == null) return -1;

                            return a.Qty - b.Qty;
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip title={"Is Active"}>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        loading={isLoading}
                                        onChange={(IsActive) => {
                                            mutate({
                                                Id: record.Id,
                                                Flag: "ChangeStatus",
                                                IsActive: IsActive,
                                            });
                                            record.IsActive = IsActive;
                                        }}
                                    />
                                </Tooltip>

                            </Space>
                        )}
                    />


                </Table>
            </StyledTableWrapper >
        </div>
    );
};
