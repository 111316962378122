import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { DeliveryDaysTable } from '../components/delivery-days-table/delivery-days-table';
import { useGetDeliveryDaysLists } from '../use-cases';
import { usePermission } from 'utils/user-permission-page';


export const DeliveryDaysView = () => {
    const permission = usePermission();

    const { params, updateParams } =
        usePaginationParams<any>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });

    const { data, isLoading } = useGetDeliveryDaysLists((params), {
        enabled: permission.View
    });

    if (!permission.View) return null;



    return (
        <>
            <DeliveryDaysTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};
