import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, message, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useItemsCommonStatus } from 'features/items/use-cases/update-common-items-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { ItemsSeasonsFilterFormPaginated } from '../filter-form/items-seasons-list-filter/types';
import { AddItemsSeasonsModal } from '../model/add-season-model/add-items-season-model';
import { usePermission } from 'utils/user-permission-page';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsSeasonsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsSeasonsTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const permission = usePermission();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);


    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useItemsCommonStatus();



    const menuItems = (record: any) => [
        permission.Edit ? {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenEditModel(record)} icon={< EditOutlined />} > Edit </Button>,
        } : null,
    ]

    const handleOpenEditModel = (record: any) => {
        setOpenEdit(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseEditModal = () => {
        setOpenEdit(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }


    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <AddItemsSeasonsModal onClose={handleCloseEditModal} editData={editData} />
            </div>


            < StyledTableWrapper >
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                < Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList?.getItemSeasonLists}

                >
                    <Column<any>
                        title={'Season Name'}
                        dataIndex={'Season_En'}
                        sorter={(a, b) => {
                            if (!a.Season_En) return 1; // Sort null or undefined values to the end
                            if (!b.Season_En) return -1;

                            return a.Season_En.localeCompare(b.Season_En);
                        }}

                    />

                    <Column<any>
                        title={'Season Name (Arabic)'}
                        dataIndex={'Season_Ar'}
                        sorter={(a, b) => {
                            if (!a.Season_Ar) return 1; // Sort null or undefined values to the end
                            if (!b.Season_Ar) return -1;

                            return a.Season_Ar.localeCompare(b.Season_Ar);
                        }}
                    />



                    <Column<any>
                        title={'Status'}
                        render={(_, record) => (
                            <h1>{record.IsActive ? "Active" : "InActive"} </h1>

                        )}
                        sorter={(a, b) => {
                            if (!a.IsActive) return 1; // Sort null or undefined values to the end
                            if (!b.IsActive) return -1;

                            return a.IsActive - b.IsActive;
                        }}
                    />

                    < Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']}
                                    className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={< MoreOutlined />} />
                                </Dropdown>
                                <Tooltip title={"Is Active"} >
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        loading={isLoadingUpdate}
                                        onChange={(IsActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "SeasonChangeStatus",
                                                Status: IsActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = IsActive; // Ensure it's an integer
                                        }}
                                    />
                                </Tooltip>

                            </Space>
                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
