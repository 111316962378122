import { PaginatedRequestParams } from "api";

export enum ItemsGenderFilterFormField {
    GenderId = 'GenderId',
    Title = 'title',
    Status = 'status',
}

export type ItemsGenderFilterFormValues = {
    [ItemsGenderFilterFormField.GenderId]: string | undefined;
    [ItemsGenderFilterFormField.Title]: string | undefined;
    [ItemsGenderFilterFormField.Status]: string | undefined;

};

export type ItemsGenderFilterFormPaginated = ItemsGenderFilterFormValues &
    PaginatedRequestParams;